import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Grid, Typography, Modal, TextField } from '@material-ui/core';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import pxToRem from 'assets/theme/functions/pxToRem';

import { TextEditedContent, Button } from 'components';
import { TopActions, Content, Title, Actions, Left, useStyles } from './styles';

const propTypes = {
  dataType: PropTypes.string,
  dataId:
    PropTypes.number |
    PropTypes.shape({
      orgId: PropTypes.number,
      userId: PropTypes.number,
      quotaUserId: PropTypes.number,
    }),
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    userType: PropTypes.string,
    preferredLanguage: PropTypes.string,
    preferredTimezone: PropTypes.any,
  }),
  updateData: PropTypes.func,
};

const IWGDashboardDetails = ({ dataType, dataId, data, updateData }) => {
  const classes = useStyles();

  const [dataJSON, setDataJSON] = useState(JSON.stringify(data, undefined, 4));
  const [isEditing, setEditing] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const onDataDetailsChange = (event) => {
    setDataJSON(event.target.value);
  };

  const OnConfirmModal = () => {
    updateData(dataId, dataJSON);
    setConfirmModalOpen(false);
    setEditing(false);
  };

  return (
    <Fragment>
      <TopActions>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{
            fontWeight: 'bold',
            marginTop: '5px',
            marginLeft: '14px',
            textAlign: 'right',
            alignSelf: 'stretch',
          }}>
          {`${dataType}: ${dataId}`}
        </Typography>
      </TopActions>
      <Content>
        <Left>
          <Title>Data Details</Title>
          {isEditing ? (
            <Fragment>
              <TextField
                variant="outlined"
                multiline
                fullWidth
                defaultValue={dataJSON}
                onChange={onDataDetailsChange}
              />
              <Actions>
                <Button
                  variant="primary"
                  onClick={() => setConfirmModalOpen(true)}>
                  Update
                </Button>
                <Button variant="empty" onClick={() => setEditing(false)}>
                  Cancel
                </Button>
              </Actions>
            </Fragment>
          ) : (
            <Fragment>
              <TextEditedContent
                content={dataJSON}
                onClick={() => setEditing(true)}
              />
            </Fragment>
          )}
        </Left>
      </Content>
      {confirmModalOpen && (
        <Modal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          className={classes.modal}>
          <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <MDTypography mb={1}>Confirm</MDTypography>
              <MDTypography>
                You are going to update a user as below:
              </MDTypography>
              <TextEditedContent content={dataJSON} />
              <Grid
                spacing={3}
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{ marginTope: '20px' }}>
                <Grid xs={6} item>
                  <MDButton
                    // variant="gradient"
                    color="info"
                    style={{
                      color: 'white',
                      fontSize: pxToRem(18),
                      marginTop: '5px',
                      marginBottom: '45px',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    onClick={OnConfirmModal}>
                    Confirm
                  </MDButton>
                </Grid>
                <Grid xs={6} item>
                  <MDButton
                    // variant="gradient"
                    color="info"
                    style={{
                      color: 'white',
                      fontSize: pxToRem(18),
                      marginTop: '5px',
                      marginBottom: '45px',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    onClick={() => {
                      setConfirmModalOpen(false);
                    }}>
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Modal>
      )}
    </Fragment>
  );
};

IWGDashboardDetails.propTypes = propTypes;

export default withRouter(IWGDashboardDetails);
