export const NotificationType = {
  // seimitsu
  NEW_ISSUE: 'NEW_ISSUE',
  NEW_COMMENT: 'NEW_COMMENT',
  ASSIGN_DOCTOR_IN_CHARGE: 'ASSIGN_DOCTOR_IN_CHARGE',
  ASSIGN_DOCTOR: 'ASSIGN_DOCTOR',
  RELEASE_DOCTOR: 'RELEASE_DOCTOR',
  STATE_CHANGE: 'STATE_CHANGE',
  STATUS_CHANGE: 'STATUS_CHANGE',
  BILLING_QUESTION: 'BILLING_QUESTION',

  // kenshin
  NEW_ISSUE_KENSHIN: 'NEW_ISSUE_KENSHIN',
  NEW_COMMENT_KENSHIN: 'NEW_COMMENT_KENSHIN',
  ASSIGN_DOCTOR_KENSHIN: 'ASSIGN_DOCTOR_KENSHIN',
  RELEASE_DOCTOR_KENSHIN: 'RELEASE_DOCTOR_KENSHIN',
  STATE_CHANGE_KENSHIN: 'STATE_CHANGE_KENSHIN',
  STATUS_CHANGE_KENSHIN: 'STATUS_CHANGE_KENSHIN',
  BILLING_QUESTION_KENSHIN: 'BILLING_QUESTION_KENSHIN',
  ALL: 'ALL',
};

export const NotificationTypeIntlKey = {
  // seimitsu
  [NotificationType.NEW_ISSUE]: 'notifications_type_new_issue_seimitsu',
  [NotificationType.NEW_COMMENT]: 'notifications_type_new_comment_seimitsu',
  [NotificationType.ASSIGN_DOCTOR_IN_CHARGE]:
    'notifications_type_assign_doctor_in_charge',
  [NotificationType.ASSIGN_DOCTOR]: 'notifications_type_assign_doctor',
  [NotificationType.RELEASE_DOCTOR]: 'notifications_type_release_doctor',
  [NotificationType.STATE_CHANGE]: 'notifications_type_state_change',
  [NotificationType.STATUS_CHANGE]: 'notifications_type_status_change',
  [NotificationType.BILLING_QUESTION]: 'notifications_type_billing_question',
  // kenshin
  [NotificationType.NEW_ISSUE_KENSHIN]: 'notifications_type_new_issue_kenshin',
  [NotificationType.NEW_COMMENT_KENSHIN]:
    'notifications_type_new_comment_kenshin',
  [NotificationType.ASSIGN_DOCTOR_KENSHIN]:
    'notifications_type_assign_doctor_kenshin',
  [NotificationType.RELEASE_DOCTOR_KENSHIN]:
    'notifications_type_release_doctor_kenshin',
  [NotificationType.STATE_CHANGE_KENSHIN]:
    'notifications_type_state_change_kenshin',
  [NotificationType.STATUS_CHANGE_KENSHIN]:
    'notifications_type_status_change_kenshin',
  [NotificationType.BILLING_QUESTION_KENSHIN]:
    'notifications_type_billing_question_kenshin',
  // all
  [NotificationType.ALL]: 'notifications_type_all',
};
