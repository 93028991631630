import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './Styles';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Preview from '../Preview';

const propTypes = {
  reportContent: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  issueId: PropTypes.number.isRequired,
  modalClose: PropTypes.func,
};

const DashboardKanbanIssueDetailsReportsReportPreview = ({
  reportContent,
  template,
  issueId,
  modalClose,
}) => {
  const classes = useStyles();

  return (
    <Grid>
      <Grid container className={classes.actionGrid} justifyContent="flex-end">
        <div>
          <IconButton onClick={modalClose}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </div>
      </Grid>
      <Grid
        container
        className={classes.reportPreviewGrid}
        columns={{ xs: 12 }}>
        <Grid className={classes.previewGrid} size={12}>
          <Preview
            reportContent={reportContent}
            template={template}
            issueId={issueId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

DashboardKanbanIssueDetailsReportsReportPreview.propTypes = propTypes;

export default withRouter(DashboardKanbanIssueDetailsReportsReportPreview);
