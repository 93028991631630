import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { InfoLabel, InfoValue, EmptyLabel } from './styles';
import { makeTagString } from 'pages/Dashboard/New/utils';
import { TextEditedContent } from 'components';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { getTextContentsFromHtmlString } from 'shared/utils/browser';
import { Grid, Chip } from '@mui/material';
import { TAG_SYSTEM } from 'shared/constants/issues';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
};

const IssueDetailsAIInfo = ({ issue }) => {
  const [CTTags, setCTTags] = useState([]);
  const [MRITags, setMRITags] = useState([]);
  const [PhyExamTags, setPhyExamTags] = useState([]);

  useEffect(() => {
    listTags(issue.tags);
  }, [issue.tags]);

  const isDescriptionEmpty =
    getTextContentsFromHtmlString(issue.description).trim().length === 0;

  const listTags = (tags) => {
    const CTTagsArray = [];
    const MRITagsArray = [];
    const PhyExamTagsArray = [];

    // create the tagsObject to use the imported "makeTagString" function, in the form of {tagKeyName: true, ...};
    const tagsObject = {};
    for (var i = 0; i < tags.length; i++) {
      tagsObject[tags[i]] = true;
    }

    for (var j = 0; j < tags.length; j++) {
      const key = tags[j];
      const res = makeTagString(key, tagsObject);
      if (res) {
        if (key.startsWith('CT')) CTTagsArray.push(res);
        if (key.startsWith('MRI')) MRITagsArray.push(res);
        if (key.startsWith('PhyExam')) PhyExamTagsArray.push(res);
      }
    }

    setCTTags(CTTagsArray);
    setMRITags(MRITagsArray);
    setPhyExamTags(PhyExamTagsArray);
  };

  // Is this needed?
  const renderTagsFixed = () => {
    return (
      <>
        <Grid item xs={2} md={2} sm={2}>
          <InfoLabel>{intl.get('issue_details_text_tags')}</InfoLabel>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <InfoValue>
            {intl.get('case_tag_value_section_1')}: {CTTags.join(', ')}
          </InfoValue>
          <InfoValue>
            {intl.get('case_tag_value_section_2')}: {MRITags.join(', ')}
          </InfoValue>
          <InfoValue>
            {intl.get('case_tag_value_section_3')}: {PhyExamTags.join(', ')}
          </InfoValue>
        </Grid>
      </>
    );
  };

  const renderTagsNew = () => {
    return (
      <>
        <Grid item xs={2} md={2} sm={2}>
          <InfoLabel>{intl.get('issue_details_text_tags')}</InfoLabel>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          {issue.tags.map((tagLabel, index) => {
            return <Chip key={index} label={tagLabel} variant="outlined" />;
          })}
        </Grid>
      </>
    );
  };

  const renderTags = () => {
    const tagSystem = process.env.REACT_APP_TAG_SYSTEM;
    if (tagSystem === TAG_SYSTEM.CUSTOMIZED) {
      return renderTagsFixed();
    } else if (tagSystem === TAG_SYSTEM.FIXED) {
      // If the fixed then there are no tags
      return <Fragment></Fragment>;
    } else {
      return renderTagsNew();
    }
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={2} md={2} sm={2}>
          <InfoLabel>
            {intl.get('issue_details_info_label_patient_name')}
          </InfoLabel>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <InfoValue>{issue.patientName}</InfoValue>
        </Grid>
        <Grid item xs={2} md={2} sm={2}>
          <InfoLabel>
            {intl.get('issue_details_info_label_patient_gender')}
          </InfoLabel>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <InfoValue>{issue.patientGender}</InfoValue>
        </Grid>
        {renderTags()}
        <Grid item xs={2} md={2} sm={2}>
          <InfoLabel>{intl.get('issue_details_info_label_from')}</InfoLabel>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <InfoValue>{issue.srcOrgName}</InfoValue>
        </Grid>
        <Grid item xs={2} md={2} sm={2}>
          <InfoLabel>
            {intl.get('issue_details_info_label_doctor_in_charge')}
          </InfoLabel>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <InfoValue>{issue.doctorsInCharge.join(', ')}</InfoValue>
        </Grid>
        <Grid item xs={2} md={2} sm={2}>
          <InfoLabel>
            {intl.get('issue_details_info_label_department')}
          </InfoLabel>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <InfoValue>{issue.department}</InfoValue>
        </Grid>
        <Grid item xs={2} md={2} sm={2}>
          <InfoLabel>{intl.get('issue_details_text_description')}</InfoLabel>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          {isDescriptionEmpty ? (
            <EmptyLabel>
              {intl.get('issue_details_placeholder_no_description')}
            </EmptyLabel>
          ) : (
            <TextEditedContent content={issue.description} />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

IssueDetailsAIInfo.propTypes = propTypes;

export default IssueDetailsAIInfo;
