import styled from 'styled-components';

import { color, font, mixin } from 'shared/utils/styles';

export const InfoLabel = styled.div`
  text-transform: uppercase;
  color: ${color.textMedium};
  ${font.size(12.5)}
  ${font.bold}
`;

export const InfoValue = styled.div`
  ${font.size(15)}
  ${font.medium}
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const EmptyLabel = styled.div`
  transition: background 0.1s;
  ${font.size(15)}
  ${font.medium}
  ${mixin.clickable}
  &:hover {
    background: ${color.backgroundLight};
  }
`;

export const Actions = styled.div`
  display: flex;
  padding-top: 12px;
  & > button {
    margin-right: 6px;
  }
`;
