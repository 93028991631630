import React, { useState } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Grid, Tab, Tabs } from '@material-ui/core';
import Card from '@mui/material/Card';

// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from 'context';
import Preference from './Preference';
import Password from './Password';
import MFA from './MFA';
import NotificationPreference from './Notifications';
import { UserProptypes } from 'shared/propTypes/userType';

const propTypes = {
  userInfo: PropTypes.shape(UserProptypes),
  // Cognito User
  currentUser: PropTypes.object,
};

const DashboardPersonalSettings = ({ userInfo, currentUser }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const tabStyle = { color: darkMode ? 'white' : 'grey' };
  const [value, setValue] = useState(0);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <Card sx={{ margin: 2, marginTop: 3, overflow: 'visible' }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <MDBox pl={1} mb={1} display="flex">
          <MDTypography variant="h3">
            {intl.get('personal_settings_text_title_personal_settings')}
          </MDTypography>
        </MDBox>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          alignContent="flex-start">
          <Grid
            container
            item
            xs={8}
            direction="column"
            justifyContent="center">
            <Grid container item xs={12} justifyContent="center">
              <MDBox>
                <Tabs
                  style={tabStyle}
                  value={value}
                  onChange={(event, newValue) => setValue(newValue)}
                  aria-label="basic tabs example">
                  <Tab
                    label={intl.get('personal_settings_tab_label_preference')}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={intl.get('personal_settings_tab_label_password')}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={intl.get('personal_settings_tab_label_mfa')}
                    {...a11yProps(2)}
                  />
                  <Tab
                    label={intl.get('personal_settings_notification_tab_name')}
                    {...a11yProps(3)}
                  />
                </Tabs>
              </MDBox>
            </Grid>
            {value === 0 && (
              <Grid container item xs={12} justifyContent="center">
                <Preference userInfo={userInfo} currentUser={currentUser} />
              </Grid>
            )}
            {value === 1 && (
              <Grid container item xs={12} justifyContent="center">
                <Password userInfo={userInfo} />
              </Grid>
            )}
            {value === 2 && (
              <Grid container item xs={12} justifyContent="center">
                <MFA />
              </Grid>
            )}
            {value === 3 && (
              <Grid container item xs={12} justifyContent="center">
                <NotificationPreference
                  userInfo={userInfo}
                  currentUser={currentUser}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

DashboardPersonalSettings.propTypes = propTypes;

export default withRouter(DashboardPersonalSettings);
