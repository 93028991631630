import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { PDFViewer } from '@react-pdf/renderer';

import { getPresignedURL, loadPdfFromPresignedURL } from 'utils/ReportUtils';
import { REPORT_TEMPLATE_LIST } from '../Templates/constants';

const propTypes = {
  reportContent: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  issueId: PropTypes.number.isRequired,
};

const AttachmentType = 'ATTACHMENT';

const DashboardKanbanIssueDetailsReportsPreview = ({
  reportContent,
  template,
  issueId,
}) => {
  const reportTitle = reportContent.fileName + '.pdf';
  const [modifiedPdfUrl, setModifiedPdfUrl] = useState('');
  const [templateComponent, setTemplateComponent] = useState(null);

  useEffect(() => {
    if ('type' in template && template.type === AttachmentType) {
      getPresignedURL(template.id, issueId)
        .then((presignedUrl) => {
          loadPdfFromPresignedURL(presignedUrl, reportContent).then((url) => {
            setModifiedPdfUrl(url);
          });
        })
        .catch((error) => {
          console.error('error', error);
        });
    } else {
      const TemplateComponent = REPORT_TEMPLATE_LIST.find(
        (t) => t.id === template.id
      ).template;

      setTemplateComponent(
        <TemplateComponent
          reportTitle={reportTitle}
          reportContent={reportContent}
        />
      );
    }
  }, [template, issueId, reportContent, reportTitle]);

  console.log('templateComponent', template);

  return (
    <>
      {'type' in template && template.type === AttachmentType ? (
        <iframe
          title={reportTitle}
          src={modifiedPdfUrl}
          width="100%"
          height="100%"
        />
      ) : (
        <PDFViewer
          width="100%"
          height="100%"
          data-testid="reportPreviewModal-PDFViewer">
          {templateComponent}
        </PDFViewer>
      )}
    </>
  );
};

DashboardKanbanIssueDetailsReportsPreview.propTypes = propTypes;

export default withRouter(DashboardKanbanIssueDetailsReportsPreview);
