/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Custom styles for the SidenavItem
import { item, itemContent, itemIcon } from './Styles';

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from 'context';
import MDBadge from 'components/MDBadge';

function SidenavListItem({
  badgeContent,
  icon,
  name,
  active,
  nested,
  children,
  open,
  onClick,
  isClickable,
  customMarginX,
  customPaddingLeft,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  return (
    <ListItem
      {...rest}
      data-testid={`sidenav-list-item-${name}`}
      onClick={onClick}
      component="li"
      sx={(theme) =>
        item(theme, {
          active,
          transparentSidenav,
          whiteSidenav,
          darkMode,
          isClickable,
          customMarginX,
          customPaddingLeft,
        })
      }>
      <MDBox
        sx={(theme) =>
          itemContent(theme, {
            active,
            miniSidenav,
            name,
            open,
            nested,
            transparentSidenav,
            whiteSidenav,
            darkMode,
          })
        }>
        {icon ? (
          <Icon
            sx={(theme) =>
              itemIcon(theme, {
                active,
                miniSidenav,
                name,
                open,
                nested,
                transparentSidenav,
                whiteSidenav,
                darkMode,
              })
            }>
            {icon}
          </Icon>
        ) : (
          <></>
        )}
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            style: { whiteSpace: 'normal', overflow: 'visible' },
          }}
        />
        {badgeContent ? (
          <MDBadge
            badgeContent={badgeContent}
            color="error"
            indicator={true}
            size="medium"
            circular
          />
        ) : (
          <></>
        )}
      </MDBox>
    </ListItem>
  );
}

// Setting default values for the props of SidenavItem
SidenavListItem.defaultProps = {
  active: false,
  nested: false,
  children: false,
  open: false,
  isClickable: false,
  customMarginX: '0px',
  customPaddingLeft: '15px',
};

// Typechecking props for the SidenavItem
SidenavListItem.propTypes = {
  badgeContent: PropTypes.number,
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  isClickable: PropTypes.bool,
  customPaddingLeft: PropTypes.string,
  customMarginX: PropTypes.string,
};

export default SidenavListItem;
