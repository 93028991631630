import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import intl from 'react-intl-universal';

import { NotificationType } from 'shared/constants/notifications';
import { DOLPHIN_API } from 'shared/constants/apis';
import { LANGUAGE, USER_DATA } from 'shared/constants/users';
import localData from 'shared/utils/localData';
import { readableDateFormat } from 'shared/utils/dateTime';
import { generateNotificationText } from './utils';

const NotificationItem = ({ notification, index, handleItemClick }) => {
  // todo:dacao change the timeZone stored in localData from 'JST' to 'Asia/Tokyo'
  const timeZone = localData.get('preferredTimezone');
  const text = generateNotificationText(
    notification.type,
    JSON.parse(notification.contents)
  );

  return (
    <Box
      data-testid={`notification-item-${index}`}
      key={index}
      onClick={() => handleItemClick(notification)}
      sx={{
        display: 'flex',
        borderBottom: '1px solid #eee',
        p: 1,
        cursor: 'pointer',
        backgroundColor: notification.clicked ? '#f5f5f5' : '#ffffff',
        '&:hover': {
          backgroundColor: notification.clicked ? '#e0e0e0' : '#eeeeee',
        },
      }}>
      {/* Left side (90% width) - main content */}
      <Box sx={{ flex: 9, paddingLeft: '4px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="subtitle3">
            {intl.get(`notifications_service_${notification.service}`)}
          </Typography>
          <Typography variant="subtitle3">
            {readableDateFormat(notification.createdAt, timeZone)}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: notification.clicked ? 'light' : 'bold',
          }}>
          {text}
        </Typography>
      </Box>

      {/* Right side (10% width) - shows an icon based on clicked or not*/}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {notification.clicked ? (
          <MarkAsUnreadIcon
            color="disabled"
            sx={{ fontSize: '22px !important' }}
          />
        ) : (
          <EmailIcon color="info" sx={{ fontSize: '22px !important' }} />
        )}
      </Box>
    </Box>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleItemClick: PropTypes.func.isRequired,
};

export default NotificationItem;
