import styled from 'styled-components';

export const Content = styled.div`
  max-height: '40vh';
  width: '100%';
  display: 'flex';
  flex-direction: 'column';
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  height: '30px';
  width: '100%';
`;

export const FilterContent = styled.div`
  border: 2px solid #e0e0e0;
  max-height: 400px;
  overflow-y: auto;
`;

export const BottomBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // border-top: 1px solid #e0e0e0;
  height: '30px';
  width: '100%';
`;
