import React, { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';
import EmailIcon from '@mui/icons-material/Email';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';

// self developed api
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
import {
  IssueStatus,
  getIssueStatus,
  getIssueStatusForClient,
} from 'shared/constants/issues';
import {
  NotificationType,
  NotificationTypeIntlKey,
} from 'shared/constants/notifications';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setDarkMode } from 'context';
import { DOLPHIN_API } from 'shared/constants/apis';
import { LANGUAGE, USER_DATA } from 'shared/constants/users';
import MDTypography from 'components/MDTypography';
import NotificationItem from 'pages/parts/Notifications/NotificationItem';
import {
  Content,
  TopBar,
  FilterContent,
  BottomBar,
} from 'pages/parts/Notifications/styles';
import { ServiceType } from 'shared/constants/service';
import { getNotificationLink } from './utils';

/**
 * NotificationDropdown component
 *
 * Props:
 *  - notifications: array of notification objects
 *  - onMarkAllAsRead: function to mark all notifications as read
 *  - onLoadMore: function triggered when clicking the "Load more" button
 */
const Notifications = ({
  notifications,
  setNotifications,
  numNewNotifications,
  setNumNewNotifications,
  notificationsPreferences,
  onMarkAllAsRead,
  onLoadMore,
}) => {
  const history = useHistory();
  // State for filter menu
  const [serviceFilterAnchorEl, setServiceFilterAnchorEl] = useState(null);
  const [typeFilterAnchorEl, setTypeFilterAnchorEl] = useState(null);
  const [selectedServiceFilter, setSelectedServiceFilter] = useState('all');
  const [selectedTypeFilter, setSelectedTypeFilter] = useState(
    NotificationType.ALL
  );
  const [loadedAllNotifications, setLoadedAllNotifications] = useState(false);

  const getNotificationKeysByService = (preferences, service) => {
    if (preferences[service]) {
      return Object.entries(preferences[service])
        .sort(([, a], [, b]) => a.displayIndex - b.displayIndex)
        .map(([key]) => key);
    }
    return [];
  };

  const seimitsuNotificationKeys = React.useMemo(
    () => getNotificationKeysByService(notificationsPreferences, 'seimitsu'),
    [notificationsPreferences]
  );

  const kenshinNotificationKeys = React.useMemo(
    () => getNotificationKeysByService(notificationsPreferences, 'kenshin'),
    [notificationsPreferences]
  );

  console.log('notificationsPreferences', notificationsPreferences);
  console.log('Seimitsu Notification Keys:', seimitsuNotificationKeys);
  console.log('Kenshin Notification Keys:', kenshinNotificationKeys);

  const [typesArray, setTypesArray] = useState([
    ...seimitsuNotificationKeys,
    ...kenshinNotificationKeys,
    NotificationType.ALL,
  ]);

  useEffect(() => {
    if (selectedServiceFilter === 'seimitsu') {
      setTypesArray([...seimitsuNotificationKeys, NotificationType.ALL]);
    } else if (selectedServiceFilter === 'kenshin') {
      setTypesArray([...kenshinNotificationKeys, NotificationType.ALL]);
    } else if (selectedServiceFilter === 'all') {
      setTypesArray([
        ...seimitsuNotificationKeys,
        ...kenshinNotificationKeys,
        NotificationType.ALL,
      ]);
    }
  }, [
    selectedServiceFilter,
    seimitsuNotificationKeys,
    kenshinNotificationKeys,
  ]);

  const handleMarkAllAsRead = () => {
    const variables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        userId: localData.get(USER_DATA.USER_ID),
      },
      data: {
        type: 'MARK_ALL_CLICKED',
      },
    };
    api.put(DOLPHIN_API.NOTIFICATIONS, variables).then(async (data) => {
      if (data.message === 'ok') {
        // if succeed, update local
        const unclickedNotificationsCount = data.unclickedNotificationsCount;
        const newNotifications = [...notifications];
        newNotifications.forEach((notification) => {
          notification.clicked = true;
        });
        setNotifications([...newNotifications]);
        setNumNewNotifications(unclickedNotificationsCount);
      }
    });
  };

  const handleNotificationItemClick = (item) => {
    const variables = {
      params: {
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        userId: localData.get(USER_DATA.USER_ID),
      },
      data: {
        type: 'MARK_CLICKED',
        ids: [item.id],
      },
    };
    api.put(DOLPHIN_API.NOTIFICATIONS, variables).then(async (data) => {
      // if succeed, update local
      if (data.message === 'ok') {
        const unclickedNotificationsCount = data.unclickedNotificationsCount;
        const newNotifications = [...notifications];
        newNotifications.forEach((notification) => {
          if (notification.id === item.id) {
            notification.clicked = true;
          }
        });
        setNotifications([...newNotifications]);
        setNumNewNotifications(unclickedNotificationsCount);
      }
    });

    const link = getNotificationLink(item);
    history.push({ pathname: link });
    // setOpenNotificationMenu(false);
  };

  const loadMoreNotifications = () => {
    const apiVariables = {
      params: {
        userId: localData.get(USER_DATA.USER_ID),
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        incrementFetch: true,
        timeThreshold:
          notifications.length > 0
            ? notifications[notifications.length - 1].createdAt
            : new Date().toISOString(),
      },
    };
    api.get(DOLPHIN_API.NOTIFICATIONS, apiVariables).then(async (data) => {
      if (data.notifications.length === 0) {
        setLoadedAllNotifications(true);
      } else {
        const newNotifications = [...notifications, ...data.notifications];
        setNotifications(newNotifications);
        setNumNewNotifications(data.unclickedNotificationsCount);
      }
    });
  };

  const handleServiceFilterButtonClick = (event) => {
    setServiceFilterAnchorEl(event.currentTarget);
  };

  const handleTypeFilterButtonClick = (event) => {
    setTypeFilterAnchorEl(event.currentTarget);
  };

  // Close the filter menu
  const handleServiceFilterClose = () => {
    setServiceFilterAnchorEl(null);
  };

  const handleTypeFilterClose = () => {
    setTypeFilterAnchorEl(null);
  };

  // Handle filter selection
  const handleServiceFilterSelect = (filter) => {
    setSelectedServiceFilter(filter);
    handleServiceFilterClose();
  };

  const handleTypeFilterSelect = (filter) => {
    setSelectedTypeFilter(filter);
    handleTypeFilterClose();
  };

  // Filter the notifications array based on the selected service and type filters
  const filteredNotifications = notifications.filter((n) => {
    const serviceFilterMatch =
      selectedServiceFilter === 'all' || n.service === selectedServiceFilter;
    const typeFilterMatch =
      selectedTypeFilter === NotificationType.ALL ||
      n.type === selectedTypeFilter;
    return serviceFilterMatch && typeFilterMatch;
  });

  return (
    <Content data-testid="notifications-box">
      <TopBar>
        {/* 1. Top bar with filter button (left) and "mark all as read" button (right) */}
        <Box>
          <Button
            startIcon={<FilterListIcon />}
            onClick={handleServiceFilterButtonClick}
            variant="text">
            {`${intl.get('notifications_topbar_text_service')}: (${intl.get(
              `notifications_service_${selectedServiceFilter}`
            )})`}
          </Button>
          <Menu
            anchorEl={serviceFilterAnchorEl}
            PaperProps={{
              style: {
                padding: 0,
              },
            }}
            open={Boolean(serviceFilterAnchorEl)}
            onClose={handleServiceFilterClose}>
            <FilterContent>
              {[ServiceType.SEIMITSU, ServiceType.KENSHIN, 'all'].map(
                (filter) => (
                  <MenuItem
                    key={filter}
                    onClick={() => handleServiceFilterSelect(filter)}>
                    {intl.get(`notifications_service_${filter}`)}
                  </MenuItem>
                )
              )}
            </FilterContent>
          </Menu>
        </Box>
        {/* <Box>
          <Button
            startIcon={<FilterListIcon />}
            onClick={handleTypeFilterButtonClick}
            variant="text">
            {`${intl.get('notifications_topbar_text_type')}: (${intl.get(
              NotificationTypeIntlKey[selectedTypeFilter]
            )})`}
          </Button>
          <Menu
            anchorEl={typeFilterAnchorEl}
            open={Boolean(typeFilterAnchorEl)}
            onClose={handleTypeFilterClose}
            PaperProps={{
              style: {
                padding: 0,
              },
            }}>
            <FilterContent>
              {typesArray.map((filter) => (
                <MenuItem
                  key={filter}
                  onClick={() => handleTypeFilterSelect(filter)}>
                  {intl.get(NotificationTypeIntlKey[filter])}
                </MenuItem>
              ))}
            </FilterContent>
          </Menu>
        </Box> */}
        {/* Mark All as Read Button */}
        <Button variant="text" onClick={handleMarkAllAsRead}>
          {intl.get('notifications_button_mark_all_as_read')}
        </Button>
      </TopBar>

      {/* 2. Content area (scrollable, max height set via the parent Box) */}
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        {filteredNotifications.map((notification, index) => (
          <NotificationItem
            key={index}
            notification={notification}
            handleItemClick={handleNotificationItemClick}
          />
        ))}

        {/* If no notifications are available after filtering, display a message */}
        {filteredNotifications.length === 0 && (
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="body2">
              {intl.get('notifications_text_no_new_notifications')}
            </Typography>
          </Box>
        )}
      </Box>

      {loadedAllNotifications ? (
        <Box sx={{ p: 1, textAlign: 'center' }}>
          <Typography variant="subtitle3">
            {intl.get('notifications_text_all_notifications_loaded')}
          </Typography>
        </Box>
      ) : (
        <BottomBar>
          <Button
            variant="text"
            onClick={loadMoreNotifications}
            sx={{ fontSize: '14px' }}>
            {intl.get('notifications_button_load_more')}
          </Button>
        </BottomBar>
      )}
    </Content>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  setNotifications: PropTypes.func.isRequired,
  numNewNotifications: PropTypes.number.isRequired,
  setNumNewNotifications: PropTypes.func.isRequired,
  notificationsPreferences: PropTypes.object.isRequired,
  onMarkAllAsRead: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
};

export default Notifications;
