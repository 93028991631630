import { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { NavLink, Link } from 'react-router-dom';
import MDBox from 'components/MDBox';

import iconNewInChargeDark from 'assets/images/icons/icon-homepage-new-in-charge-dark.svg';
import iconNewInChargeLight from 'assets/images/icons/icon-homepage-new-in-charge-light.svg';
import iconNewAssignedDark from 'assets/images/icons/icon-homepage-new-assigned-dark.svg';
import iconNewAssignedLight from 'assets/images/icons/icon-homepage-new-assigned-light.svg';
import HomeCard from '../HomeCard';
import MDButton from 'components/MDButton';

const propTypes = {
  numberNewInCharge: PropTypes.number,
  numNewAssigned: PropTypes.number.isRequired,
};

export const DoctorHomeDashboard = ({ numberNewInCharge, numNewAssigned }) => {
  return (
    <Fragment>
      <MDBox
        display="flex"
        sx={{ flexDirection: 'column' }}
        justifyContent="center"
        alignItems="center"
        px={1.5}
        component={NavLink}
        to={'/assignment/received'}>
        <HomeCard
          badgeContent={numNewAssigned}
          imgDarkSource={iconNewAssignedDark}
          imgLightSource={iconNewAssignedLight}
          text={intl.get('home_link_new_assigned_issues')}
        />
      </MDBox>

      <MDBox
        display="flex"
        sx={{ flexDirection: 'column' }}
        justifyContent="center"
        alignItems="center"
        px={1.5}
        component={NavLink}
        to="/assignment/sent">
        <HomeCard
          badgeContent={numberNewInCharge}
          imgDarkSource={iconNewInChargeDark}
          imgLightSource={iconNewInChargeLight}
          text={intl.get('home_link_new_in_charge_issues')}
        />
      </MDBox>
    </Fragment>
  );
};

DoctorHomeDashboard.propTypes = propTypes;
