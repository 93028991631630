import { NotificationType } from 'shared/constants/notifications';

export const NotificationTypeIntlKey = {
  [NotificationType.NEW_ISSUE]: 'personal_settings_notification_type_new_issue',
  [NotificationType.NEW_COMMENT]:
    'personal_settings_notification_type_new_comment',
  [NotificationType.ASSIGN_DOCTOR_IN_CHARGE]:
    'personal_settings_notification_type_assign_doctor_in_charge',
  [NotificationType.ASSIGN_DOCTOR]:
    'personal_settings_notification_type_assign_doctor',
  [NotificationType.RELEASE_DOCTOR]:
    'personal_settings_notification_type_release_doctor',
  [NotificationType.STATE_CHANGE]:
    'personal_settings_notification_type_state_change',
  [NotificationType.STATUS_CHANGE]:
    'personal_settings_notification_type_status_change',
  [NotificationType.BILLING_QUESTION]:
    'personal_settings_notification_type_billing',
  [NotificationType.NEW_ISSUE_KENSHIN]:
    'personal_settings_notification_type_new_issue_kenshin',
  [NotificationType.NEW_COMMENT_KENSHIN]:
    'personal_settings_notification_type_new_comment_kenshin',
  [NotificationType.ASSIGN_DOCTOR_KENSHIN]:
    'personal_settings_notification_type_assign_doctor_kenshin',
  [NotificationType.RELEASE_DOCTOR_KENSHIN]:
    'personal_settings_notification_type_release_doctor_kenshin',
  [NotificationType.STATE_CHANGE_KENSHIN]:
    'personal_settings_notification_type_state_change_kenshin',
  [NotificationType.STATUS_CHANGE_KENSHIN]:
    'personal_settings_notification_type_status_change_kenshin',
};
