import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Switch } from '@material-ui/core';
import styled from 'styled-components';

const SwitchArea = styled.div`
  display: flex;
  align-items: center; // Align items vertically
`;

const SwitchWithLabels = ({
  preferences,
  service,
  notificationKey,
  channel,
  handleChangePreference,
}) => {
  return (
    <SwitchArea>
      <Typography variant="body1">OFF</Typography>
      <Switch
        checked={preferences[notificationKey][channel]}
        onChange={(event, checked) => {
          handleChangePreference(service, notificationKey, channel, checked);
        }}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography variant="body1">ON</Typography>
    </SwitchArea>
  );
};

SwitchWithLabels.propTypes = {
  preferences: PropTypes.object.isRequired,
  service: PropTypes.string.isRequired,
  notificationKey: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  handleChangePreference: PropTypes.func.isRequired,
};

export default SwitchWithLabels;
