import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import intl from 'react-intl-universal';
import { styles } from './styles';
import PropTypes from 'prop-types';

const propTypes = {
  reportContent: PropTypes.object.isRequired,
};

const ReportDateAndDoctors = ({ reportContent }) => {
  const signature = reportContent.signature;
  return (
    <Fragment>
      <View style={styles.reportDateAndDoctors}>
        <View style={styles.reportDate}>
          <Text>
            {intl.get('issue_details_report_preview_text_report_date')}
          </Text>
        </View>
        <View style={styles.reportDoctors}>
          <Text>
            {intl.get('issue_details_report_preview_text_doctors_from_dst_org')}
            {signature}
          </Text>
        </View>
      </View>
    </Fragment>
  );
};

ReportDateAndDoctors.propTypes = propTypes;

export default ReportDateAndDoctors;
