import React, { Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { useLatest } from 'ahooks';
import { Modal, MenuItem } from '@material-ui/core';
import Grid from '@mui/material/Grid2';

import { REPORT_TEMPLATE_LIST } from './Templates/constants';
import ReportList from './ReportList';
import ReportEditor from './ReportEditor';
import Confirm from './Confirm';
import localData from 'shared/utils/localData';
import { Button as MUIButton, Menu } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useStyles } from './styles';
import { makeTagString } from 'pages/Dashboard/New/utils';
import ReportPreview from './ReportPreview';
import { USER_COMMENT } from 'shared/constants/comment';
import { USER_DATA } from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';
import { IssueStatus } from 'shared/constants/issues';
import api from 'shared/utils/api';

import { formatDateTime, formatDateTitle } from 'shared/utils/formatTime';
import { AttachmentTitle } from '../AttachmentList/Styles';
import ReportSelection from './ReportSelection';
import {
  generatePDFFromTemplate,
  getPresignedURL,
  loadPdfFromPresignedURL,
} from 'utils/ReportUtils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SummarizeIcon from '@mui/icons-material/Summarize';

const propTypes = {
  issue: PropTypes.object.isRequired,
  isDoctor: PropTypes.bool.isRequired,
  isClient: PropTypes.bool.isRequired,
  reloadIssue: PropTypes.func.isRequired,
};

const ProjectBoardIssueDetailsReports = ({
  issue,
  isDoctor,
  isClient,
  reloadIssue,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // for reporting modal
  const [reportEditorModalOpen, setReportEditorModalOpen] = useState(false);
  const [reportEditorIndex, setReportEditorIndex] = useState(-1);
  const latestReportEditorIndex = useLatest(reportEditorIndex);
  const [reportContent, setReportContent] = useState({
    fileName: '',
    issueId: '',
    issueTitle: '',
    issueDate: '',
    patientName: '',
    patientGender: '',
    patientBirthdayDate: '',
    doctorsFromSrcOrg: '',
    doctorsFromDstOrg: '',
    department: '',
    srcOrgName: '',
    dsrOrgname: '',
    tags: '',
    description: '',
    measures: '',
    signature: '',
    diagnosis: '',
    keyImages: [],
    reportDate: '',
    template: REPORT_TEMPLATE_LIST[0],
  });

  const latestReportContent = useLatest(reportContent);
  const [reportPreviewModalOpen, setReportPreviewModalOpen] = useState(false);
  const [previewReportContent, setPreviewReportContent] = useState({
    fileName: '',
    issueId: '',
    issueTitle: '',
    issueDate: '',
    patientName: '',
    patientGender: '',
    patientBirthdayDate: '',
    doctorsFromSrcOrg: '',
    doctorsFromDstOrg: '',
    department: '',
    srcOrgName: '',
    dsrOrgname: '',
    tags: '',
    signature: '',
    description: '',
    measures: '',
    diagnosis: '',
    keyImages: [],
    reportDate: '',
    template: REPORT_TEMPLATE_LIST[0],
  });
  const latestPreviewReportContent = useLatest(previewReportContent);

  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState(false);
  const [confirmPublishModalOpen, setConfirmPublishModalOpen] = useState(false);
  const [reportToPublishIndex, setReportToPublishIndex] = useState(-1);
  const latestReportToPublishIndex = useLatest(reportToPublishIndex);
  const [selectReportTypeModalOpen, setSelectReportTypeModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [attachmentList, setAttachmentList] = useState([]);
  const [selectedReportTemplate, setSelectedReportTemplate] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const issueIsDone = issue.status === IssueStatus.DONE;

  const handleMessageFromViewer = async (event) => {
    if (event.type === 'message' && event.data.messageType === 'keyImage') {
      const fileName = event.data.fileName;
      const fileBuffer = await event.data.blob.arrayBuffer();
      let newKeyImages = [...latestReportContent.current.keyImages];
      newKeyImages.push({
        fileName: fileName,
        fileBuffer: Buffer.from(fileBuffer),
      });
      setReportContent((prevState) => {
        return {
          ...prevState,
          keyImages: newKeyImages,
        };
      });
    } else if (
      event.type === 'message' &&
      event.data.messageType === 'measurement'
    ) {
      // TODO: Add measurement to the report
    }
  };

  const PDFAttachments = (issue) => {
    const PDFAttachments = [
      ...issue.attachmentsOwnedBySrcOrg,
      ...issue.attachmentsOwnedByDstOrg,
    ];
    return PDFAttachments.filter((attachment) =>
      attachment.title.endsWith('.pdf')
    );
  };

  const handleDownloadButtonClick = (reportData) => {
    if (
      'template' in reportData.content &&
      reportData.content.template.type === 'ATTACHMENT'
    ) {
      getPresignedURL(reportData.content.template.id, issue.id).then(
        (presignedUrl) => {
          loadPdfFromPresignedURL(presignedUrl, reportData.content).then(
            (url) => {
              const link = document.createElement('a');
              link.href = url;
              link.download = reportData.fileName + '.pdf';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            }
          );
        }
      );
    } else {
      generatePDFFromTemplate(
        //! Need to do this as the template react Object is not saved into the DB else backwards compatibility
        reportData.content.template
          ? REPORT_TEMPLATE_LIST.find(
              (template) => template.id === reportData.content.template.id
            )
          : REPORT_TEMPLATE_LIST[0],
        reportData.content,
        reportData.fileName
      ).then((url) => {
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.download = reportData.fileName + '.pdf';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
    }
  };

  const handlePreviewButtonClick = (report) => {
    console.log('handlePreviewButtonClick: ', report);
    setPreviewReportContent({
      ...report.content,
      //! To be able to be backwards compatible
      template: report.content.template
        ? report.content.template
        : REPORT_TEMPLATE_LIST[0],
    });
    setReportPreviewModalOpen(true);
  };

  const handleEditButtonClick = (report, index) => {
    //! Adding support for backwards compatibility
    if (!report.content.template) {
      report.content.template = REPORT_TEMPLATE_LIST[0];
    }
    setSelectedReportTemplate(report.content.template);
    if (latestReportEditorIndex.current !== index) {
      setReportContent({
        ...report.content,
      });
      setReportEditorIndex(index);
    }
    setReportEditorModalOpen(true);
  };

  const handleTemplateReportCreateButtonClick = () => {
    setAttachmentList(REPORT_TEMPLATE_LIST);
    setSelectReportTypeModalOpen(true);
  };

  const handleExistingPDFReportCreateButtonClick = () => {
    setAttachmentList(PDFAttachments(issue));
    setSelectReportTypeModalOpen(true);
  };

  const handlePublishButtonClick = (report, index) => {
    setReportToPublishIndex(index);
    setConfirmPublishModalOpen(true);
  };

  const initReportContent = (template) => {
    // get tags
    // TODO: Change to another tag system
    const CTTagsArray = [];
    const MRITagsArray = [];
    const PhyExamTagsArray = [];
    const tags = issue.tags;

    const tagsObject = {};
    for (var i = 0; i < tags.length; i++) {
      tagsObject[tags[i]] = true;
    }
    for (var j = 0; j < tags.length; j++) {
      const key = tags[j];
      const res = makeTagString(key, tagsObject);
      if (res) {
        if (key.startsWith('CT')) CTTagsArray.push(res);
        if (key.startsWith('MRI')) MRITagsArray.push(res);
        if (key.startsWith('PhyExam')) PhyExamTagsArray.push(res);
      }
    }

    const tagsText = issue.tags.join('; ');

    const issueDate = issue.createdAt.split('T')[0];

    setReportContent({
      fileName: `${formatDateTitle(new Date())}`,
      issueId: issue.id,
      issueTitle: issue.title,
      issueDate: issueDate,
      patientName: issue.patientName,
      patientGender: issue.patientGender,
      patientBirthdayDate: issue.patientBirthdayDate,
      doctorsFromSrcOrg: issue.doctorsInCharge.join(', '),
      doctorsFromDstOrg: '',
      department: issue.department,
      srcOrgName: issue.srcOrgName,
      dsrOrgname: issue.dstOrgName,
      tags: tagsText,
      description: issue.descriptionText,
      measures: '',
      diagnosis: '',
      signature: '',
      keyImages: [],
      reportDate: formatDateTime(new Date()),
      template: template,
    });
  };

  //! Save the report
  const saveReportContent = async (index) => {
    try {
      if (index === -1) {
        // new report
        let newKeyImages = [];
        latestReportContent.current.keyImages.forEach((image) => {
          newKeyImages.push({
            fileName: image.fileName,
            fileBuffer: Buffer.from(image.fileBuffer).toString(),
          });
        });

        const apiVariables = {
          'Content-Type': 'multipart/form-data',
          data: {
            fileName: latestReportContent.current.fileName,
            creatorId: localData.get(USER_DATA.CURRENT_USER_ID),
            isPublished: false,
            content: {
              ...latestReportContent.current,
            },
          },
          params: {
            organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
            issueId: issue.id,
          },
        };

        await api.post(`${DOLPHIN_API.REPORTS}/create`, apiVariables);
        await reloadIssue();
      } else {
        // old report
        const apiVariables = {
          'Content-Type': 'multipart/form-data',
          data: {
            fileName: latestReportContent.current.fileName,
            creatorId: localData.get(USER_DATA.CURRENT_USER_ID),
            content: {
              ...latestReportContent.current,
            },
            index: index,
            reportId: issue.reports[index].id,
          },
          params: {
            organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
            issueId: issue.id,
          },
        };

        await api.post(`${DOLPHIN_API.REPORTS}/update`, apiVariables);
        await reloadIssue();
      }
    } catch (error) {
      console.error('save report: ', error);
    }
  };

  // The publish report only works for the current template
  const publishReport = async (index) => {
    try {
      const apiVariables = {
        'Content-Type': 'multipart/form-data',
        data: {
          reportId: issue.reports[index].id,
        },
        params: {
          organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
          issueId: issue.id,
        },
      };

      await api.post(`${DOLPHIN_API.REPORTS}/publish`, apiVariables);
      await reloadIssue();
    } catch (error) {
      console.error('publish report: ', error);
    }
  };

  // Add new menu items
  const CREATE_REPORT_MENU_LIST = [
    {
      id: 1,
      title: 'issue_details_report_list_button_create_from_template',
      onClick: handleTemplateReportCreateButtonClick,
    },
    {
      id: 2,
      title: 'issue_details_report_list_button_create_from_pdf',
      onClick: handleExistingPDFReportCreateButtonClick,
    },
  ];

  useEffect(() => {
    initReportContent();
    window.addEventListener('message', handleMessageFromViewer);
    return () => {
      window.removeEventListener('message', handleMessageFromViewer);
    };
  }, []);

  return (
    <Fragment>
      <Grid container spacing={1} alignItems="center">
        <Grid style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <AttachmentTitle
            style={{
              fontSize: '16px',
            }}>
            📝 {intl.get('issue_details_report_list_label_reports')}
          </AttachmentTitle>
        </Grid>

        {/* TODO Add styling */}
        {!isClient && isDoctor && !issueIsDone && (
          <Grid
            size={5}
            style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <MUIButton
                variant="contained"
                startIcon={<SummarizeIcon />}
                sx={{ justifyContent: 'start', color: '#FFF' }}
                onClick={handleClick}>
                {intl.get('issue_details_report_editor_button_open')}
                <KeyboardArrowDownIcon
                  sx={{
                    marginLeft: '10px',
                    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                  }}
                />
              </MUIButton>

              <Menu
                id="create-new-report-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {CREATE_REPORT_MENU_LIST.map((menu) => (
                  <MenuItem
                    key={menu.id}
                    onClick={() => {
                      menu.onClick();
                      setSelectedReportType(menu.id);
                      handleClose();
                    }}>
                    {intl.get(menu.title)}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Grid>
        )}
      </Grid>

      <ReportList
        issue={issue}
        isDoctor={isDoctor}
        isClient={isClient}
        handleDownloadButtonClick={handleDownloadButtonClick}
        handlePreviewButtonClick={handlePreviewButtonClick}
        handleEditButtonClick={handleEditButtonClick}
        handlePublishButtonClick={handlePublishButtonClick}
      />

      {/* Select Report */}
      <Modal
        open={selectReportTypeModalOpen}
        onClose={() => setSelectReportTypeModalOpen(false)}
        className={classes.reportingModal}>
        <ReportSelection
          selectionList={attachmentList}
          title={
            selectedReportType === 1
              ? intl.get(
                  'issue_details_report_editor_button_open_select_template_menu_title'
                )
              : intl.get(
                  'issue_details_report_editor_button_open_select_pdf_menu_title'
                )
          }
          onSelect={(selectedReport) => {
            setSelectReportTypeModalOpen(false);
            setSelectedReportTemplate(selectedReport);
            initReportContent(selectedReport);
            setReportEditorIndex(-1);
            setReportEditorModalOpen(true);
          }}
          onClose={() => setSelectReportTypeModalOpen(false)}
        />
      </Modal>

      {/* Report editor */}
      <Modal
        open={reportEditorModalOpen}
        onClose={() => setReportEditorModalOpen(false)}
        className={classes.reportingModal}>
        <div className={classes.reportingEditorModalPaperWithPreview}>
          <ReportEditor
            issueId={issue.id}
            template={selectedReportTemplate}
            isEditable={true}
            reportContent={latestReportContent.current}
            setReportContent={setReportContent}
            reportEditorIndex={latestReportEditorIndex.current}
            saveReportContent={() => {
              setConfirmSaveModalOpen(true);
              setReportEditorModalOpen(false);
            }}
            modalClose={() => setReportEditorModalOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        data-testid="reportPreviewModal"
        open={reportPreviewModalOpen}
        onClose={() => setReportPreviewModalOpen(false)}
        className={classes.reportingModal}>
        <div className={classes.reportingPreviewModalPaper}>
          <ReportPreview
            reportContent={latestPreviewReportContent.current}
            template={latestPreviewReportContent.current.template}
            issueId={issue.id}
            modalClose={() => setReportPreviewModalOpen(false)}
          />
        </div>
      </Modal>

      <Confirm
        ifOpen={confirmSaveModalOpen}
        confirmMessage={intl.get(
          'issue_details_report_confirm_modal_text_message_save'
        )}
        onClose={() => {
          setConfirmSaveModalOpen(false);
        }}
        onConfirm={() => {
          saveReportContent(latestReportEditorIndex.current);
          enqueueSnackbar(
            latestReportContent.current.fileName +
              intl.get('issue_details_report_confirm_modal_text_message_saved'),
            {
              variant: 'success',
            }
          );
          setConfirmSaveModalOpen(false);
        }}
        onCancel={() => {
          setReportEditorModalOpen(true);
          setConfirmSaveModalOpen(false);
        }}
        confirmButtonText={intl.get(
          'issue_details_report_confirm_modal_button_save'
        )}
        cancelButtonText={intl.get(
          'issue_details_report_confirm_modal_button_cancel'
        )}
      />

      <Confirm
        ifOpen={confirmPublishModalOpen}
        confirmMessage={intl.get(
          'issue_details_report_confirm_modal_text_message_publish'
        )}
        onClose={() => {
          setConfirmPublishModalOpen(false);
        }}
        onConfirm={() => {
          publishReport(latestReportToPublishIndex.current);
          setConfirmPublishModalOpen(false);
          enqueueSnackbar(
            latestReportContent.current.fileName +
              intl.get(
                'issue_details_report_confirm_modal_text_message_published'
              ),
            {
              variant: 'success',
            }
          );
        }}
        onCancel={() => {
          setConfirmPublishModalOpen(false);
        }}
        confirmButtonText={intl.get(
          'issue_details_report_confirm_modal_button_publish'
        )}
        cancelButtonText={intl.get(
          'issue_details_report_confirm_modal_button_cancel'
        )}
      />
    </Fragment>
  );
};

ProjectBoardIssueDetailsReports.propTypes = propTypes;

export default ProjectBoardIssueDetailsReports;
