import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import { useSnackbar } from 'notistack';
import { Grid, Typography } from '@material-ui/core';
import Switch from '@mui/material/Switch';

import useApi from 'shared/hooks/api';
import api from 'shared/utils/api';

import { PageLoader, PageError } from 'components';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import FormField from 'pages/parts/FormField';
import FormFieldHelperText from 'pages/parts/FormFieldHelperText';
import pxToRem from 'assets/theme/functions/pxToRem';
import localData from 'shared/utils/localData';
import { ConfirmModal } from 'shared/modals/confirmModal';
import { setSavedName } from 'shared/utils/name';
import {
  LANGUAGE,
  TIMEZONE,
  USER_DATA,
  UserType,
} from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';
import { ServiceType } from 'shared/constants/service';
import { NotificationTypeIntlKey } from './constants';
import {
  TopActions,
  Content,
  Column,
  useStyles,
  // Left,
  Title,
  Actions,
  StyledButton,
  Row,
  PrivilegeDiv,
  SwitchArea,
  TitleSmall,
} from './styles';
import SwitchWithLabels from './Switch';

const propTypes = {
  userInfo: PropTypes.shape({
    firstName: PropTypes.string,
    username: PropTypes.string,
    userType: PropTypes.string,
    lastName: PropTypes.string,
    avataUrl: PropTypes.string,
    preferredLanguage: PropTypes.string,
    preferredTimezone: PropTypes.string,
  }),
};

const NotificationPreference = ({ userInfo }) => {
  const [formState, setFormState] = useState({
    firstName: userInfo.firstName,
  });

  const currentOrganizationId = localData.get('currentOrganizationId');
  const userId = localData.get(USER_DATA.USER_ID);
  const userType = localData.get(USER_DATA.USER_TYPE);

  const [preferencesSeimitsu, setPreferencesSeimitsu] = useState({});
  const [preferencesKenshin, setPreferencesKenshin] = useState({});
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await api.get(DOLPHIN_API.NOTIFICATION_PREFERENCE, {
          params: {
            organizationId: currentOrganizationId,
            userId: userId,
            service: ServiceType.SEIMITSU,
          },
        });
        setPreferencesSeimitsu(response.preferences);
      } catch (err) {
        setError(err);
      }
    };
    fetchPreferences();
  }, [currentOrganizationId, userId]);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await api.get(DOLPHIN_API.NOTIFICATION_PREFERENCE, {
          params: {
            organizationId: currentOrganizationId,
            userId: userId,
            service: ServiceType.KENSHIN,
          },
        });
        setPreferencesKenshin(response.preferences);
      } catch (err) {
        setError(err);
      }
    };
    fetchPreferences();
  }, [currentOrganizationId, userId]);

  if (error) return <PageError />;
  // If either preference is still null, show the loader
  if (
    Object.keys(preferencesSeimitsu).length === 0 ||
    Object.keys(preferencesKenshin).length === 0
  ) {
    return <PageLoader />;
  }
  const orderedNotificationKeysSeimitsu = Object.keys(preferencesSeimitsu).sort(
    (a, b) =>
      preferencesSeimitsu[a].displayIndex - preferencesSeimitsu[b].displayIndex
  );
  const orderedNotificationKeysKenshin = Object.keys(preferencesKenshin).sort(
    (a, b) =>
      preferencesKenshin[a].displayIndex - preferencesKenshin[b].displayIndex
  );

  const handleChangePreference = async (
    service,
    notificationKey,
    channel,
    checked
  ) => {
    const variables = {
      params: {
        organizationId: currentOrganizationId,
        userId: userId,
        service: service,
      },
      data: {
        preferences: {
          [notificationKey]: {
            [channel]: checked,
          },
        },
      },
    };
    api.put(DOLPHIN_API.NOTIFICATION_PREFERENCE, variables).then(
      (data) => {
        if (service === ServiceType.SEIMITSU) {
          setPreferencesSeimitsu((prevState) => ({
            ...prevState,
            [notificationKey]: {
              ...prevState[notificationKey],
              [channel]: checked,
            },
          }));
        }
      },
      (error) => {
        console.log(error);
        enqueueSnackbar('Failed to update settings.', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Content data-testid="notification-preference-content">
          <Row>
            <Column ratio={6} style={{ alignItems: 'flex-start' }}>
              <Title style={{ textAlign: 'left' }}>
                {intl.get('personal_settings_notification_title_text_seimitsu')}
              </Title>
            </Column>
            <Column ratio={3}>
              <Title style={{ textAlign: 'center' }}>
                {intl.get('personal_settings_notification_channel_inapp')}
              </Title>
            </Column>
            {/* <Column ratio={3}>
              <Title style={{ textAlign: 'center' }}>
                {intl.get('personal_settings_notification_channel_email')}
              </Title>
            </Column> */}
          </Row>

          {orderedNotificationKeysSeimitsu.map((notificationKey) => {
            if (!(notificationKey in preferencesSeimitsu)) return null;
            const intlKey = NotificationTypeIntlKey[notificationKey];
            return (
              <Row key={notificationKey}>
                <Column ratio={6} style={{ alignItems: 'flex-start' }}>
                  <PrivilegeDiv>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                      style={{ textAlign: 'left' }}>
                      {intl.get(intlKey)}
                    </Typography>
                  </PrivilegeDiv>
                </Column>
                <Column ratio={3}>
                  <SwitchWithLabels
                    preferences={preferencesSeimitsu}
                    service={ServiceType.SEIMITSU}
                    notificationKey={notificationKey}
                    channel="inapp"
                    handleChangePreference={handleChangePreference}
                  />
                </Column>
                {/* <Column ratio={3}>
                  <SwitchWithLabels
                    preferences={preferencesSeimitsu}
                    service={ServiceType.SEIMITSU}
                    notificationKey={notificationKey}
                    channel="email"
                    handleChangePreference={handleChangePreference}
                  />
                </Column> */}
              </Row>
            );
          })}

          <Row>
            <Column ratio={6} style={{ alignItems: 'flex-start' }}>
              <Title style={{ textAlign: 'left' }}>
                {intl.get('personal_settings_notification_title_text_kenshin')}
              </Title>
            </Column>
            <Column ratio={3}>
              <Title style={{ textAlign: 'center' }}>
                {intl.get('personal_settings_notification_channel_inapp')}
              </Title>
            </Column>
            {/* <Column ratio={3}>
              <Title style={{ textAlign: 'center' }}>
                {intl.get('personal_settings_notification_channel_email')}
              </Title>
            </Column> */}
          </Row>

          {orderedNotificationKeysKenshin.map((notificationKey) => {
            if (!(notificationKey in preferencesKenshin)) return null;
            const intlKey = NotificationTypeIntlKey[notificationKey];
            return (
              <Row key={notificationKey}>
                <Column ratio={6} style={{ alignItems: 'flex-start' }}>
                  <PrivilegeDiv>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                      style={{ textAlign: 'left' }}>
                      {intl.get(intlKey)}
                    </Typography>
                  </PrivilegeDiv>
                </Column>
                <Column ratio={3}>
                  <SwitchWithLabels
                    preferences={preferencesKenshin}
                    service={ServiceType.KENSHIN}
                    notificationKey={notificationKey}
                    channel="inapp"
                    handleChangePreference={handleChangePreference}
                  />
                </Column>
                {/* <Column ratio={3}>
                  <SwitchWithLabels
                    preferences={preferencesKenshin}
                    service={ServiceType.KENSHIN}
                    notificationKey={notificationKey}
                    channel="email"
                    handleChangePreference={handleChangePreference}
                  />
                </Column> */}
              </Row>
            );
          })}

          {/* <Actions>
            <StyledButton
              data-testid="user-privilege-yes-button"
              variant={'primary'}
              onClick={() => handleUpdateClick(true)}>
              Update
            </StyledButton>
            <StyledButton onClick={close}>Cancel</StyledButton>
          </Actions> */}
          {/* </Left> */}
        </Content>
      </Grid>
    </Grid>
  );
};

NotificationPreference.propTypes = propTypes;

export default withRouter(NotificationPreference);
