import React, { Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import StarIcon from '@mui/icons-material/Star';
// import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

import CustomTitle from './CustomTitle';
import CustomToolbarSelect from './CustomToolbarSelect';

import { formatTime } from 'shared/utils/formatTime';
import IssueDetails from 'pages/Dashboard/Kanban/IssueDetails';
import { Modal, RefreshButton } from 'components';

import useStyles from './styles';

import { getIssuePriority } from 'shared/constants/issues';
import { DESC } from '../../shared/constants';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { modalWidths } from 'shared/utils/styles';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes).isRequired,
  billData: PropTypes.arrayOf(
    PropTypes.shape({
      dstOrg: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    })
  ).isRequired,
  organizationName: PropTypes.string.isRequired,
  selectedDstOrgId: PropTypes.number.isRequired,
  setTableType: PropTypes.func.isRequired,
  refreshTableData: PropTypes.func.isRequired,
};

const DashboardBillingForSentTasksDetailedTable = ({
  match,
  history,
  billData,
  organizationName,
  selectedDstOrgId,
  setTableType,
  refreshTableData,
}) => {
  const classes = useStyles();

  const [data, setData] = useState([]);

  const goToViewer = (issueId) => {
    history.push({
      pathname: `/billing/sent-billing/seimitsu/issues/${issueId}`,
    });
  };

  useEffect(() => {
    const getDetailedTableData = (billData, selectedDstOrgId) => {
      const bills = billData.filter(
        (bill) => bill.dstOrg.id === selectedDstOrgId
      );

      const tableData = bills.map((bill) => {
        const {
          id,
          // issueCheckItem,
          issueCreatedAt,
          createdAt,
          issueId,
          issueTitle,
          issuePriority,
          originalFee,
          currentFee,
          starred,
        } = bill;

        return [
          issueTitle,
          getIssuePriority(issuePriority),
          issueCreatedAt,
          createdAt,
          {
            originalFee: originalFee,
            currentFee: currentFee,
          },
          {
            billId: id,
            issueId: issueId,
          },
          starred,
        ];
      });

      setData(tableData);
    };

    getDetailedTableData(billData, selectedDstOrgId);
  }, [billData, selectedDstOrgId]);

  const columns = [
    {
      name: 'issue_title',
      label: intl.get('billing_for_sent_cases_label_column_case_title'),
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const words1 = obj1.data.split('-');
            const words2 = obj2.data.split('-');
            if (words1[0] > words2[0]) {
              return order === DESC ? -1 : 1;
            } else if (words1[0] < words2[0]) {
              return order === DESC ? 1 : -1;
            } else {
              if (words1[1] > words2[1]) {
                return order === DESC ? -1 : 1;
              } else if (words1[1] < words2[1]) {
                return order === DESC ? 1 : -1;
              } else {
                return (
                  (parseInt(words1[2], 10) - parseInt(words2[2], 10)) *
                  (order === DESC ? -1 : 1)
                );
              }
            }
          };
        },
      },
    },
    {
      name: 'emergency',
      label: intl.get('billing_for_sent_cases_label_column_emergency'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'order_time',
      label: intl.get('billing_for_sent_cases_label_column_order_time'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatTime(value);
        },
      },
    },
    {
      name: 'complete_time',
      label: intl.get('billing_for_sent_cases_label_column_complete_time'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatTime(value);
        },
      },
    },
    {
      name: 'price',
      label: intl.get('billing_for_sent_cases_label_column_price'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const originalFee = value.originalFee;
          const currentFee = value.currentFee;

          if (currentFee === null || currentFee === originalFee) {
            return (
              <div>
                <p>￥{originalFee}</p>
              </div>
            );
          }
          return (
            <div>
              <p>
                ￥{currentFee} <del>￥{originalFee}</del>
              </p>
            </div>
          );
        },
      },
    },
    {
      name: 'view',
      label: intl.get('billing_for_sent_cases_label_column_details'),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const issueId = parseInt(value.issueId);
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => goToViewer(issueId)}>
                {intl.get('billing_for_sent_cases_button_go_to_viewer')}
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: 'flag',
      label: intl.get('billing_for_sent_cases_label_column_flag'),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const ifStarred = value;
          return (
            <div>
              {ifStarred && (
                <Tooltip
                  className={classes.tooltipMark}
                  title={intl.get(
                    'billing_for_sent_cases_detailed_tooltip_star_mark'
                  )}>
                  <StarIcon fontSize="large" color="warning" />
                </Tooltip>
              )}
              {/* {value.question && (
                <Tooltip
                  className={classes.tooltipMark}
                  title={intl.get(
                    'billing_for_sent_cases_detailed_tooltip_question_mark'
                  )}>
                  <HelpIcon fontSize="large" color="error" />
                </Tooltip>
              )} */}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    filterType: 'textField',
    responsive: 'stacked',
    rowsPerPage: 25,
    page: 1,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          data={data}
          setData={setData}
        />
      );
    },
    customToolbar: () => {
      return <RefreshButton handleRefreshClick={refreshTableData} />;
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      <MUIDataTable
        title={
          <CustomTitle
            dstOrgName={organizationName}
            setTableType={setTableType}
          />
        }
        data={data}
        columns={columns}
        options={options}
      />
      <Route
        path={`${match.path}/seimitsu/issues/:issueId`}
        render={(routeProps) => (
          <Modal
            isOpen
            testid="modal:issue-details-sent-tasks-from-billing"
            width={modalWidths.xxl}
            minWidth={modalWidths.xl}
            withCloseIcon={false}
            onClose={() => history.push(match.url)}
            renderContent={(modal) => (
              <IssueDetails
                issueId={routeProps.match.params.issueId}
                trigger={'byClick'}
                doctor={[]}
                modalClose={modal.close}
              />
            )}
          />
        )}
      />
    </Fragment>
  );
};

DashboardBillingForSentTasksDetailedTable.propTypes = propTypes;

export default withRouter(DashboardBillingForSentTasksDetailedTable);
