import intl from 'react-intl-universal';
import {
  IssueStatus,
  getIssueStatus,
  getIssueStatusForClient,
} from 'shared/constants/issues';
import localData from 'shared/utils/localData';
import { LANGUAGE, USER_DATA } from 'shared/constants/users';
import { NotificationType } from 'shared/constants/notifications';
import { ServiceType } from 'shared/constants/service';

export const generateNotificationText = (
  notificationType,
  notificationContents
) => {
  let text = '';

  if (localData.get(USER_DATA.PREFFERED_LANGUAGE) === LANGUAGE.ENGLISH) {
    switch (notificationType) {
      case NotificationType.NEW_ISSUE: {
        text = `${notificationContents[0]} sent a new case ${notificationContents[1]} to you.`;
        break;
      }
      case NotificationType.NEW_COMMENT: {
        text = `${notificationContents[0]} ${notificationContents[1]} ${
          notificationContents[2] === 'REPORT'
            ? 'submitted a report'
            : 'made a comment'
        } in ${notificationContents[3]}`;
        break;
      }
      case NotificationType.ASSIGN_DOCTOR_IN_CHARGE: {
        text = `You are assigned a new case: ${notificationContents[0]}.`;
        break;
      }
      case NotificationType.ASSIGN_DOCTOR: {
        text = `You are assigned a new case: ${notificationContents[0]}.`;
        break;
      }
      case NotificationType.RELEASE_DOCTOR: {
        text = `You are released from duty of case: ${notificationContents[0]}.`;
        break;
      }
      case NotificationType.STATE_CHANGE: {
        text = `There is a problem in ${notificationContents[0]}`;
        break;
      }
      case NotificationType.STATUS_CHANGE: {
        if (notificationContents[1] === IssueStatus.CHECKREADY) {
          text = `${
            notificationContents[0]
          }'s status changed into ${getIssueStatusForClient(
            IssueStatus.REPORTREADY
          )}`;
        } else if (notificationContents[1] === IssueStatus.REPORTREADY) {
          text = `${
            notificationContents[0]
          }'s status changed into ${getIssueStatus(IssueStatus.REPORTREADY)}`;
        } else if (notificationContents[1] === IssueStatus.SENDBACK) {
          text = `${
            notificationContents[0]
          }'s status changed into ${getIssueStatusForClient(
            IssueStatus.REPORTREADY
          )}`;
        } else if (notificationContents[1] === IssueStatus.DONE) {
          text = `${
            notificationContents[0]
          }'s status changed into ${getIssueStatus(IssueStatus.DONE)}`;
        }
        break;
      }
      case NotificationType.BILLING_QUESTION: {
        text = `There is a problem with ${notificationContents[0]}'s billing`;
        break;
      }
      default: {
        break;
      }
    }
  } else if (localData.get('userPreferredLanguage') === 'ja-JP') {
    switch (notificationType) {
      case NotificationType.NEW_ISSUE: {
        text = `${notificationContents[0]}から新しい依頼が届きました：${notificationContents[1]}`;
        break;
      }
      case NotificationType.NEW_COMMENT: {
        text = `${notificationContents[1]} ${notificationContents[0]}が${
          notificationContents[3]
        }に${
          notificationContents[2] === 'REPORT'
            ? 'レポートを投稿しました'
            : 'コメントしました'
        }`;
        break;
      }
      case NotificationType.ASSIGN_DOCTOR_IN_CHARGE: {
        text = `${notificationContents[0]}があなたに割り当てられました`;
        break;
      }
      case NotificationType.ASSIGN_DOCTOR: {
        text = `${notificationContents[0]}があなたに割り当てられました`;
        break;
      }
      case NotificationType.RELEASE_DOCTOR: {
        text = `あなたの割り当てがリムーブされました: ${notificationContents[0]}`;
        break;
      }
      case NotificationType.STATE_CHANGE: {
        text = `${notificationContents[0]}のステータスが「要確認」に変更されました`;
        break;
      }
      case NotificationType.STATUS_CHANGE: {
        if (notificationContents[1] === IssueStatus.CHECKREADY) {
          text = `以下のご依頼の受付をお知らせいたします：${notificationContents[0]}`;
        } else if (notificationContents[1] === IssueStatus.REPORTREADY) {
          text = `${notificationContents[0]}のレポートをご確認ください`;
        } else if (notificationContents[1] === IssueStatus.SENDBACK) {
          text = `以下のご依頼の返信をお知らせいたします：${notificationContents[0]}`;
        } else if (notificationContents[1] === IssueStatus.DONE) {
          text = `以下のご依頼のレポート受領をお知らせいたします：${notificationContents[0]}`;
        }
        break;
      }
      case NotificationType.BILLING_QUESTION: {
        text = `${notificationContents[0]}の請求に問題が発生しました。`;
        break;
      }
      default: {
        break;
      }
    }
  }
  return text;
};

export const getNotificationLink = (notification) => {
  const { service, type, link } = notification;
  const [linkType, issueId] = link.split('-');

  let finalLink = '';

  switch (service) {
    case ServiceType.SEIMITSU:
      switch (type) {
        case NotificationType.NEW_ISSUE:
          finalLink = `/history/received/issues/${issueId}`;
          break;
        case NotificationType.NEW_COMMENT:
          finalLink = `/history/${linkType}/issues/${issueId}`;
          break;
        case NotificationType.ASSIGN_DOCTOR_IN_CHARGE:
          finalLink = `/assignment/sent/issues/${issueId}`; //todo: check
          break;
        case NotificationType.ASSIGN_DOCTOR:
          finalLink = `/assignment/received/issues/${issueId}`;
          break;
        case NotificationType.RELEASE_DOCTOR:
          finalLink = `/assignment/received/issues/${issueId}`;
          break;
        case NotificationType.STATE_CHANGE:
          finalLink =
            linkType === 'received'
              ? `/task-management/task-management-regular/issues/${issueId}`
              : `/history/sent/issues/${issueId}`;
          break;
        case NotificationType.STATUS_CHANGE:
          finalLink = ``; //todo: to be implemented
          break;
        case NotificationType.BILLING_QUESTION:
          finalLink = `/billing/received-billing/issues/${issueId}`; //todo: check
          break;
        default:
          break;
      }
      break;
    case ServiceType.KENSHIN:
      //todo: to be implemented;
      finalLink = link;
      break;
    default:
      break;
  }

  return finalLink;
};
