import intl from 'react-intl-universal';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid2';
import { Button } from 'components';
import IconButton from '@mui/material/IconButton';
import AddCommentIcon from '@mui/icons-material/AddComment';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { formatBytes } from 'shared/utils/misc';
import { generate8DigitsIdentifier } from 'shared/utils/randomIdGenerator';

const FILE_TYPE = {
  DIRECTORY: 'directory',
  FILE: 'file',
};

// Currently only tested and used in the issueDetails page
export const newAttachmentList = ({
  parentIssue,
  attachments,
  dicomFilePaths,
  isClient,
  isDoctor,
  darkMode,
  handleCommentButtonClick,
  handleDownloadButtonClick,
  handleDicomPreviewButtonClick,
  handlePreviewButtonClick,
  handleViewerButtonClick,
  handleAddAttachmentToParentIssueButtonClick,
}) => {
  let filteredAttachments = attachments;
  const res = filteredAttachments.map((item, index) => {
    let dicoms;
    if (item.title in dicomFilePaths) {
      dicoms = dicomFilePaths[item.title];
    } else {
      // Only go this branch
      dicoms = item.dicomFilesInfo;
    }

    const structureJson = item.structureJson;

    const label =
      structureJson.type === FILE_TYPE.DIRECTORY
        ? structureJson.name
        : `📄 ${structureJson.name}  -  ${formatBytes(structureJson.size)}`;

    return (
      <Grid
        container
        spacing={3}
        style={{ margin: '5px' }}
        key={index}
        alignItems="center"
        justifyContent="flex-start">
        <Grid style={{ display: 'flex', alignItems: 'center', width: '500px' }}>
          {structureJson.type === FILE_TYPE.FILE ? (
            <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
              {label}
            </Typography>
          ) : (
            <SimpleTreeView
              collapseIcon={<ExpandMoreIcon />}
              expandIcon={<ChevronRightIcon />}
              style={{
                color: 'black',
                backgroundColor: 'white',
              }}
              sx={{
                pt: 0.5,
                maxheight: 400,
                flexGrow: 1,
                maxWidth: 500,
                overflowY: 'auto',
              }}>
              {generateTreeView(structureJson)}
            </SimpleTreeView>
          )}
        </Grid>

        <Grid container spacing={2}>
          <Grid>
            <IconButton
              onClick={() => handleCommentButtonClick(item)}
              aria-label={intl.get('issue_details_quote_filename_in_comment')}>
              <Tooltip
                title={intl.get('issue_details_quote_filename_in_comment')}>
                <AddCommentIcon />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid>
            <IconButton
              onClick={() => handleDownloadButtonClick(item)}
              aria-label={intl.get('issue_details_button_download')}>
              <Tooltip title={intl.get('issue_details_button_download')}>
                <DownloadIcon />
              </Tooltip>
            </IconButton>
          </Grid>

          {structureJson.type === 'file' &&
            (!isObject(dicoms) || Object.keys(dicoms).length === 0) && (
              <Grid>
                <IconButton
                  onClick={() => handlePreviewButtonClick(item)}
                  aria-label={intl.get('issue_details_button_preview')}>
                  <Tooltip title={intl.get('issue_details_button_preview')}>
                    <DashboardIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            )}

          {/* This button is for the preview of the DICOM files */}
          {isObject(dicoms) && Object.keys(dicoms).length > 0 && (
            <Grid>
              <IconButton
                onClick={() => handleDicomPreviewButtonClick(dicoms)}
                aria-label={intl.get('issue_details_button_preview')}>
                <Tooltip title={intl.get('issue_details_button_preview')}>
                  <DashboardIcon />
                </Tooltip>
              </IconButton>
            </Grid>
          )}

          {isDoctor && isObject(dicoms) && Object.keys(dicoms).length > 0 && (
            <Grid>
              <IconButton
                onClick={() => handleViewerButtonClick(item.id)}
                aria-label={intl.get('issue_details_button_jump_to_viewer')}>
                <Tooltip
                  title={intl.get('issue_details_button_jump_to_viewer')}>
                  <VisibilityIcon />
                </Tooltip>
              </IconButton>
            </Grid>
          )}

          {isClient && parentIssue && parentIssue !== undefined && (
            <Grid>
              <IconButton
                aria-label={intl.get(
                  'issue_details_button_attach_to_parent_issue'
                )}
                onClick={() =>
                  handleAddAttachmentToParentIssueButtonClick(item, parentIssue)
                }>
                <Tooltip
                  title={intl.get(
                    'issue_details_button_attach_to_parent_issue'
                  )}>
                  <AttachFileIcon />
                </Tooltip>
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  });

  return res;
};

// This function is the legacy function that needs to be updated
export const generateAttachmentList = ({
  parentIssue,
  attachments,
  dicomFilePaths,
  isClient,
  isDoctor,
  darkMode,
  handleCommentButtonClick,
  handleDownloadButtonClick,
  handleDicomPreviewButtonClick,
  handlePreviewButtonClick,
  handleViewerButtonClick,
  handleAddAttachmentToParentIssueButtonClick,
}) => {
  let filteredAttachments = attachments;

  // It loops per each attachment
  const res = filteredAttachments.map((item, index) => {
    let dicoms;
    if (item.title in dicomFilePaths) {
      dicoms = dicomFilePaths[item.title];
    } else {
      // Only go this branch
      dicoms = item.dicomFilesInfo;
    }

    const structureJson = item.structureJson;

    const label =
      structureJson.type === FILE_TYPE.DIRECTORY
        ? structureJson.name
        : `📄 ${structureJson.name}  -  ${formatBytes(structureJson.size)}`;

    return (
      <Grid container spacing={3} style={{ marginBottom: '5px' }} key={index}>
        <Grid xs>
          {structureJson.type === FILE_TYPE.FILE ? (
            <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
              {label}
            </Typography>
          ) : (
            <SimpleTreeView
              collapseIcon={<ExpandMoreIcon />}
              expandIcon={<ChevronRightIcon />}
              style={{
                color: 'black',
                backgroundColor: 'white',
              }}
              sx={{
                pt: 0.5,
                maxheight: 400,
                flexGrow: 1,
                maxWidth: 500,
                overflowY: 'auto',
              }}>
              {generateTreeView(structureJson)}
            </SimpleTreeView>
          )}
        </Grid>

        {/* There are 7 buttons out of these 7, 4 buttons are MUST */}
        <Grid container spacing={2} xs>
          <Grid item>
            <Button
              icon="page"
              iconSize={20}
              variant="empty"
              onClick={() => handleCommentButtonClick(item)}>
              {intl.get('issue_details_quote_filename_in_comment')}
            </Button>
          </Grid>

          <Grid item>
            <Button
              icon="arrow-down"
              iconSize={20}
              variant="empty"
              onClick={() => handleDownloadButtonClick(item)}>
              {intl.get('issue_details_button_download')}
            </Button>
          </Grid>

          {structureJson.type === 'file' &&
            (!isObject(dicoms) || Object.keys(dicoms).length === 0) && (
              <Grid item>
                <Button
                  icon="board"
                  iconSize={20}
                  variant="empty"
                  onClick={() => handlePreviewButtonClick(item)}>
                  {intl.get('issue_details_button_preview')}
                </Button>
              </Grid>
            )}

          {isObject(dicoms) && Object.keys(dicoms).length > 0 && (
            <Grid item>
              <Button
                icon="board"
                iconSize={20}
                variant="empty"
                onClick={() => handleDicomPreviewButtonClick(dicoms)}>
                {intl.get('issue_details_button_preview')}
              </Button>
            </Grid>
          )}

          {isDoctor && isObject(dicoms) && Object.keys(dicoms).length > 0 && (
            <Grid item xs={2}>
              <Button
                icon="board"
                iconSize={20}
                variant="empty"
                onClick={() => handleViewerButtonClick(item.id)}>
                {intl.get('issue_details_button_jump_to_viewer')}
              </Button>
            </Grid>
          )}

          {isClient && parentIssue && parentIssue !== undefined && (
            <Grid item xs={2}>
              <Button
                icon="attach"
                iconSize={20}
                variant="empty"
                onClick={() =>
                  handleAddAttachmentToParentIssueButtonClick(item, parentIssue)
                }>
                {intl.get('issue_details_button_attach_to_parent_issue')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  });

  return res;
};

export const generateTreeView = (structureObject, index) => {
  const labelString =
    structureObject.type === FILE_TYPE.DIRECTORY
      ? structureObject.name
      : `📄 ${structureObject.name}  -  ${formatBytes(structureObject.size)}`;
  const label = (
    <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
      {labelString}
    </Typography>
  );

  return (
    <TreeItem
      itemId={`${structureObject.name}-${generate8DigitsIdentifier()}`}
      label={label}
      key={index}>
      {structureObject.type === FILE_TYPE.DIRECTORY &&
        structureObject.children.map((item, index) =>
          generateTreeView(item, index)
        )}
    </TreeItem>
  );
};

export const isObject = (objValue) => {
  return (
    objValue && typeof objValue === 'object' && objValue.constructor === Object
  );
};

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      // Handle successful copy (e.g., show tooltip, change icon color)
      // alert('Text copied to clipboard!');
    })
    .catch((err) => {
      console.error('Error in copying text: ', err);
    });
};

/**
 * Converts a DICOMweb URL to a standard HTTP URL, fetches the resource,
 * and returns a URL representing the downloaded blob.
 *
 * This function is designed to work with URLs typically used in medical
 * imaging (DICOM). It replaces the custom 'dicomweb://' protocol with
 * 'https://' to make the URL compatible with standard web requests.
 *
 * @param {string} url - The DICOMweb URL to process.
 * @returns {Promise<string>} A promise that resolves to a blob URL
 *           representing the downloaded resource. This blob URL can
 *           be used in web applications to display or process the
 *           fetched resource.
 * @throws {Error} Throws an error if the network request fails.
 */
export const processOne = async (url) => {
  const newUrl = url.replace('dicomweb://', 'https://');
  const response = await fetch(newUrl);
  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  // window.URL.revokeObjectURL(downloadUrl);
  return blobUrl;
};

/**
 * Processes each URL in the attachments asynchronously.
 *
 * Iterates over the given attachments array, and for each attachment, iterates over its
 * `dicomFilesInfo` object. Each file path in `dicomFilesInfo` is processed by the `processOne`
 * function, and the result is used to update the original file path.
 *
 * @param {Object[]} attachments - An array of attachment objects. Each attachment should have a
 *                                 `dicomFilesInfo` property that is an object containing file paths.
 */
export const processUrls = async (attachments) => {
  const blobUrls = []; // Array to store blob URLs
  for (const attachment of attachments) {
    for (const [folderName, { filePaths }] of Object.entries(
      attachment.dicomFilesInfo
    )) {
      for (let i = 0; i < filePaths.length; i++) {
        const blobUrl = await processOne(filePaths[i]);
        attachment.dicomFilesInfo[folderName].filePaths[i] = blobUrl;
        blobUrls.push(blobUrl); // Add the generated blob URL to the array
      }
    }
  }
  return blobUrls;
};

/**
 * Processes an array of attachments and ranks them based on specific criteria.
 *
 * The function iterates through each attachment to calculate:
 * - The number of DICOM files.
 * - The size of the attachment.
 * - The average file size of the DICOM files.
 *
 * It then ranks the attachments based on the following rule:
 * - Only attachments with less than 10 DICOM files are considered.
 * - Among these attachments, those with a larger average file size are ranked higher.
 *
 * The function returns an array of integer indices representing the order of the attachments.
 *
 * @param {object[]} attachmentArray - An array of attachment objects, each with a `dicomFilesInfo`
 *                                     property (object containing file paths) and a `structureJson`
 *                                     property (object with size information).
 * @returns {number[]} An array of indices representing the ordered attachments.
 */
export const getPreFetchOrder = (attachmentArray) => {
  return attachmentArray
    .map((attachment, index) => {
      // Calculate the total number of DICOM files
      const totalFiles = Object.values(attachment.dicomFilesInfo).reduce(
        (sum, info) => sum + info.filePaths.length,
        0
      );

      // Get the size of the attachment, defaulting to 0 if undefined
      const totalSize = attachment.structureJson.size || 0;

      // Calculate average file size, handle division by zero
      const avgFileSize = totalFiles > 0 ? totalSize / totalFiles : 0;

      return {
        index,
        totalFiles,
        avgFileSize,
        isEligible: totalFiles <= 10,
      };
    })
    .filter((item) => item.isEligible) // Filter out attachments with more than 10 DICOM files
    .sort((a, b) => b.avgFileSize - a.avgFileSize) // Sort by average file size
    .map((item) => item.index); // Extract sorted indices
};
