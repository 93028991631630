/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Divider from '@mui/material/Divider';

import intl from 'react-intl-universal';

import Icon from '@mui/material/Icon';
// Custom styles for the Sidenav
import SidenavRoot from './SidenavRoot';

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentSidenav,
  setWhiteSidenav,
} from 'context';
import { UserProfile } from 'components/UserProfile/UserProfile';
import { createUsername } from 'utils/SidenavUtils';
import { USER_DATA } from 'shared/constants/users';
import localData from 'shared/utils/localData';
import { SideNavActionButton } from 'components/SidenavActionButton/SidenavActionButton';
import SidenavList from 'components/SidenavList/SidenavList';
import SidenavListItem from 'components/SidenavListItem/SidenavListItem';
import SidenavListCollapse from 'components/SidenavListCollapse/SidenavListCollapse';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Box } from '@material-ui/core';
import api from 'shared/utils/api';
import { DOLPHIN_API } from 'shared/constants/apis';
// Krill
import KrillChat from 'krill';

const ROUTES = {
  NEW: 'new',
  LOGS: 'logs',
  RECEIVED: 'received',
  MY_TASK: 'my-task',
  HISTORY: 'history',
  SENT: 'sent',
  BILLING: 'billing',
  RECEIVED_BILLING: 'received-billing',
  SENT_BILLING: 'sent-billing',
  SETTINGS: 'settings',
  MANUAL: 'manual',
};

function Sidenav({
  openDrawer,
  color,
  brand,
  brandName,
  routes,
  userInfo,
  handleClickLogout,
  ...rest
}) {
  // Note:
  // transparentSidenav, whiteSidenav are not changed whatsoever. They are fixed.
  // Code related to them can be deleted and replaced with fix value, if later versions do not use them
  const [controller, dispatch] = useMaterialUIController();
  const [openMenuCollapse, setOpenMenuCollapse] = useState(false);
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];
  const items = pathname.split('/').slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  // const krillChatContext = KrillChat.Context.useKrillChatContext();
  // const { recvFriendApplicationList } = krillChatContext;

  const currentOrganization = localData.get(USER_DATA.CURRENT_ORGANIZATION);
  const changeCurrentOrganization = (newOrganization, newOrganizationId) => {
    localData.set(USER_DATA.CURRENT_ORGANIZATION, newOrganization);
    localData.set(USER_DATA.CURRENT_ORGANIZATION_ID, newOrganizationId);
    api.post(`${DOLPHIN_API.USERS}/signin-organization`, {
      data: {
        username: userInfo.username,
        userId: userInfo.id,
        organizationId: newOrganizationId,
      },
    });
  };

  useEffect(() => {
    // setOpenCollapse(collapseName);
  }, [collapseName, itemParentName]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      // setMiniSidenav(dispatch, window.innerWidth < 1200); temp: DO NOT allow sidebar to minimize automatically;
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }
    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location, transparentSidenav, whiteSidenav]);

  // get all routes that should be displayed on the sidenav
  const newRoute = routes.find((route) => route.key === ROUTES.NEW);

  let newSeimitsuRoute, newKenshinRoute;
  if (newRoute) {
    newSeimitsuRoute = newRoute.collapse.find(
      (item) => item.key === 'new-regular'
    );
    newKenshinRoute = newRoute.collapse.find(
      (item) => item.key === 'new-kenshin'
    );
  }
  const taskManagements = routes.find(
    (route) => route.key === 'task-management'
  );

  let taskManagementSeimitsuRoute, taskManagementKenshinRoute;
  if (taskManagements) {
    taskManagementSeimitsuRoute = taskManagements.collapse.find(
      (item) => item.key === 'task-management-regular'
    );
    taskManagementKenshinRoute = taskManagements.collapse.find(
      (item) => item.key === 'task-management-kenshin'
    );
  }

  const histories = routes.find((route) => route.key === 'history');

  let historyReceivedSeimitsuRoute,
    historySentSeimitsuRoute,
    historyReceivedKenshinRoute,
    historySentKenshinRoute;
  if (histories) {
    historyReceivedSeimitsuRoute = histories.collapse.find(
      (item) => item.key === 'received'
    );
    historySentSeimitsuRoute = histories.collapse.find(
      (item) => item.key === 'sent'
    );
    historyReceivedKenshinRoute = histories.collapse.find(
      (item) => item.key === 'received-kenshin'
    );
    historySentKenshinRoute = histories.collapse.find(
      (item) => item.key === 'sent-kenshin'
    );
  }

  const billing = routes.find((route) => route.key === ROUTES.BILLING);
  let billingReceivedRoute, billingSentRoute;
  if (billing) {
    billingReceivedRoute = billing.collapse.find(
      (item) => item.key === 'received-billing'
    );
    billingSentRoute = billing.collapse.find(
      (item) => item.key === 'sent-billing'
    );
  }

  const assignment = routes.find((route) => route.key === 'assignment');
  let assignment_received, assignment_sent, assignment_received_kenshin;
  if (assignment) {
    assignment_received = assignment.collapse.find(
      (item) => item.key === 'received'
    );
    assignment_sent = assignment.collapse.find((item) => item.key === 'sent');
    assignment_received_kenshin = assignment.collapse.find(
      (item) => item.key === 'received-kenshin'
    );
  }
  const contacts = routes.find((route) => route.key === 'contacts');
  let contacts_friends;
  if (contacts) {
    contacts_friends = contacts.collapse.find((item) => item.key === 'friends');
  }

  const paidWage = routes.find((item) => item.key === 'paid-wage');
  const myWage = routes.find((item) => item.key === 'my-wage');
  const orgSettings = routes.find((item) => item.key === 'settings');

  const logs = routes.find((item) => item.key === 'logs');
  const manual = routes.find((item) => item.key === 'manual');
  const serviceTerms = routes.find((item) => item.key === 'service-terms');

  return (
    <Box component="nav" data-testid="sidenav-box">
      <SidenavRoot
        {...rest}
        open={openDrawer}
        variant="persistent"
        ownerState={{
          transparentSidenav,
          whiteSidenav,
          miniSidenav,
          darkMode,
        }}>
        <UserProfile
          userName={createUsername(
            userInfo.firstName,
            userInfo.lastName,
            userInfo.preferredLanguage
          )}
          userFirstName={userInfo.firstName}
          organizationList={userInfo.organizations}
          currentOrganization={currentOrganization}
          handleChangeCurrentOrganization={changeCurrentOrganization}
          handleClickLogout={handleClickLogout}
        />

        {newSeimitsuRoute && (
          <SideNavActionButton
            dataTestId="sidenav-button-new-seimitsu"
            startIcon={newRoute.icon}
            color="primary"
            text={newSeimitsuRoute.name}
            route={newSeimitsuRoute.route}
          />
        )}

        {newKenshinRoute && (
          <SideNavActionButton
            dataTestId="sidenav-button-new-kenshin"
            startIcon={newRoute.icon}
            color="primary"
            text={newKenshinRoute.name}
            route={newKenshinRoute.route}
          />
        )}

        {taskManagementSeimitsuRoute && (
          <SideNavActionButton
            dataTestId="sidenav-button-kanban-seimitsu"
            // This route doesn't have an icon
            startIcon={<AssignmentIcon />}
            color="tertiary"
            text={taskManagementSeimitsuRoute.name}
            route={taskManagementSeimitsuRoute.route}
          />
        )}

        {taskManagementKenshinRoute && (
          <SideNavActionButton
            // This route doesn't have an icon
            dataTestId="sidenav-button-kanban-kenshin"
            startIcon={<AssignmentIcon />}
            color="tertiary"
            text={taskManagementKenshinRoute.name}
            route={taskManagementKenshinRoute.route}
          />
        )}

        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />

        {histories && (
          <SidenavList
            title={intl.get('dashboard_sidebar_list_title_received_and_sent')}>
            {historySentSeimitsuRoute && (
              <NavLink to={historySentSeimitsuRoute.route}>
                <SidenavListItem
                  // badgeContent={2}
                  color={color}
                  name={historySentSeimitsuRoute.name}
                  icon={histories.icon}
                  isClickable={true}
                  active={location.pathname === historySentSeimitsuRoute.route}
                />
              </NavLink>
            )}

            {historyReceivedSeimitsuRoute && (
              <NavLink to={historyReceivedSeimitsuRoute.route}>
                <SidenavListItem
                  // badgeContent={2}
                  color={color}
                  name={historyReceivedSeimitsuRoute.name}
                  // icon={receivedHistoryRoute.icon ?? receivedRoute.icon}
                  icon={histories.icon}
                  isClickable={true}
                  active={
                    location.pathname === historyReceivedSeimitsuRoute.route
                  }
                />
              </NavLink>
            )}

            {historySentKenshinRoute && (
              <NavLink to={historySentKenshinRoute.route}>
                <SidenavListItem
                  // badgeContent={2}
                  color={color}
                  name={historySentKenshinRoute.name}
                  icon={histories.icon}
                  isClickable={true}
                  active={location.pathname === historySentKenshinRoute.route}
                />
              </NavLink>
            )}

            {historyReceivedKenshinRoute && (
              <NavLink to={historyReceivedKenshinRoute.route}>
                <SidenavListItem
                  // badgeContent={2}
                  color={color}
                  name={historyReceivedKenshinRoute.name}
                  icon={histories.icon}
                  isClickable={true}
                  active={
                    location.pathname === historyReceivedKenshinRoute.route
                  }
                />
              </NavLink>
            )}
          </SidenavList>
        )}

        {billing && (
          <SidenavList title={billing.name}>
            <NavLink to={billingSentRoute.route}>
              <SidenavListItem
                // badgeContent={0}
                color={color}
                name={billingSentRoute.name}
                icon={billing.icon}
                isClickable={true}
                active={location.pathname === billingSentRoute.route}
              />
            </NavLink>
            <NavLink to={billingReceivedRoute.route}>
              <SidenavListItem
                // badgeContent={2}
                color={color}
                name={billingReceivedRoute.name}
                icon={billing.icon}
                isClickable={true}
                active={location.pathname === billingReceivedRoute.route}
              />
            </NavLink>
          </SidenavList>
        )}

        {(paidWage || myWage) && (
          <SidenavList title={paidWage?.name || myWage?.name}>
            {paidWage && (
              <NavLink to={paidWage.route}>
                <SidenavListItem
                  color={color}
                  name={paidWage.name}
                  icon={paidWage.icon}
                  isClickable={true}
                  active={location.pathname === paidWage.route}
                />
              </NavLink>
            )}
            {myWage && (
              <NavLink to={myWage.route}>
                <SidenavListItem
                  color={color}
                  name={myWage.name}
                  icon={myWage.icon}
                  isClickable={true}
                  active={location.pathname === myWage.route}
                />
              </NavLink>
            )}
          </SidenavList>
        )}

        {assignment && (
          <SidenavList title={assignment.name}>
            {assignment_received && (
              <NavLink to={assignment_received.route}>
                <SidenavListItem
                  color={color}
                  name={assignment_received.name}
                  icon={assignment_received.icon}
                  isClickable={true}
                  active={location.pathname === assignment_received.route}
                />
              </NavLink>
            )}

            {assignment_sent && (
              <NavLink to={assignment_sent.route}>
                <SidenavListItem
                  color={color}
                  name={assignment_sent.name}
                  icon={assignment_sent.icon}
                  isClickable={true}
                  active={location.pathname === assignment_sent.route}
                />
              </NavLink>
            )}

            {assignment_received_kenshin && (
              <NavLink to={assignment_received_kenshin.route}>
                <SidenavListItem
                  name={assignment_received_kenshin.name}
                  icon={assignment_received_kenshin.icon}
                  isClickable={true}
                  active={
                    location.pathname === assignment_received_kenshin.route
                  }
                />
              </NavLink>
            )}
          </SidenavList>
        )}

        {contacts && (
          <SidenavList title={contacts.name}>
            {contacts_friends && (
              <NavLink to={contacts_friends.route}>
                <SidenavListItem
                  color={color}
                  name={contacts_friends.name}
                  icon={contacts_friends.icon}
                  isClickable={true}
                  active={location.pathname === contacts_friends.route}
                />
              </NavLink>
            )}
          </SidenavList>
        )}

        <SidenavListCollapse
          title={intl.get('dashboard_sidebar_list_title_others')}
          active={true}
          open={openMenuCollapse}
          handleClick={() => setOpenMenuCollapse(!openMenuCollapse)}>
          <NavLink to={'/personal-settings'}>
            <SidenavListItem
              color={color}
              customMarginX="16px"
              customPaddingLeft="8px"
              name={intl.get('dashboard_text_page_title_personal_settings')}
              icon={<Icon fontSize="medium">settings</Icon>}
              isClickable={true}
              active={location.pathname === '/personal-settings'}
            />
          </NavLink>

          {orgSettings && (
            <NavLink to={orgSettings.route}>
              <SidenavListItem
                color={color}
                customMarginX="16px"
                customPaddingLeft="8px"
                name={orgSettings.name}
                icon={orgSettings.icon}
                isClickable={true}
                active={location.pathname === orgSettings.route}
              />
            </NavLink>
          )}

          {logs && (
            <NavLink to={logs.route}>
              <SidenavListItem
                color={color}
                customMarginX="16px"
                customPaddingLeft="8px"
                name={logs.name}
                icon={logs.icon}
                isClickable={true}
                active={location.pathname === logs.route}
              />
            </NavLink>
          )}

          {manual && (
            <NavLink to={manual.route}>
              <SidenavListItem
                color={color}
                customMarginX="16px"
                customPaddingLeft="8px"
                name={manual.name}
                icon={manual.icon}
                isClickable={true}
                active={location.pathname === manual.route}
              />
            </NavLink>
          )}

          {serviceTerms && (
            <NavLink to={serviceTerms.route}>
              <SidenavListItem
                // badgeContent={2}
                color={color}
                customMarginX="16px"
                customPaddingLeft="8px"
                name={serviceTerms.name}
                icon={serviceTerms.icon}
                isClickable={true}
                active={location.pathname === serviceTerms.route}
              />
            </NavLink>
          )}
        </SidenavListCollapse>
        <Box>
          <br></br>
          <br></br>
          <br></br>
        </Box>
      </SidenavRoot>
    </Box>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
};

Sidenav.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  openDrawer: PropTypes.bool.isRequired,
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  userInfo: PropTypes.object,
  handleClickLogout: PropTypes.func,
};

export default Sidenav;
