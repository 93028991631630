import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

import CustomSearchRender from 'components/CustomSearchRender';
import { getIssuePriority } from 'shared/constants/issues';
import { TABLE_TYPE } from '../../shared/constants';
import RefreshButton from 'components/RefreshButton';
import { useMaterialUIController } from 'context';

// TODO Object to shape
const propTypes = {
  // issuesData: PropTypes.array.isRequired,
  // pricesDict: PropTypes.object.isRequired,
  // dstOrgNamesDict: PropTypes.object.isRequired,
  // setSelectedDstOrgId: PropTypes.func.isRequired,
  // setTableType: PropTypes.func.isRequired,
  billData: PropTypes.array.isRequired,
  billDataKenshin: PropTypes.array.isRequired,
  setSelectedDstOrgId: PropTypes.func.isRequired,
  setSelectedDstOrgName: PropTypes.func.isRequired,
  setTableType: PropTypes.func.isRequired,
  refreshTableData: PropTypes.func.isRequired,
  // srcOrg: PropTypes.object.isRequired,
};

const DashboardBillingForSentTasksSummaryTable = ({
  // issuesData,
  // pricesDict,
  // dstOrgNamesDict,
  // setSelectedDstOrgId,
  // setTableType,
  billData,
  billDataKenshin,
  setSelectedDstOrgId,
  setSelectedDstOrgName,
  setTableType,
  refreshTableData,
  // srcOrg,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const getSummaryTableData = (billData, billDataKenshin) => {
    const map1 = new Map();
    billData.forEach((bill, index) => {
      const srcOrgId = bill.srcOrg.id;
      const dstOrgId = bill.dstOrg.id;
      const totalBill = map1.get(dstOrgId);
      if (!totalBill) {
        const newTotalBill = {
          dstOrgName: bill.dstOrg.name,
          dstOrgId: dstOrgId,
          billAmount: bill.currentFee ? bill.currentFee : bill.originalFee,
        };
        map1.set(dstOrgId, newTotalBill);
      } else {
        const newTotalBill = {
          dstOrgName: totalBill.dstOrgName,
          dstOrgId: dstOrgId,
          billAmount: bill.currentFee
            ? totalBill.billAmount + bill.currentFee
            : totalBill.billAmount + bill.originalFee,
        };
        map1.set(dstOrgId, newTotalBill);
      }
    });

    billDataKenshin.forEach((bill, index) => {
      const srcOrgId = bill.srcOrgId;
      const dstOrgId = bill.dstOrgId;
      const totalBill = map1.get(dstOrgId);
      if (!totalBill) {
        const newTotalBill = {
          dstOrgName: bill.dstOrgName,
          dstOrgId: bill.dstOrgId,
          billAmount: bill.currentFee ? bill.currentFee : bill.originalFee,
        };
        map1.set(dstOrgId, newTotalBill);
      } else {
        const newTotalBill = {
          dstOrgName: totalBill.dstOrgName,
          dstOrgId: dstOrgId,
          billAmount: bill.currentFee
            ? totalBill.billAmount + bill.currentFee
            : totalBill.billAmount + bill.originalFee,
        };
        map1.set(dstOrgId, newTotalBill);
      }
    });

    const data = [];
    map1.forEach((value) => {
      const { dstOrgName, dstOrgId, billAmount, starred } = value;
      data.push([
        dstOrgName,
        billAmount,
        {
          organizationName: dstOrgName,
          organizationId: dstOrgId,
        },
        {
          organizationName: dstOrgName,
          organizationId: dstOrgId,
        },
      ]);
    });

    return data;
  };

  const data = getSummaryTableData(billData, billDataKenshin);

  const exportToCSV = (organizationId, organizationName) => {
    const selectedBillData = billData.filter(
      (bill) => bill.dstOrg.id === organizationId
    );

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      '\uFEFF' +
      'Organization,Issue ID,Emergency,Check Item,Created At,Done At,Original Fee,Current Fee, Remark\n' +
      selectedBillData
        .map((bill) => {
          const organizationName = bill.dstOrg.name;
          const issueTitle = bill.issueTitle;
          // const emergencyText = bill.emergencyText;
          const emergencyText = getIssuePriority(bill.issuePriority);
          const checkItems = bill.issueCheckItems.join(';');
          const createdAt = bill.issueCreatedAt; // TODO: format time
          const doneAt = bill.createdAt;
          const originalFee = bill.originalFee;
          const currentFee =
            bill.currentFee === null ? bill.originalFee : bill.currentFee;
          // generate mark
          let remarks = '';
          const changeHistory = JSON.parse(bill.changeHistory);
          const tags = JSON.parse(bill.tags);
          changeHistory.forEach((history) => {
            const tagId = history.tagId;
            const tag = tags.filter((tag) => tag.id === tagId)[0];
            const taglabelText = tag.labelText;
            const historyType = history.type;
            if (historyType === 'rule') {
              const remark = taglabelText + '-' + history.remark + ';';
              remarks += remark;
            } else if (historyType === 'adjust') {
              const remark = `料金調整 ${history.feeChange};`;
              remarks += remark;
            }
          });

          const checkItems_ = checkItems.replace(/,/g, ' ');
          const remarks_ = remarks.replace(/,/g, ' ');
          return (
            organizationName +
            ',' +
            issueTitle +
            ',' +
            emergencyText +
            ',' +
            checkItems_ +
            ',' +
            createdAt +
            ',' +
            doneAt +
            ',' +
            originalFee +
            ',' +
            currentFee +
            ',' +
            remarks_
          );
        })
        .join('\n');

    const encodeUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodeUri);
    link.setAttribute('download', `normal-bills-from-${organizationName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToCSVKenshin = (organizationId, organizationName) => {
    const selectedBillData = billDataKenshin.filter(
      (bill) => bill.dstOrgId === organizationId
    );

    // eslint-disable-next-line no-useless-concat
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      '\uFEFF' +
      'Organization, Issue ID, Check Item, Created At, Done At, Orginal Fee, Curren Fee, Remark\n' +
      selectedBillData
        .map((bill) => {
          const organizationName = bill.dstOrgName;
          const issueIdentifier =
            bill.issueIdentifier && bill.issueIdentifier.length > 0
              ? bill.issueIdentifier
              : bill.issueTitle;
          const createdAt = bill.issueCreatedAt;
          let checkItems = '';
          const tagCount = JSON.parse(bill.tagCount);
          for (let key in tagCount) {
            const tag = tagCount[key];
            checkItems += `${tag.labelText} * ${tag.count};`;
          }
          const doneAt = bill.createdAt;
          const originalFee = bill.originalFee;
          const currentFee =
            bill.currentFee === null ? bill.originalFee : bill.currentFee;
          // genereate remark
          let remarks = '';
          const changeHistory = JSON.parse(bill.changeHistory);
          changeHistory.forEach((history) => {
            const historyType = history.type;
            if (historyType === 'adjust') {
              const remark = `料金調整 ${history.feeChange};`;
              remarks += remark;
            }
          });

          const checkItems_ = checkItems.replace(/./g, ' ');
          const remarks_ = remarks.replace(/,/g, ' ');
          return (
            organizationName +
            ',' +
            issueIdentifier +
            ', ' +
            checkItems_ +
            ',' +
            createdAt +
            ',' +
            doneAt +
            ',' +
            originalFee +
            ',' +
            currentFee +
            ',' +
            remarks_
          );
        })
        .join('\n');

    const encodeUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodeUri);
    link.setAttribute('download', `kenshin-bills-from-${organizationName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      name: 'to_organization_name',
      label: intl.get('billing_for_sent_cases_label_column_sent_to'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'total_price',
      label: intl.get('billing_for_sent_cases_label_column_total_price'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMega, updateValue) => {
          const totalFee = value;
          return (
            <div>
              <p>￥{totalFee}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'details',
      label: intl.get('billing_for_sent_cases_label_column_details'),
      options: {
        customBodyRender: (value) => {
          const { organizationName, organizationId } = value;
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedDstOrgName(organizationName);
                  setSelectedDstOrgId(organizationId);
                  setTableType(TABLE_TYPE.DETAILED);
                }}>
                {intl.get(
                  'billing_for_sent_cases_button_go_to_details_seimitsu'
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedDstOrgName(organizationName);
                  setSelectedDstOrgId(organizationId);
                  setTableType(TABLE_TYPE.DETAILED_KENSHIN);
                }}>
                {intl.get(
                  'billing_for_sent_cases_button_go_to_details_kenshin'
                )}
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: 'download',
      label: intl.get('billing_for_sent_cases_label_column_download'),
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          const { organizationId, organizationName } = value;
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  exportToCSV(organizationId, organizationName);
                }}>
                {intl.get(
                  'billing_for_sent_cases_button_download_csv_seimitsu'
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  exportToCSVKenshin(organizationId, organizationName);
                }}>
                {intl.get('billing_for_sent_cases_button_download_csv_kenshin')}
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'none',
    filterType: 'textField',
    tableBodyMaxHeight: '950px',
    rowsPerPage: 15,
    rowsPerPageOptions: [10, 15, 25, 100],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={intl.get('billing_for_sent_cases_text_sent_cases')}
        />
      );
    },
    customToolbar: () => {
      const handleRefreshClick = () => {
        refreshTableData();
      };

      return (
        <RefreshButton
          darkMode={darkMode}
          tooltipTitle={intl.get('dashboard_refresh_button_tooltip_title')}
          handleRefreshClick={handleRefreshClick}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      <MUIDataTable
        title={intl.get('billing_for_sent_cases_text_sent_cases')}
        data={data}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};

DashboardBillingForSentTasksSummaryTable.propTypes = propTypes;

export default DashboardBillingForSentTasksSummaryTable;
