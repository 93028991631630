/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// react-router components
import { useHistory, NavLink } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';
import MenuIcon from '@mui/icons-material/Menu';
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';

// self developed api
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';

import intl from 'react-intl-universal';

// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow, navbarIconButton } from './styles';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setDarkMode } from 'context';
import { DOLPHIN_API } from 'shared/constants/apis';
import { LANGUAGE, USER_DATA } from 'shared/constants/users';
import MDTypography from 'components/MDTypography';
import Notifications from 'pages/parts/Notifications';

//! Need to use intl
function DashboardNavbar({ absolute, light, isMini, brand, onOpenDrawer }) {
  // states
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, darkMode } = controller;
  const [openNotificationMenu, setOpenNotificationMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsPreferences, setNotificationsPreferences] = useState({});
  const [numNewNotifications, setNumNewNotifications] = useState(0);
  const [refresh, setRefresh] = useState(0);

  const history = useHistory();

  // increment value of `refresh` per specified time period (default is 60 seconds)
  const PERIOD_MS = process.env.REACT_APP_NOTIFICATION_FETCH_PERIOD_MS || 60000;
  useEffect(() => {
    const interval = setInterval(() => {
      if (process.env.REACT_APP_AUTH_SERVER === 'aws-cognito') {
        setRefresh((refresh) => refresh + 1);
      }
    }, PERIOD_MS);
    return () => clearInterval(interval); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const apiVariables = {
      params: {
        userId: localData.get(USER_DATA.USER_ID),
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      },
    };

    api.get(DOLPHIN_API.NOTIFICATIONS, apiVariables).then(async (data) => {
      const notifications = data.notifications;
      setNotifications(notifications);
      setNumNewNotifications(
        notifications.filter((notification) => notification.clicked === false)
          .length
      );
    });
  }, [refresh]);

  useEffect(() => {
    const fetchPreferences = async () => {
      const response = await api.get(DOLPHIN_API.NOTIFICATION_PREFERENCE_ALL, {
        params: {
          userId: localData.get(USER_DATA.USER_ID),
          organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        },
      });
      setNotificationsPreferences(response.preferences);
    };
    fetchPreferences();
  }, []);

  const handleOpenNotificationMenu = (event) =>
    setOpenNotificationMenu(event.currentTarget);
  const handleCloseNotificationMenu = () => setOpenNotificationMenu(false);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // // TODO:dacao not working; need to come back;
  // const textColor = (darkMode, clicked) => {
  //   let colorValue;
  //   if (darkMode) {
  //     colorValue = `${clicked ? 'text' : 'light'}`;
  //   } else {
  //     colorValue = clicked ? 'text' : 'info';
  //   }
  //   return colorValue;
  // };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      data-testid="notification-menu"
      anchorEl={openNotificationMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openNotificationMenu)}
      onClose={handleCloseNotificationMenu}
      sx={{ mt: 1 }}
      PaperProps={{
        style: {
          width: 500,
          maxHeight: 600,
          padding: 2,
        },
      }}>
      <Notifications
        notifications={notifications}
        setNotifications={setNotifications}
        numNewNotifications={numNewNotifications}
        setNumNewNotifications={setNumNewNotifications}
        notificationsPreferences={notificationsPreferences}
        onMarkAllAsRead={() => {
          console.log('mark all as read');
        }}
        onLoadMore={() => {
          console.log('load more');
        }}
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: 'white !important',
  });

  return (
    <AppBar
      data-testid="app-bar"
      position="fixed"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      } // defines the color/style of the navbar
    >
      <Toolbar sx={(theme) => navbarContainer(theme)} data-testid="mui-Toolbar">
        <MDBox display="flex" alignItems="center">
          <IconButton size="large" color="inherit" onClick={onOpenDrawer}>
            <MenuIcon sx={iconsStyle} />
          </IconButton>
          <MDBox
            component={NavLink}
            to="/home"
            display="flex"
            alignItems="center"
            sx={{ marginLeft: '20px' }}>
            <MDBox component="img" src={brand} alt="Brand" width="3rem" />
            <MDTypography
              component="h1"
              color="white"
              variant="button"
              fontWeight="bold">
              {'I.W.G Docloud'}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
          <MDBox color={light ? 'white' : 'inherit'}>
            {/* <IconButton
              size="medium"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              onClick={handleDarkMode}>
              <DarkModeIcon sx={iconsStyle} />
            </IconButton> */}

            <IconButton
              data-testid="navbar-notification-button"
              size="medium"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              variant="contained"
              onClick={handleOpenNotificationMenu}>
              <MDBadge
                badgeContent={numNewNotifications}
                color="error"
                size="xs"
                circular>
                <Icon sx={iconsStyle}>notifications</Icon>
              </MDBadge>
            </IconButton>

            {renderMenu()}
          </MDBox>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  brand: PropTypes.string,
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  onOpenDrawer: PropTypes.func,
};

export default DashboardNavbar;
