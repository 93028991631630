import { makeStyles } from '@material-ui/core/styles';

// Define your custom styles
export const useStyles = makeStyles((theme) => ({
  modalContent: {
    width: '650px', // Set your desired fixed width
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
