import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import FileViewer from 'react-file-viewer';
import { useStyles } from './styles';
import api from 'shared/utils/api';
import { getFileExtension } from 'shared/utils/fileType';
import localData from 'shared/utils/localData';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { AttachmentProptypes } from 'shared/propTypes/attachmentType';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  attachment: PropTypes.shape(AttachmentProptypes).isRequired,
  modalClose: PropTypes.func,
};

const DashboardKanbanIssueDetailsPreview = ({
  issue,
  attachment,
  modalClose,
}) => {
  const classes = useStyles();

  const [objectPresignedURL, setGetObjectPresignedURL] = useState('');

  useEffect(() => {
    const config = {
      params: {
        organizationId: localData.get('currentOrganizationId'),
        attachmentId: attachment.id,
      },
    };

    api
      .get(`/api/download/${issue.id}`, config)
      .then(async (data) => {
        setGetObjectPresignedURL(data.GetObjectPresignedURL);
      })
      .catch((error) => {
        alert(error);
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      <Grid
        container
        className={classes.actionGrid}
        columns={{ xs: 12 }}
        justifyContent="flex-end">
        <div>
          <IconButton onClick={modalClose}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </div>
      </Grid>
      {objectPresignedURL && (
        <Grid className={classes.fileViewerGrid} columns={{ xs: 12 }}>
          <FileViewer
            fileType={getFileExtension(attachment.title)}
            filePath={objectPresignedURL}
          />
        </Grid>
      )}
    </Grid>
  );
};

DashboardKanbanIssueDetailsPreview.propTypes = propTypes;

export default withRouter(DashboardKanbanIssueDetailsPreview);
