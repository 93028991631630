import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { IssueStatus } from 'shared/constants/issues';
import { sortByNewest } from 'shared/utils/javascript';

import Create from './Create';
import Comment from './Comment';
import { Comments, Title } from './Styles';

const propTypes = {
  issue: PropTypes.object.isRequired,
  fetchIssue: PropTypes.func.isRequired,
  isClient: PropTypes.bool.isRequired,
  updateLocalProjectIssues: PropTypes.func,
  handleDownloadButtonClick: PropTypes.func,
  handlePreviewButtonClick: PropTypes.func,
  quoteAttachmentName: PropTypes.string,
  refreshQuote: PropTypes.number,
  commentEditorRef: PropTypes.shape({
    // Quill object
    current: PropTypes.object,
  }),
  isCommentFormOpen: PropTypes.bool,
  setIsCommentFormOpen: PropTypes.func,
  handleBlockQuoteTextClick: PropTypes.func,
};

const ProjectBoardIssueDetailsComments = ({
  issue,
  fetchIssue,
  isClient,
  updateLocalProjectIssues,
  handleDownloadButtonClick,
  handlePreviewButtonClick,
  quoteAttachmentName,
  refreshQuote,
  commentEditorRef,
  isCommentFormOpen,
  setIsCommentFormOpen,
  handleBlockQuoteTextClick,
}) => (
  <Comments>
    <Title>{intl.get('issue_details_text_comments')}</Title>
    <Create
      issueId={issue.id}
      fetchIssue={fetchIssue}
      isClient={isClient}
      // allowReport={[
      //   IssueStatus.DOCTORASSIGNED,
      //   IssueStatus.REPORTREADY,
      // ].includes(issue.status)}
      updateLocalProjectIssues={updateLocalProjectIssues}
      quoteAttachmentName={quoteAttachmentName}
      refreshQuote={refreshQuote}
      commentEditorRef={commentEditorRef}
      isCommentFormOpen={isCommentFormOpen}
      setIsCommentFormOpen={setIsCommentFormOpen}
    />

    {sortByNewest(issue.comments, 'createdAt').map((comment) => (
      <Comment
        key={comment.id}
        comment={comment}
        issue={issue}
        fetchIssue={fetchIssue}
        handleDownloadButtonClick={handleDownloadButtonClick}
        handlePreviewButtonClick={handlePreviewButtonClick}
        handleBlockQuoteTextClick={handleBlockQuoteTextClick}
      />
    ))}
  </Comments>
);

ProjectBoardIssueDetailsComments.propTypes = propTypes;

export default ProjectBoardIssueDetailsComments;
