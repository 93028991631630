import intl from 'react-intl-universal';
import { Button } from '@material-ui/core';

import State from 'components/DataTable/State';

import { formatTime } from 'shared/utils/formatTime';
import {
  // IssueStatusForClient,
  IssueStatusForClientID,
  getIssueStatusForClient,
  IssueStatusForClientToOrder,
  getIssueState,
} from 'shared/constants/issues';

import { Status } from './styles';
import Deadline from 'components/DataTable/Deadline';

export const getSentTableColumns = (handleOpenIssueDetails) => {
  const tableColumns = [
    {
      name: 'case_title',
      label: intl.get('case_list_label_column_case_title'),
      options: {
        filter: true,
        filterType: 'textField',
        filterOptions: { fullWidth: true },
        sort: false,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const words1 = obj1.data.split('-');
            const words2 = obj2.data.split('-');
            if (words1[0] > words2[0]) {
              return order === 'desc' ? -1 : 1;
            } else if (words1[0] < words2[0]) {
              return order === 'desc' ? 1 : -1;
            } else {
              if (words1[1] > words2[1]) {
                return order === 'desc' ? -1 : 1;
              } else if (words1[1] < words2[1]) {
                return order === 'desc' ? 1 : -1;
              } else {
                return (
                  (parseInt(words1[2], 10) - parseInt(words2[2], 10)) *
                  (order === 'desc' ? -1 : 1)
                );
              }
            }
          };
        },
      },
    },
    {
      name: 'patientName',
      label: intl.get('case_list_label_column_patient_name'),
      options: {
        filter: true,
        filterType: 'textField',
        filterOptions: { fullWidth: true },
      },
    },
    {
      name: 'status',
      label: intl.get('case_list_label_column_status'),
      options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {
          names: [
            IssueStatusForClientID.NEWTASK,
            IssueStatusForClientID.INPROGRESS,
            IssueStatusForClientID.SENDBACK,
            IssueStatusForClientID.DONE,
          ],
          logic(value, filters) {
            if (filters.length) {
              let real_filters = [];
              for (let i = 0; i < filters.length; i++) {
                real_filters.push(filters[i].split('_')[1]);
              }
              return !real_filters.includes(value);
            }
            return false;
          },
          renderValue: (value) => {
            const status = value.split('_')[1];
            return getIssueStatusForClient(status);
            // return (
            //   <Status color={status}>{getIssueStatusForClient(value)}</Status>
            // );
          },
        },
        customFilterListOptions: {
          render: (value) => {
            const status = value.split('_')[1];
            return (
              intl.get('case_list_label_column_status') +
              ': ' +
              getIssueStatusForClient(status)
            );
          },
        },
        sort: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const order1 = IssueStatusForClientToOrder[obj1.data];
            const order2 = IssueStatusForClientToOrder[obj2.data];
            if (order1 > order2) {
              return order === 'desc' ? -1 : 1;
            } else if (order1 < order2) {
              return order === 'desc' ? 1 : -1;
            } else {
              return 1;
            }
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const status = value;
          return (
            <Status color={status}>{getIssueStatusForClient(value)}</Status>
          );
        },
      },
    },
    {
      name: 'state',
      label: intl.get('case_list_label_column_state'),
      options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {
          renderValue: (value) => {
            const state = value;
            return getIssueState(state);
            // return (
            //   <div>
            //     <State state={state} />
            //   </div>
            // );
          },
        },
        customFilterListOptions: {
          render: (value) => {
            const state = value;
            return (
              intl.get('case_list_label_column_state') +
              ': ' +
              getIssueState(state)
            );
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const state = value;
          return (
            <div>
              <State state={state} />
            </div>
          );
        },
      },
    },
    {
      name: 'order_time',
      label: intl.get('case_list_label_column_order_time'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatTime(value);
        },
      },
    },
    // TODO change here
    {
      name: 'deadline',
      label: intl.get('case_list_label_column_deadline'),
      options: {
        filter: false,
        sort: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const deadline1 = new Date(obj1.data);
            const deadline2 = new Date(obj2.data);
            if (deadline1 > deadline2) {
              return order === 'desc' ? -1 : 1;
            } else if (deadline1 < deadline2) {
              return order === 'desc' ? 1 : -1;
            } else {
              return 1;
            }
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const deadline = value;
          // Current status is at index 2 for the sent table
          const currentStatus = tableMeta.rowData[2];
          return (
            <div>
              <Deadline deadline={deadline} currentStatus={currentStatus} />
            </div>
          );
        },
      },
    },
    {
      name: 'to_hospital',
      label: intl.get('case_list_label_column_to'),
      options: {
        filter: true,
        filterType: 'multiselect',
        customFilterListOptions: {
          render: (value) => {
            const hospital = value;
            return intl.get('case_list_label_column_to') + ': ' + hospital;
          },
        },
        sort: true,
      },
    },
    {
      name: 'view',
      label: intl.get('case_list_label_column_details'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const issueId = value;
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenIssueDetails(issueId);
                }}>
                {intl.get('case_list_button_view')}
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return tableColumns;
};
