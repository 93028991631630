import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Grid, Modal, TextField } from '@material-ui/core';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { TextEditedContent } from 'components';
import api from 'shared/utils/api';
import pxToRem from 'assets/theme/functions/pxToRem';
import useStyles from './styles';
import { DOLPHIN_API } from 'shared/constants/apis';

const propTypes = {
  close: PropTypes.func.isRequired,
  fetchEmployments: PropTypes.func.isRequired,
};

const IWGDashboardEmploymentsNew = ({ close, fetchEmployments }) => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    organizationCode: '',
    organizationId: -1,
    username: '',
    userId: -1,
    quota: -1,
    used: 0,
    isOwner: false,
  });
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [incompleteWarningModalOpen, setIncompleteWarningModalOpen] =
    useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [options, setOptions] = useState({
    organizationOptions: [],
    userOptions: [],
  });

  useEffect(() => {
    const getOptions = async () => {
      const organizations = await api.get(DOLPHIN_API.ADMIN_ORGS, {});
      const organizationOptions = organizations.map((org) => {
        return {
          label: org.organizationCode + ' (' + org.name + ')',
          value: {
            organizationId: org.id,
            organizationCode: org.organizationCode,
          },
        };
      });
      const users = await api.get(DOLPHIN_API.ADMIN_USERS, {});
      const userOptions = users.map((user) => {
        return {
          label: user.username + ' (' + user.userType + ')',
          value: {
            userId: user.id,
            username: user.username,
          },
        };
      });
      setOptions({
        organizationOptions: organizationOptions,
        userOptions: userOptions,
      });
    };
    getOptions();
  }, []);

  const keyToCheck = ['organizationCode', 'username'];

  const keyToLabel = {
    organizationCode: 'Organization Code',
    username: 'Username',
  };

  const onValueChange = (key, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const sendToServer = async () => {
    const data = { ...formState };
    await api.post(DOLPHIN_API.ADMIN_EMPLOYMENTS, {
      data: {
        createdData: [data],
      },
    });
    await fetchEmployments();
    close();
  };

  const checkIfFormCompleted = () => {
    const _incompleteFields = [];

    keyToCheck.forEach((key) => {
      const value = formState[key];
      if (value.length === 0) {
        _incompleteFields.push(keyToLabel[key]);
      }
    });

    if (_incompleteFields.length > 0) {
      setIncompleteFields(_incompleteFields);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();
    if (!checkIfFormCompleted()) {
      setIncompleteWarningModalOpen(true);
    } else {
      setConfirmModalOpen(true);
    }
  };

  return (
    <Fragment>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <Grid
          spacing={3}
          container
          alignItems="flex-start"
          justifyContent="center">
          <Grid xs={12} item>
            <Autocomplete
              disablePortal
              id="organizationCode"
              onChange={(event, newValue) => {
                if (newValue && newValue.value) {
                  onValueChange(
                    'organizationCode',
                    newValue.value.organizationCode
                  );
                  onValueChange(
                    'organizationId',
                    newValue.value.organizationId
                  );
                }
              }}
              options={options.organizationOptions}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    name="organizationCode"
                    fullWidth
                    variant="outlined"
                    label="Organization Code"
                    color="secondary"
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <Autocomplete
              disablePortal
              id="username"
              onChange={(event, newValue) => {
                if (newValue && newValue.value) {
                  onValueChange('username', newValue.value.username);
                  onValueChange('userId', newValue.value.userId);
                }
              }}
              options={options.userOptions}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    name="username"
                    fullWidth
                    variant="outlined"
                    label="Username"
                    color="secondary"
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <Autocomplete
              disablePortal
              id="owner"
              onChange={(event, newValue) => {
                if (newValue && newValue.value) {
                  onValueChange('isOwner', newValue.value);
                }
              }}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    name="owner"
                    fullWidth
                    variant="outlined"
                    label="Is Owner?"
                    color="secondary"
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              id="quota"
              name="quota"
              fullWidth
              variant="outlined"
              label="Quota"
              onChange={(event) => onValueChange('quota', event.target.value)}
              value={formState.quota}
              color="secondary"
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              id="used"
              name="used"
              fullWidth
              variant="outlined"
              label="Used"
              onChange={(event) => onValueChange('used', event.target.value)}
              value={formState.used}
              color="secondary"
            />
          </Grid>
        </Grid>
        <Grid
          spacing={3}
          container
          alignItems="flex-start"
          justifyContent="center">
          <Grid xs={6} item>
            <MDButton
              // variant="gradient"
              color="info"
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '5px',
                marginBottom: '45px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={handleSubmitClick}>
              Submit
            </MDButton>
          </Grid>
          <Grid xs={6} item>
            <MDButton
              // variant="gradient"
              color="info"
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '5px',
                marginBottom: '45px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={close}>
              Cancel
            </MDButton>
          </Grid>
        </Grid>

        {/* Modal for warning */}
        <Modal
          open={incompleteWarningModalOpen}
          onClose={() => setIncompleteWarningModalOpen(false)}
          className={classes.modal}>
          <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <MDTypography mb={1}>Warning</MDTypography>
              <MDTypography>
                The following information is incomplete!
              </MDTypography>
              <br />
              {incompleteFields &&
                incompleteFields.map((field, index) => (
                  <p style={{ color: 'red' }} key={index}>
                    {field}
                  </p>
                ))}
              <br />
            </MDBox>
          </Card>
        </Modal>
        {/* Modal for confirm */}
        <Modal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          className={classes.modal}>
          <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
            <MDBox py={3} px={3} mt={3} mx={3}>
              <MDTypography mb={1}>Confirm</MDTypography>
              <MDTypography>
                You are going to create a new employment as below:
              </MDTypography>
              <TextEditedContent
                content={JSON.stringify(formState, undefined, 4)}
              />
              <Grid
                spacing={3}
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{ marginTope: '20px' }}>
                <Grid xs={6} item>
                  <MDButton
                    // variant="gradient"
                    color="info"
                    style={{
                      color: 'white',
                      fontSize: pxToRem(18),
                      marginTop: '5px',
                      marginBottom: '45px',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    onClick={sendToServer}>
                    Confirm
                  </MDButton>
                </Grid>
                <Grid xs={6} item>
                  <MDButton
                    // variant="gradient"
                    color="info"
                    style={{
                      color: 'white',
                      fontSize: pxToRem(18),
                      marginTop: '5px',
                      marginBottom: '45px',
                      width: '50%',
                      marginLeft: '25%',
                    }}
                    onClick={() => {
                      setIncompleteWarningModalOpen(false);
                      setConfirmModalOpen(false);
                    }}>
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Modal>
      </MDBox>
    </Fragment>
  );
};

IWGDashboardEmploymentsNew.propTypes = propTypes;

export default withRouter(IWGDashboardEmploymentsNew);
