import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import { TaskTimer } from 'tasktimer';
import {
  Grid,
  Modal,
  Checkbox,
  FormControlLabel,
  Box,
} from '@material-ui/core';
import Chip from './Chip';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import FormField from 'pages/parts/FormField';
import FormFieldHelperText from 'pages/parts/FormFieldHelperText';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import DashboardNewDropZone from 'pages/parts/DropZone';
import TagCheckboxForm from './TagCheckboxForm';
import { CustomCheckboxForm } from './customCheckboxForm';
import theme from 'assets/theme';
import themeDark from 'assets/theme-dark';
import { PageLoader, PageError, TextEditor } from 'components';
import useStyles from './styles';
import { useMaterialUIController } from 'context';
import {
  useUploadMissionsContextController,
  addNewMission,
  setMissionStatusContext,
  contextUpdateUploadingSpeed,
} from 'context/NewIssueContext';
import {
  initNewIssue,
  startIssuePeriodicStatusCheck,
  uploadBatchAttachmentsToS3,
  checkForAttachmentsCompletionOnNewIssue,
  getDstOrgOptions,
  getGenderOptions,
  getGenderValue,
  getEmergencyOptions,
  generateConfirmWindowContents,
  getDstOrgObjectById,
  generateFileList,
  generateDirectoryList,
  generateFileListForAttachmentsFromParent,
  generateDirectoryListForAttachmentsFromParent,
  getCVICSuperUserSourceOrgs,
  getCVICSuperUserDestinationOrgs,
} from './utils';

import {
  fileTypeIsValid,
  getStructuredFiles,
  calculateFolderSize,
  calcBatchTotalSize,
  findDuplicateAttachments,
} from 'shared/utils/fileHandling';

import { removeLeadingSlash } from 'shared/utils/misc';
import pxToRem from 'assets/theme/functions/pxToRem';
import { tagIntlValues, tagRelationships } from 'shared/constants/tagsValues';
import { formConstants } from './constants';
import { ConfirmModal } from 'shared/modals/confirmModal';
import { getUserFullName } from 'shared/utils/name';
import { DOLPHIN_API } from 'shared/constants/apis';
import { isDeepEqual } from 'shared/utils/equality';

import BootstrapFormField from 'components/Form/BootstrapFormField';
import AutoCompleteFormField from 'components/Form/AutocompleteFormField';

import { MISSION_CATEGORY } from 'context/constants';

const AutocompleteGender = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 0.88em;
  }
`;

const propTypes = {
  parentIssue: PropTypes.object,
  close: PropTypes.func,
};

const DashboardNew = ({ parentIssue, close }) => {
  const tagIntlValues = {
    CT1: intl.get('case_tag_value_ct_1'),
    CT2: intl.get('case_tag_value_ct_2'),
    CT3: intl.get('case_tag_value_ct_3'),
    CT4: intl.get('case_tag_value_ct_4'),
    CT5: intl.get('case_tag_value_ct_5'),
    CT6: intl.get('case_tag_value_ct_6'),
    CT7: intl.get('case_tag_value_ct_7'),
    CT8: intl.get('case_tag_value_ct_8'),
    CT9: intl.get('case_tag_value_ct_9'),
    CT10: intl.get('case_tag_value_ct_10'),
    MRI1: intl.get('case_tag_value_mri_1'),
    MRI2: intl.get('case_tag_value_mri_2'),
    MRI3: intl.get('case_tag_value_mri_3'),
    MRI4: intl.get('case_tag_value_mri_4'),
    MRI5: intl.get('case_tag_value_mri_5'),
    MRI6: intl.get('case_tag_value_mri_6'),
    MRI7: intl.get('case_tag_value_mri_7'),
    MRI8: intl.get('case_tag_value_mri_8'),
    MRI9: intl.get('case_tag_value_mri_9'),
    MRI10: intl.get('case_tag_value_mri_10'),
    MRI11: intl.get('case_tag_value_mri_11'),
    MRI12: intl.get('case_tag_value_mri_12'),
    MRI13: intl.get('case_tag_value_mri_13'),
    MRI14: intl.get('case_tag_value_mri_14'),
    PhyExam1: intl.get('case_tag_value_phy_exam_1'),
    PhyExam2: intl.get('case_tag_value_phy_exam_2'),
    PhyExam3: intl.get('case_tag_value_phy_exam_3'),
    PhyExam4: intl.get('case_tag_value_phy_exam_4'),
    MRICor: intl.get('case_tag_value_mri_cor'),
  };

  // For new child Issue
  const initPatientName =
    parentIssue === undefined ? '' : parentIssue.patientName;
  const initPatientGender =
    parentIssue === undefined ? '' : parentIssue.patientGender;
  const initDescription =
    parentIssue === undefined ? '' : parentIssue.description;
  const initDepartment =
    parentIssue === undefined ? '' : parentIssue.department;
  const initAttachmentsFromParent =
    parentIssue === undefined
      ? []
      : [
          ...parentIssue.attachmentsOwnedBySrcOrg,
          ...parentIssue.attachmentsOwnedByDstOrg,
        ];
  const initParentIssueId = parentIssue === undefined ? -1 : parentIssue.id;

  const [controllerUploadMissions, dispatchUploadMissions] =
    useUploadMissionsContextController();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const classes = useStyles();
  const currentUsername = localData.get('username');
  const currentOrganization = localData.get('currentOrganization');
  const currentOrganizationId = localData.get('currentOrganizationId');

  // states specifically for resetting some Autocomplete inputs;
  const [toggleResetEmergency, setToggleResetEmergency] = useState(false);
  const [toggleResetDstOrgName, setToggleResetDstOrgName] = useState(false);
  const [toggleResetDescription, setToggleResetDescription] = useState(false);

  // states related to pre-sending;
  const [exceedUploadMissionsLimit, setExceedUploadMissionsLimit] =
    useState(false);
  const [warnFormIncomplete, setWarnFormIncomplete] = useState(false);
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [showWarningBeforeSend, setShowWarningBeforeSend] = useState(false); // show modal window to confirm sending new Issue;
  const [sendContentsConfirmed, setSendContentsConfirmed] = useState(false); // whether the confirmation checkbox is clicked in above modal window;
  const [newIssueConfirmButtonIsLoading, setNewIssueConfirmButtonIsLoading] =
    useState(false); // whether the 'confirm' button in above modal window is clicked or not;
  const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
  const [fileToDelete, setFileToDelete] = useState({});
  // const [genderValue, setGenderValue] = useState('xxx');
  const [genderValue, setGenderValue] = useState(
    getGenderValue(initPatientGender)
  );
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [showWarnInvalidFileTypes, setShowWarnInvalidFileTypes] =
    useState(false);
  const [openCheckboxForm, setOpenCheckboxForm] = useState(false);

  // const [checkboxForm, setCheckboxForm] = useState(InitialTagForm);
  const [checkboxForm, setCheckboxForm] = useState([]);

  const [formjson, setFormjson] = useState({});
  const [selectedjson, setSelectedjson] = useState([]);

  // added by qiao for new tag system
  const [tagListJson, setTagListJson] = useState({});
  const [selectedTagJson, setSelectedTagJson] = useState([]);

  const [formState, setFormState] = useState({
    // patientName: '',
    patientName: initPatientName,
    // patientGender: '',
    patientGender: initPatientGender,
    srcOrgName: currentOrganization,
    srcOrgId: currentOrganizationId,
    dstOrgName: '',
    dstOrgId: '',
    emergency: '',
    // department: '',
    department: initDepartment,
    doctorInCharge: '',
    // description: '',
    description: initDescription,
    file: '',
    dataSet: '',
    doctorsInCharge: [],
    doctorIdsFromSrcOrg: [],
    parentIssueId: initParentIssueId,
  });

  const [structuredAttachments, setStructuredAttachments] = useState([]);
  const [newStructuredAttachments, setNewStructuredAttachments] = useState([]);
  const [duplicatedAttachments, setDuplicatedAttachments] = useState([]);
  // states related to procress of sending new issue;

  const [confirmNotCheck, setconfirmNotCheck] = useState(false);
  const [sendNewIssueFailed, setSendNewIssueFailed] = useState(false);
  const [doctorsInCharge, setDoctorsInCharge] = useState([]);
  const [doctorsInChargeTextInputValue, setDoctorsInChargeTextInputValue] =
    useState('');
  const [doctorsInChargeInputFocused, setDoctorsInChargeInputFocused] =
    useState(false);

  // For child issue
  const [attachmentsFromParent, setAttachmentsFromParent] = useState(
    initAttachmentsFromParent
  );
  const [attachmentsFromParentToDelete, setAttachmentsFromParentToDelete] =
    useState({});
  const [
    confirmDeleteAttachmentFromParent,
    setConfirmDeleteAttachmentFromParent,
  ] = useState(false);

  useEffect(() => {
    const id = controllerUploadMissions.newIssueIdentifierId;
    if (id in controllerUploadMissions.missions) {
      const { status, missionCategory } = controllerUploadMissions.missions[id];
      if (missionCategory === MISSION_CATEGORY.SEIMITSU && status === 'INIT') {
        resetForm();
        const missionId = controllerUploadMissions.newIssueIdentifierId;
        const mission = controllerUploadMissions.missions[missionId];
        sendNewIssueContinued(mission);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerUploadMissions.newIssueIdentifierId]);

  const [{ data, error }] = useApi.get(DOLPHIN_API.ORGANIZATION_NEW, {
    params: { organizationId: currentOrganizationId },
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { dstOrgs, CvicSuperUserOrgs, doctorsFromSrcOrg } = data;
  const doctorOptions = doctorsFromSrcOrg.map((doctor) => ({
    label: getUserFullName(doctor.firstName, doctor.lastName),
    id: doctor.id,
  }));

  dstOrgs.sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });
  const resetForm = () => {
    setFormState({
      patientName: '',
      patientGender: '',
      srcOrgName: currentOrganization,
      srcOrgId: currentOrganizationId,
      dstOrgName: '',
      dstOrgId: '',
      emergency: '',
      department: '',
      doctorInCharge: '',
      studyID: '',
      bodyPart: '',
      modality: '',
      description: '',
      file: '',
      dataSet: '',
      doctorsInCharge: [],
      doctorIdsFromSrcOrg: [],
      parentIssueId: -1,
    });
    setDoctorsInCharge([]);

    setGenderValue('');
    setToggleResetEmergency(!toggleResetEmergency);
    setToggleResetDstOrgName(!toggleResetDstOrgName);
    setToggleResetDescription(!toggleResetDescription);
    // setCheckboxForm(InitialTagForm);
    setCheckboxForm([]);

    setStructuredAttachments([]);

    // for child issue
    setAttachmentsFromParent([]);

    if (close !== null && close !== undefined) {
      close();
    }
  };

  const handleSrcDstForCVICSuperUser = (key, org) => {
    const newFormState = { ...formState, [key]: org.id };
    const orgName = org.label;

    if (key === 'srcOrgId') {
      newFormState.srcOrgName = orgName;
      newFormState.dstOrgId = '';
      newFormState.dstOrgName = '';
    } else if (key === 'dstOrgId') {
      newFormState.dstOrgName = orgName;
    }
    setFormState({ ...newFormState });
  };

  // Handle changes from destOrgId
  const handleChangeOnForm = (key, value) => {
    const newFormState = {
      ...formState,
      [key]: value && value.value ? value.value : '',
    };
    // special case
    if (value && value.value) {
      if (key === formConstants.DEST_ORG_ID) {
        const dstOrg = getDstOrgObjectById(value.value, dstOrgs);
        newFormState.dstOrgName = dstOrg.name.toString();
      }
    }
    setFormState({ ...newFormState });
  };

  const handleChange = (key) => async (event) => {
    const newFormState = { ...formState, [key]: event.target.value };
    setFormState({ ...newFormState });
  };

  const handleChangeDoctors = (newDoctors) => {
    const doctorIds = newDoctors
      .filter((doctor) => doctor.id !== -1)
      .map((doctor) => doctor.id);
    const doctorNames = newDoctors.map((doctor) => doctor.label);
    setFormState((prevFormState) => ({
      ...prevFormState,
      doctorsInCharge: doctorNames,
      doctorIdsFromSrcOrg: doctorIds,
    }));
  };

  const handleDescriptionChange = (htmlValue) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      description: htmlValue,
    }));
  };

  //! This will handle the delete for the chips
  // Might want this for later
  // const handleDeleteTag = (valueToDelete) => () => {
  //  const newFormValues = {
  //   ...checkboxForm
  //  };
  //  newFormValues[valueToDelete] = false;
  //  setCheckboxForm(newFormValues);
  // }

  // // Create the tags
  // const createTags = (tagNameList) => {
  //   const tagList = [];
  //   tagNameList.forEach(function (item, index) {
  //     tagList.push(
  //       <Chip
  //         key={index}
  //         label={`${item}`}
  //         labelcolor={darkMode ? 'white' : 'black'}
  //         variant="outlined"
  //         style={{ marginRight: '4px' }}
  //       />
  //     );
  //   });
  //   return tagList;
  // };

  // const showSelectedTags = () => {
  //   const tagList = [];
  //   selectedjson.forEach(function (item, index) {
  //     tagList.push(
  //       <Chip
  //         key={index}
  //         label={tagObj2Str(item)}
  //         labelcolor={darkMode ? 'white' : 'black'}
  //         variant="outlined"
  //         style={{ marginRight: '4px' }}
  //       />
  //     );
  //   });
  //   return tagList;
  // };

  const showSelectedTagsNew = () => {
    const tagList = [];
    checkboxForm.forEach((checked, index) => {
      if (checked) {
        tagList.push(
          <Chip
            data-testid="new-issue-tags-selected-chip"
            key={index}
            label={tagListJson.tags[index].labelText}
            labelcolor={darkMode ? 'white' : 'black'}
            variant="outlined"
            style={{ marginRight: '4px' }}
          />
        );
      }
    });
    return tagList;
  };

  const tagObj2Str = (item) => {
    let rslt = '';
    for (let i = 0; i < item.length; i++) {
      if (i < 1) {
        rslt += item[i].label;
      } else {
        rslt += '-' + item[i].label;
      }
      if (item[i].value) {
        rslt += ':' + item[i].value;
      }
    }

    return rslt;
  };

  // Show the tags correctly
  //! This will only work with the current situation
  const showSelectedModalItems = (type) => {
    const selectedItems = {};
    // Need to join specific tags
    for (const [key, value] of Object.entries(checkboxForm)) {
      // Trim the numbers from the key and lowercase them
      if (value && key.replace(/[0-9]/g, '') === type) {
        if (key in tagRelationships) {
          if (tagRelationships[key] === 'MRIGroup2') {
            selectedItems[tagRelationships[key]] = tagIntlValues['MRICor'];
          }
          selectedItems[tagRelationships[key]] = selectedItems[
            tagRelationships[key]
          ]
            ? selectedItems[tagRelationships[key]] + ' - ' + tagIntlValues[key]
            : tagIntlValues[key];
        } else {
          selectedItems[key] = tagIntlValues[key];
        }
      }
    }
    return Object.values(selectedItems);
  };

  // Shows the phyExam tags
  const showSelectedPhyExamModalItems = () => {
    const selectedItems = [];
    // Need to join specific tags
    for (const [key, value] of Object.entries(checkboxForm)) {
      // Trim the numbers from the key and lowercase them
      if (value && key.replace(/[0-9]/g, '') === 'PhyExam') {
        selectedItems.push(
          <Chip
            key={key}
            label={tagIntlValues[key]}
            labelcolor={darkMode ? 'white' : 'black'}
            variant="outlined"
            style={{ marginRight: '4px' }}
          />
        );
      }
    }
    return selectedItems;
  };

  // DO NOT DELETE!
  // const handleDicomZipFileInfoChange = (targetIndex, key) => (async (event) => {
  //   const newItems = attachments.map( (item, index) => {
  //     if (index == targetIndex) {
  //       return { ...item, [key]: event.target.value}
  //     }
  //     return item;
  //   });
  //   setAttachments(newItems);
  // });

  const handleFileDelete = (item) => async (event) => {
    setFileToDelete(item);
    setConfirmDeleteFile(true);
  };

  const handleAttachmentFromParentDelete = (item) => async (event) => {
    setAttachmentsFromParentToDelete(item);
    setConfirmDeleteAttachmentFromParent(true);
  };

  const handleDuplicateAttachments = async (action) => {
    switch (action) {
      case 'REPLACE': {
        const tmp = structuredAttachments.filter(
          (item) => !duplicatedAttachments.some((x) => item.name === x.name)
        );
        setStructuredAttachments([...tmp, ...newStructuredAttachments]);
        break;
      }
      case 'IGNORE': {
        const nonDups = newStructuredAttachments.filter(
          (item) => !duplicatedAttachments.some((x) => item.name === x.name)
        );
        setStructuredAttachments([...structuredAttachments, ...nonDups]);
        break;
      }
      case 'CANCEL':
        break;
      default:
        break;
    }
    setNewStructuredAttachments([]);
    setDuplicatedAttachments([]);
  };

  const handleFiles = async (files) => {
    const validAttachments = [];
    const invalidFiles = [];

    // filter out files with invalid formats;
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      if (!fileTypeIsValid(file)) {
        await invalidFiles.push(file);
      } else {
        const item = await handleSingleFile(file);
        await validAttachments.push(item);
      }
    }
    if (invalidFiles.length > 0) {
      setInvalidFiles([...invalidFiles]);
      setShowWarnInvalidFileTypes(true);
    }

    const newItems = getStructuredFiles(validAttachments);
    newItems.forEach((item) => {
      calculateFolderSize(item);
    });
    const dups = findDuplicateAttachments(structuredAttachments, newItems);

    if (dups.length > 0) {
      setDuplicatedAttachments(dups);
      setNewStructuredAttachments(newItems);
    } else {
      setStructuredAttachments([...structuredAttachments, ...newItems]);
    }
  };

  const handleClosingWarnInvalidFileWindow = () => {
    setInvalidFiles([]);
    setShowWarnInvalidFileTypes(false);
  };

  const handleSingleFile = async (f) => {
    const item = {
      type: 'file',
      file: f,
      name: f.name,
      fileFullPath: removeLeadingSlash(f.path),
      size: f.size,
    };
    return item;
  };

  const checkIfFormCompleted = () => {
    // if (!formState.dstOrgId || !formState.emergency) {
    //   let incompleteFields = [
    //     !formState.dstOrgId ? intl.get('new_label_send_to') : null,
    //     !formState.emergency ? intl.get('new_label_emergency') : null,
    //   ];
    //   incompleteFields = incompleteFields.filter(function (e) {
    //     return e;
    //   });
    //   setIncompleteFields(incompleteFields);
    //   return false;
    // } else {
    //   return true;
    // }
    let incompleteFields = [];
    const checkboxForm_ = checkboxForm.filter((elem) => {
      return elem;
    });

    if (!formState.dstOrgId) {
      incompleteFields.push(intl.get('new_label_send_to'));
    }
    if (!formState.emergency) {
      incompleteFields.push(intl.get('new_label_emergency'));
    }
    if (checkboxForm_.length === 0) {
      incompleteFields.push(intl.get('new_label_check_item'));
    }
    if (incompleteFields.length > 0) {
      setIncompleteFields(incompleteFields);
      return false;
    } else {
      return true;
    }
  };

  const handleConfirmContentsCheckbox = () => {
    if (sendContentsConfirmed) {
      setSendContentsConfirmed(false);
    } else {
      setSendContentsConfirmed(true);
    }
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();
    if (checkIfFormCompleted()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setShowWarningBeforeSend(true); // open the "confirm to send issue" modal window
      return;
    } else {
      setWarnFormIncomplete(true);
      return;
    }
  };

  const handleShowWarningBeforeSendModalClose = () => {
    setSendContentsConfirmed(false); // resume default value
    setShowWarningBeforeSend(false); // resume default value
  };

  const handleConfirmNotCheckModalClose = () => {
    setconfirmNotCheck(false);
  };

  const handleNewIssueFailModalClose = () => {
    setNewIssueConfirmButtonIsLoading(false);
    setShowWarningBeforeSend(false);
    setSendContentsConfirmed(false);
    setSendNewIssueFailed(false);
  };

  const handleExceedUploadMissionsLimitClose = () => {
    setExceedUploadMissionsLimit(false);
  };

  const getNumOngoingUploads = () => {
    const ongoings = Object.values(controllerUploadMissions.missions).filter(
      (x) => x.status === 'UPLOADING' || x.status === 'PROCESSING'
    );
    return ongoings.length;
  };

  const sendNewIssue = async () => {
    if (!sendContentsConfirmed) {
      setconfirmNotCheck(true);
      return false;
    }

    // Step 0: check if there are already 3 ongoing uploads missions;
    if (getNumOngoingUploads() >= 3) {
      setExceedUploadMissionsLimit(true);
      return;
    }

    setNewIssueConfirmButtonIsLoading(true);

    // Step 1: Create the Issue Entity in the backend, and get the issue unique ID;
    let issueInfo;
    try {
      issueInfo = await initNewIssue(
        formState,
        checkboxForm,
        attachmentsFromParent,
        structuredAttachments,
        formjson,
        tagListJson
      );
    } catch (error) {
      resetForm();
      setSendNewIssueFailed(true);
      return false;
    }

    const { issueId, issueIdentifierId, issueCreatedAt } = issueInfo;

    // Add the upload mission to Context;
    const batchTotalBytes = calcBatchTotalSize(structuredAttachments);

    addNewMission(dispatchUploadMissions, {
      issueId,
      issueIdentifierId,
      issueCreatedAt,
      formState,
      checkboxForm,
      structuredAttachments,
      batchTotalBytes,
      tagListJson,
    });

    setNewIssueConfirmButtonIsLoading(false);
    setShowWarningBeforeSend(false);
    setSendContentsConfirmed(false);
    // The rest of the steps are in function "sendNewIssueContinued"
    // It has to wait for addNewMission to finish updating the state stored in context;
    // Therefore It is placed inside useEffect hook
  };

  const sendNewIssueContinued = async (mission) => {
    // This function is a continuation from the "sendNewIssue" function;
    // It is placed in useEffect hook above and only executed after context state is successfully updated;

    const {
      issueId,
      issueIdentifierId,
      issueCreatedAt,
      formState,
      structuredAttachments,
      batchTotalBytes,
    } = mission;

    // Step 1: start a timer to track the upload speed of the upload mission that is about to start;
    const timer0 = new TaskTimer(1000); // milliseconds
    const task_uploadSpeed_checker = {
      id: 'heartbeat',
      tickInterval: 2, // number of ticks between 2 task runs;
      async callback(task) {
        // can also be an async function, returning a promise
        contextUpdateUploadingSpeed(dispatchUploadMissions, {
          missionId: issueIdentifierId,
        });
        if (!['INIT', 'UPLOADING'].includes(mission.status)) {
          timer0.stop();
        }
      },
    };
    timer0.add(task_uploadSpeed_checker).start();

    // Step 2: Upload the attachments to S3 bucket, and notify the backend to process it;
    try {
      setMissionStatusContext(dispatchUploadMissions, {
        missionId: issueIdentifierId,
        value: 'UPLOADING',
      });
      await uploadBatchAttachmentsToS3({
        missionId: issueIdentifierId,
        issueId,
        issueIdentifierId,
        issueCreatedAt,
        structuredAttachments,
        batchTotalBytes,
        srcOrgId: localData.get('currentOrganizationId'),
        dstOrgId: formState.dstOrgId,
        controllerUploadMissions,
        dispatchUploadMissions,
      });
    } catch (error) {
      // if cancelled, error = {message: 'canceled', name: 'CanceledError', code: 'ERR_CANCELED'}
      if (error.code === 'ERR_CANCELED') {
        console.log(`Upload is aborted: ${error}`);
        setMissionStatusContext(dispatchUploadMissions, {
          missionId: issueIdentifierId,
          value: 'UPLOAD_ABORTED',
        });
      } else {
        console.log(`Failed to upload batch of attachments: ${error}`);
        setMissionStatusContext(dispatchUploadMissions, {
          missionId: issueIdentifierId,
          value: 'UPLOAD_FAILED',
        });
      }
      return false;
    }

    setMissionStatusContext(dispatchUploadMissions, {
      missionId: issueIdentifierId,
      value: 'PROCESSING',
    });

    // Step 3: Notify the backend to start a periodic check on this issue;
    await startIssuePeriodicStatusCheck(issueId);

    // Step 4: while waiting for backend to process, poll every a few seconds to check for new status;
    const timer = new TaskTimer(1000); // milliseconds
    const task1 = {
      id: 'heartbeat',
      tickInterval: 5, // number of ticks between 2 taskTuns; e.g. run every 5 sec;
      totalRuns: 1440, // times to run; max 2 hour before timeout;
      async callback(task) {
        // can also be an async function, returning a promise
        const issueStatusRes = await checkForAttachmentsCompletionOnNewIssue(
          issueId
        );
        if (issueStatusRes === 'Complete') {
          setMissionStatusContext(dispatchUploadMissions, {
            missionId: issueIdentifierId,
            value: 'PROCESS_COMPLETE',
          });
          timer.stop();
        }
        if (issueStatusRes === 'Failed') {
          setMissionStatusContext(dispatchUploadMissions, {
            missionId: issueIdentifierId,
            value: 'PROCESS_FAILED',
          });
          timer.stop();
        }
      },
    };
    timer.add(task1).start();
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {/* <Card
        data-testid="page-new-main"
        sx={{
          // margin: 2,
          // marginTop: 3,
          overflow: 'visible',
        }}> */}
      <div data-testid="page-width-control" style={{ minWidth: '1180px' }}>
        <Grid
          data-testid="page-new-main"
          spacing={3}
          container
          style={{
            paddingLeft: 100,
            marginTop: 30,
            paddingRight: 30,
            maxWidth: 1180,
          }}>
          <Grid xs={4} item>
            {/* Left side grid*/}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="h3">
                  {intl.get('new_text_new_task')}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} style={{ marginTop: 105 }}>
                <BootstrapFormField
                  data-testid="new-issue-patient-name"
                  id="patientName"
                  label={intl.get('new_label_patient_name')}
                  onChange={handleChange(formConstants.PATIENT_NAME)}
                  value={formState.patientName}
                  required
                />
              </Grid>
              <Grid item xs="auto" sx={{ marginTop: 30 }}>
                <MDButton
                  data-testid="new-issue-tags-button"
                  color="tertiary"
                  width="50%"
                  onClick={() => setOpenCheckboxForm(!openCheckboxForm)}
                  style={{
                    color: 'white',
                    fontSize: pxToRem(12),
                    borderRadius: '30px',
                  }}>
                  {intl.get('new_button_modal_tag')}
                </MDButton>
              </Grid>
              <Grid item xs={10}>
                {showSelectedTagsNew()}
                {/* {showSelectedModalItems('CT').length > 0 ? (
                  <Box pb={2}>
                    <MDTypography component={'span'}>
                      <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        {'\u2022'} CT :
                      </span>{' '}
                      {createTags(showSelectedModalItems('CT'))}
                    </MDTypography>
                  </Box>
                ) : (
                  <></>
                )}
                {showSelectedModalItems('MRI').length > 0 ? (
                  <Box pb={2}>
                    <MDTypography component={'span'}>
                      <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        {'\u2022'} MRI :
                      </span>{' '}
                      {createTags(showSelectedModalItems('MRI'))}
                    </MDTypography>
                  </Box>
                ) : (
                  <></>
                )}
                {showSelectedPhyExamModalItems().length > 0 ? (
                  <Box pb={2}>
                    <MDTypography component={'span'}>
                      <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        {'\u2022'} 生理検査 :
                      </span>{' '}
                      {showSelectedPhyExamModalItems()}
                    </MDTypography>
                  </Box>
                ) : (
                  <></>
                )} */}
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={8} item>
            {/* Right side grid */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                {currentUsername === 'cvic_super' && (
                  <div>
                    <Autocomplete
                      id="srcOrgName"
                      value={formState.srcOrgName}
                      onChange={(event, newValue) => {
                        if (newValue && newValue.id)
                          handleSrcDstForCVICSuperUser(
                            formConstants.SOURCE_ORG_ID,
                            newValue
                          );
                      }}
                      options={getCVICSuperUserSourceOrgs(CvicSuperUserOrgs)}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label={'From: '}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    <FormFieldHelperText style={{ color: 'red' }}>
                      Source Organization
                    </FormFieldHelperText>
                  </div>
                )}
                {currentUsername === 'cvic_super' && (
                  <div>
                    <Autocomplete
                      data-testid="new-issue-select-destination"
                      id="dstOrgName"
                      value={formState.dstOrgName}
                      onChange={(event, newValue) => {
                        if (newValue && newValue.id)
                          handleSrcDstForCVICSuperUser(
                            formConstants.DEST_ORG_ID,
                            newValue
                          );
                      }}
                      options={getCVICSuperUserDestinationOrgs(
                        CvicSuperUserOrgs,
                        formState.srcOrgId
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label={'To: '}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    <FormFieldHelperText style={{ color: 'red' }}>
                      Destination Organization
                    </FormFieldHelperText>
                  </div>
                )}
                {!(currentUsername === 'cvic_super') && (
                  <Autocomplete
                    data-testid="new-issue-select-destination"
                    id="dstOrgName"
                    onChange={(event, newValue) => {
                      handleChangeOnForm(formConstants.DEST_ORG_ID, newValue);
                    }}
                    options={getDstOrgOptions(dstOrgs)}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    renderInput={(params) => (
                      <AutoCompleteFormField
                        {...params}
                        label={intl.get('new_label_send_to')}
                        placeholder={intl.get('new_helper_send_to')}
                        InputLabelProps={{ shrink: true }}
                        required
                        // variant="outlined"
                      />
                    )}
                    key={toggleResetDstOrgName}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  data-testid="new-issue-select-emergency"
                  id="emergency"
                  onChange={(event, newValue) => {
                    handleChangeOnForm(formConstants.EMERGENCY, newValue);
                  }}
                  options={getEmergencyOptions()}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  renderInput={(params) => (
                    <AutoCompleteFormField
                      {...params}
                      label={intl.get('new_label_emergency')}
                      placeholder={intl.get('new_helper_emergency')}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <Autocomplete
                  id="patientGender"
                  data-testid="new-issue-select-patient-gender"
                  value={genderValue}
                  onChange={(event, newValue) => {
                    handleChangeOnForm(formConstants.PATIENT_GENDER, newValue);
                    if (newValue && newValue.value) {
                      setGenderValue(getGenderValue(newValue.value));
                    } else {
                      setGenderValue('');
                    }
                  }}
                  options={getGenderOptions()}
                  isOptionEqualToValue={(option, value) => {
                    if (value === '') return true;
                    return option.label === value;
                  }}
                  renderInput={(params) => (
                    <AutoCompleteFormField
                      {...params}
                      label={intl.get('new_label_patient_gender')}
                      placeholder={intl.get('new_helper_select_gender')}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <BootstrapFormField
                  data-testid="new-issue-department"
                  id="departmentId"
                  label={intl.get('new_label_department')}
                  onChange={handleChange(formConstants.DEPARTMENT)}
                  value={formState.department}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <Autocomplete
                  data-testid="new-issue-select-doctor-in-charge"
                  multiple
                  id="doctor-in-charge"
                  onFocus={() => setDoctorsInChargeInputFocused(true)}
                  onBlur={() => setDoctorsInChargeInputFocused(false)}
                  options={doctorOptions}
                  freeSolo
                  open={
                    doctorsInChargeInputFocused &&
                    doctorOptions.some((option) =>
                      option.label
                        .toLowerCase()
                        .includes(doctorsInChargeTextInputValue.toLowerCase())
                    )
                  }
                  onInputChange={(event, value) => {
                    setDoctorsInChargeTextInputValue(value); // Update the input value state
                  }}
                  filterOptions={(opts, state) =>
                    opts.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    )
                  }
                  value={doctorsInCharge}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        labelcolor={darkMode ? 'white' : 'grey'}
                        deleteiconcolor={darkMode ? 'white' : 'grey'}
                        label={option.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <AutoCompleteFormField
                      {...params}
                      id="doctor-in-charge-input"
                      label={intl.get('new_label_doctor_in_charge')}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue.length > doctorsInCharge.length) {
                      const lastNewValue = newValue[newValue.length - 1];
                      if (typeof lastNewvalue !== 'string') {
                        let i = 0;
                        for (i = 0; i < newValue.length - 1; i++) {
                          if (
                            typeof newValue[i] !== 'string' &&
                            newValue[i].id === lastNewValue.id
                          ) {
                            break;
                          }
                        }
                        if (i !== newValue.length - 1) {
                          newValue.splice(newValue.length - 1);
                          newValue.splice(i, 1);
                        }
                      }
                    }
                    const newDoctors = newValue.map((doctor) => {
                      if (typeof doctor === 'string') {
                        return {
                          label: doctor,
                          id: -1,
                        };
                      }
                      return doctor;
                    });
                    handleChangeDoctors(newDoctors);
                    setDoctorsInCharge(newDoctors);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography
                  style={{
                    fontSize: '12px',
                    marginLeft: '10px',
                    marginTop: 0,
                  }}>
                  {intl.get('new_label_additional_info')}
                </MDTypography>
                <TextEditor
                  className={classes.description}
                  placeholder={intl.get('new_label_case_description')}
                  defaultValue={formState.description}
                  onChange={handleDescriptionChange}
                  key={toggleResetDescription}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Dropzone */}
          <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
            <div style={{ position: 'relative' }}>
              <DashboardNewDropZone
                position="relative"
                handleFiles={handleFiles}
                handleDeleteFiles={handleFileDelete}
                files={structuredAttachments}
                darkMode={darkMode}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
            <MDButton
              data-testid="new_send_button"
              color="tertiary"
              variant="contained"
              onClick={handleSubmitClick}
              style={{
                fontSize: pxToRem(18),
                left: '37%',
                minWidth: '300px',
                borderRadius: '30px',
              }}>
              {intl.get('new_button_submit')}
            </MDButton>
          </Grid>
        </Grid>
      </div>
      {/* Modals */}
      <ConfirmModal
        isOpened={confirmDeleteFile}
        title={intl.get('new_modal_to_be_removed')}
        onConfirm={() => {
          setStructuredAttachments(
            structuredAttachments.filter(
              (item, index) => !isDeepEqual(item, fileToDelete)
            )
          );
          setFileToDelete({});
          setConfirmDeleteFile(false);
        }}
        bodyText={`${intl.get('new_modal_confirm_to_be_removed_body')}`}
        confirmButtonText={intl.get('new_modal_confirm_to_be_removed')}
        onCancel={() => setConfirmDeleteFile(false)}
        cancelButtonText={intl.get('new_modal_cancel_to_be_removed')}>
        <MDBox
          data-testid="modal-child-box"
          sx={{ marginTop: '-10px', maxWidth: 'md' }}>
          <MDTypography variant="h6">{fileToDelete.name}</MDTypography>
        </MDBox>
      </ConfirmModal>

      <ConfirmModal
        isOpened={confirmDeleteAttachmentFromParent}
        title={intl.get('new_modal_to_be_removed')}
        onConfirm={() => {
          // setStructuredAttachments(
          //   structuredAttachments.filter(
          //     (item, index) => !isDeepEqual(item, fileToDelete)
          //   )
          // );
          setAttachmentsFromParent(
            attachmentsFromParent.filter(
              (item, index) => item.id !== attachmentsFromParentToDelete.id
            )
          );
          // setFileToDelete({});
          setAttachmentsFromParentToDelete({});
          // setConfirmDeleteFile(false);
          setConfirmDeleteAttachmentFromParent(false);
        }}
        bodyText={`${intl.get('new_modal_confirm_to_be_removed_body')} 
        ${
          attachmentsFromParentToDelete.structureJson
            ? attachmentsFromParentToDelete.structureJson.name
            : ''
        }`}
        confirmButtonText={intl.get('new_modal_confirm_to_be_removed')}
        onCancel={() => setConfirmDeleteAttachmentFromParent(false)}
        cancelButtonText={intl.get('new_modal_cancel_to_be_removed')}
      />

      <ConfirmModal
        isOpened={sendNewIssueFailed}
        title={intl.get('new_modal_new_issue_failed_message_1')}
        bodyText={intl.get('new_modal_new_issue_failed_message_2')}
        onConfirm={handleNewIssueFailModalClose}
        confirmButtonText={intl.get('new_modal_new_issue_failed_message_4')}>
        <MDBox data-testid="modal-child-box" sx={{ maxWidth: 'md' }}>
          <MDTypography variant="h6">
            {intl.get('new_modal_new_issue_failed_message_3')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      <ConfirmModal
        isOpened={confirmNotCheck}
        title={intl.get('confirm_not_check_message_1')}
        bodyText={intl.get('confirm_not_check_message_2')}
        onConfirm={handleConfirmNotCheckModalClose}
        confirmButtonText={intl.get('confirm_not_check_message_4')}>
        <MDBox data-testid="modal-child-box" sx={{ maxWidth: 'md' }}>
          <MDTypography variant="h6">
            {intl.get('confirm_not_check_message_3')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      <ConfirmModal
        isOpened={exceedUploadMissionsLimit}
        title={intl.get('new_modal_exceed_upload_limit_message_1')}
        bodyText={intl.get('new_modal_exceed_upload_limit_message_2')}
        onConfirm={handleExceedUploadMissionsLimitClose}
        confirmButtonText={intl.get(
          'new_modal_exceed_upload_limit_button_close'
        )}>
        <MDBox data-testid="modal-child-box" sx={{ maxWidth: 'md' }}>
          <MDTypography variant="h6">
            {intl.get('new_modal_exceed_upload_limit_message_3')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      <ConfirmModal
        isOpened={warnFormIncomplete}
        title={intl.get('new_modal_text_warning_title')}
        bodyText={intl.get('new_modal_text_warning_text_empty')}
        onConfirm={() => setWarnFormIncomplete(false)}
        confirmButtonText={intl.get('new_modal_tag_confirm')}>
        <MDBox>
          {incompleteFields &&
            incompleteFields.map((field, index) => (
              <MDTypography variant="h6" style={{ color: 'red' }} key={index}>
                {field}
              </MDTypography>
            ))}
          <MDTypography variant="h6" style={{ paddingTop: '30px' }}>
            {intl.get('new_modal_text_warning_text_fill')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      {/* Modal of confirmation sending new Issue to the server */}
      <ConfirmModal
        isOpened={showWarningBeforeSend}
        title={intl.get('new_modal_text_confirm_title')}
        bodyText={`*${intl.get('new_modal_text_confirm_text_correct')}`}
        onConfirm={sendNewIssue}
        onCancel={handleShowWarningBeforeSendModalClose}
        confirmButtonIsLoadingButton={true}
        confirmButtonIsLoading={newIssueConfirmButtonIsLoading}
        confirmButtonText={intl.get('new_modal_button_submit')}
        cancelButtonText={intl.get('new_modal_button_cancel')}>
        <MDBox sx={{ overFlowY: 'auto', maxWidth: 'xl' }}>
          {showWarningBeforeSend &&
            generateConfirmWindowContents(
              formState,
              attachmentsFromParent,
              structuredAttachments,
              checkboxForm,
              darkMode,
              selectedjson,
              tagListJson
            )}

          <Grid
            spacing={3}
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            style={{ marginTop: '20px' }}>
            <Grid xs={12} item>
              <FormControlLabel
                data-testid="confirm-modal-checkbox"
                style={{ color: darkMode ? 'white' : 'black' }}
                control={
                  <Checkbox
                    icon={
                      <CheckBoxOutlineBlankIcon style={{ fill: '#66ccff' }} />
                    }
                    onChange={handleConfirmContentsCheckbox}
                  />
                }
                label={intl.get('new_modal_text_confirm_text_sure')}
              />
            </Grid>
          </Grid>
        </MDBox>
      </ConfirmModal>

      {/*//! Different irregular modal - Open the more info modal, where select  */}
      <Modal
        data-testid="new-issue-tags-modal"
        open={openCheckboxForm}
        onClose={() => setOpenCheckboxForm(false)}
        className={classes.modalModal}>
        <Box>
          <TagCheckboxForm
            tagListJson={tagListJson}
            setTagListJson={setTagListJson}
            checkboxForm={checkboxForm}
            setCheckboxForm={setCheckboxForm}
            openCheckboxForm={openCheckboxForm}
            setOpenCheckboxForm={setOpenCheckboxForm}
            organizationId={currentOrganizationId}
            dstOrgId={formState.dstOrgId}
          />
        </Box>
      </Modal>

      <ConfirmModal
        isOpened={showWarnInvalidFileTypes}
        title={intl.get('new_modal_invalid_files_title')}
        bodyText={intl.get('new_modal_invalid_files_message_1')}
        onConfirm={handleClosingWarnInvalidFileWindow}
        confirmButtonColor="info"
        confirmButtonText={intl.get(
          'new_modal_exceed_upload_limit_button_close'
        )}>
        <MDBox data-testid="modal-child-box" sx={{ maxWidth: 'md' }}>
          {invalidFiles.map((file, index) => (
            <MDTypography variant="h6" style={{ color: 'red' }} key={index}>
              {file.path}
            </MDTypography>
          ))}
          <MDTypography variant="h6" style={{ paddingTop: '30px' }}>
            {intl.get('new_modal_invalid_files_message_2')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>

      {/* Changed into 2 buttons since the last button is useless*/}
      <ConfirmModal
        isOpened={duplicatedAttachments.length > 0}
        title={intl.get('new_modal_duplicate_files_message_1')}
        onConfirm={() => handleDuplicateAttachments('REPLACE')}
        onCancel={() => handleDuplicateAttachments('IGNORE')}
        confirmButtonColor="info"
        confirmButtonText={intl.get('new_modal_duplicate_files_replace')}
        cancelButtonColor="error"
        cancelButtonText={intl.get('new_modal_duplicate_files_ignore')}>
        <MDBox data-testid="modal-child-box" sx={{ maxWidth: 'md' }}>
          {duplicatedAttachments.map((file, index) => (
            <MDTypography variant="h6" style={{ color: 'red' }} key={index}>
              {file.fileFullPath}
            </MDTypography>
          ))}
          <MDTypography variant="h6" style={{ paddingTop: '30px' }}>
            {intl.get('new_modal_duplicate_files_message_2')}
          </MDTypography>
        </MDBox>
      </ConfirmModal>
      {/* </Card> */}
    </ThemeProvider>
  );
};

DashboardNew.propTypes = propTypes;

export default withRouter(DashboardNew);
