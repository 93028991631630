import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PublishIcon from '@mui/icons-material/Publish';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { USER_DATA } from 'shared/constants/users';
import { IssueStatus } from 'shared/constants/issues';
import localData from 'shared/utils/localData';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes),
  isClient: PropTypes.bool.isRequired,
  isDoctor: PropTypes.bool.isRequired,
  handleEditButtonClick: PropTypes.func.isRequired,
  handlePreviewButtonClick: PropTypes.func.isRequired,
  handlePublishButtonClick: PropTypes.func.isRequired,
  handleDownloadButtonClick: PropTypes.func.isRequired,
};

const ProjectBoardIssueDetailsReportList = ({
  issue,
  isClient,
  isDoctor,
  handleEditButtonClick,
  handlePreviewButtonClick,
  handlePublishButtonClick,
  handleDownloadButtonClick,
}) => {
  const username = localData.get(USER_DATA.USERNAME);
  const issueIsDone = issue.status === IssueStatus.DONE;
  return (
    <Fragment>
      {issue.reports.map((item, index) => {
        // TODO: get publish previlege according to the previlege system
        const hasPublishPrevilege = true; // Debug

        const isPublished = item.isPublished;

        const isEditable =
          !isPublished &&
          !isClient &&
          isDoctor &&
          item.creator.username === username &&
          !issueIsDone;

        const isPreview = isPublished || !isClient;

        const canPublish =
          !isPublished &&
          !isClient &&
          isDoctor &&
          hasPublishPrevilege &&
          !issueIsDone;

        return isClient && !isPublished ? (
          <Fragment></Fragment>
        ) : (
          <Grid container spacing={3} sx={{ padding: '5px 0px' }}>
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '500px' }}>
              <Typography
                variant="body2"
                style={{ wordWrap: 'break-word' }}
                data-testid={`report:${item.fileName}`}>
                {isPublished && !isClient
                  ? `📋 ${item.fileName}` +
                    intl.get('issue_details_report_list_text_published')
                  : `📋 ${item.fileName}`}
              </Typography>
            </Grid>

            {/* TODO the download button */}
            <Grid container spacing={2}>
              {isPublished && (
                <Grid>
                  <IconButton
                    onClick={() => handleDownloadButtonClick(item)}
                    aria-label={intl.get('issue_details_button_download')}>
                    <Tooltip title={intl.get('issue_details_button_download')}>
                      <DownloadIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              )}
              {canPublish && (
                <Grid>
                  <IconButton
                    data-testid={`report_button_publish:${item.fileName}`}
                    onClick={() => handlePublishButtonClick(item, index)}
                    aria-label={intl.get(
                      'issue_details_report_list_button_publish'
                    )}>
                    <Tooltip
                      title={intl.get(
                        'issue_details_report_list_button_publish'
                      )}>
                      <PublishIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              )}
              {isPreview && (
                <Grid>
                  <IconButton
                    data-testid={`report_button_preview:${item.fileName}`}
                    onClick={() => handlePreviewButtonClick(item)}
                    aria-label={intl.get(
                      'issue_details_report_list_button_preview'
                    )}>
                    <Tooltip
                      title={intl.get(
                        'issue_details_report_list_button_preview'
                      )}>
                      <DashboardIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              )}
              {isEditable && (
                <Grid>
                  <IconButton
                    data-testid={`report_button_edit:${item.fileName}`}
                    onClick={() => handleEditButtonClick(item, index, true)}
                    aria-label={intl.get(
                      'issue_details_report_list_button_edit'
                    )}>
                    <Tooltip
                      title={intl.get('issue_details_report_list_button_edit')}>
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Fragment>
  );
};

ProjectBoardIssueDetailsReportList.propTypes = propTypes;

export default ProjectBoardIssueDetailsReportList;
