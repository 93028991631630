import { rgb, PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import localData from 'shared/utils/localData';
import api from 'shared/utils/api';
import { pdf } from '@react-pdf/renderer';

const publicUrl = process.env.PUBLIC_URL;
export const getPresignedURL = async (attachmentId, issueId) => {
  const config = {
    params: {
      organizationId: localData.get('currentOrganizationId'),
      attachmentId: attachmentId,
    },
  };
  const response = await api.get(`/api/download/${issueId}`, config);
  return response.GetObjectPresignedURL;
};

//! This function is used specifically for the Yokohama Sports Center Report
export const loadPdfFromPresignedURL = async (
  objectPresignedURL,
  reportContent
) => {
  const existingPdfBytes = await fetch(objectPresignedURL).then((res) =>
    res.arrayBuffer()
  );
  if (existingPdfBytes) {
    console.debug('PDF loaded:', existingPdfBytes.byteLength);
  }
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const fontBytes = await fetch(
    `${publicUrl}/static/font/Noto_Sans_JP/NotoSansJP-Regular.otf`
  ).then((res) => res.arrayBuffer());
  if (fontBytes) {
    console.debug('Font loaded:', fontBytes.byteLength);
  }

  // Register fontkit
  pdfDoc.registerFontkit(fontkit);
  const font = await pdfDoc.embedFont(fontBytes);
  const pages = pdfDoc.getPages();

  // TODO Issue, we can only update the first page need to add to select.
  const firstPage = pages[0];

  // Adding the diagnosis
  firstPage.drawText(reportContent.diagnosis, {
    x: 30,
    y: 305,
    size: 11,
    font: font,
    color: rgb(0.29, 0.29, 0.29),
    maxWidth: 530,
    lineHeight: 14,
  });

  // Adding the measures
  firstPage.drawText(reportContent.measures, {
    x: 30,
    y: 435,
    size: 11,
    font: font,
    color: rgb(0.29, 0.29, 0.29),
    maxWidth: 530,
    lineHeight: 14,
  });

  // Adding the signature
  if (reportContent.signature) {
    firstPage.drawText(reportContent.signature, {
      x: 360,
      y: 75,
      size: 12,
      font: font,
      color: rgb(0.29, 0.29, 0.29),
    });
  }

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  return url;
};

export const generatePDFFromTemplate = async (
  template,
  reportContent,
  reportTitle
) => {
  const blob = await pdf(
    <template.template
      reportContent={reportContent}
      reportTitle={reportTitle}
    />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  return url;
};
