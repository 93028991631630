import intl from 'react-intl-universal';

const publicUrl = process.env.PUBLIC_URL;

export const IssueType = {
  TASK: 'task',
  // STORY: 'story',
};

export const IssueStatusID = {
  NEWTASK: '0_NEWTASK',
  CHECKREADY: '1_CHECKREADY',
  DOCTORASSIGNED: '2_DOCTORASSIGNED',
  REPORTREADY: '3_REPORTREADY',
  SENDBACK: '4_SENDBACK',
  DONE: '5_DONE',
};

export const IssueStatus = {
  NEWTASK: 'NEWTASK',
  CHECKREADY: 'CHECKREADY',
  DOCTORASSIGNED: 'DOCTORASSIGNED',
  REPORTREADY: 'REPORTREADY',
  SENDBACK: 'SENDBACK',
  DONE: 'DONE',
};

export const IssueStatusToKey = {
  NEWTASK: 'shared_value_status_new_task',
  CHECKREADY: 'shared_value_status_check_ready',
  DOCTORASSIGNED: 'shared_value_status_doctor_assigned',
  REPORTREADY: 'shared_value_status_report_ready',
  SENDBACK: 'shared_value_status_send_back',
  DONE: 'shared_value_status_done',
};

export const IssueStatusToOrder = {
  NEWTASK: 0,
  CHECKREADY: 1,
  DOCTORASSIGNED: 2,
  REPORTREADY: 3,
  SENDBACK: 4,
  DONE: 5,
};

export const IssueStatusForClientID = {
  NEWTASK: '0_NEWTASK',
  CHECKREADY: '1_INPROGRESS',
  DOCTORASSIGNED: '1_INPROGRESS',
  REPORTREADY: '1_INPROGRESS',
  SENDBACK: '2_SENDBACK',
  DONE: '3_DONE',
  INPROGRESS: '1_INPROGRESS',
};

export const IssueStatusForClient = {
  NEWTASK: 'NEWTASK',
  CHECKREADY: 'INPROGRESS',
  DOCTORASSIGNED: 'INPROGRESS',
  REPORTREADY: 'INPROGRESS',
  SENDBACK: 'SENDBACK',
  DONE: 'DONE',
  INPROGRESS: 'INPROGRESS',
};

export const IssueStatusForClientToOrder = {
  NEWTASK: 0,
  INPROGRESS: 1,
  SENDBACK: 2,
  DONE: 3,
};

export const IssueState = {
  NORMAL: 'NORMAL',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

// Sender side
export const IssueSelectableStatus = {
  NEWTASK: ['NEWTASK', 'CHECKREADY'],
  CHECKREADY: ['CHECKREADY', 'NEWTASK'],
  DOCTORASSIGNED: ['DOCTORASSIGNED', 'NEWTASK', 'CHECKREADY', 'REPORTREADY'],
  REPORTREADY: [
    'REPORTREADY',
    'NEWTASK',
    'CHECKREADY',
    'DOCTORASSIGNED',
    'SENDBACK',
  ],
  SENDBACK: [
    'SENDBACK',
    'NEWTASK',
    'CHECKREADY',
    'DOCTORASSIGNED',
    'REPORTREADY',
  ],
  DONE: ['DONE'],
};

export const IssueSelectableStatusWithWarning = {
  NEWTASK: ['NEWTASK'],
  CHECKREADY: ['CHECKREADY', 'NEWTASK'],
  DOCTORASSIGNED: ['DOCTORASSIGNED', 'NEWTASK', 'CHECKREADY'],
  REPORTREADY: ['REPORTREADY', 'NEWTASK', 'CHECKREADY', 'DOCTORASSIGNED'],
  SENDBACK: ['SENDBACK', 'NEWTASK', 'CHECKREADY', 'DOCTORASSIGNED'],
  DONE: ['DONE'],
};

// Client side
export const IssueSelectableStatusForClient = {
  NEWTASK: ['NEWTASK'],
  CHECKREADY: ['CHECKREADY'],
  DOCTORASSIGNED: ['DOCTORASSIGNED'],
  REPORTREADY: ['REPORTREADY'],
  SENDBACK: ['SENDBACK', 'DONE'],
  DONE: ['DONE'],
};

export const IssueSelectableStatusForClientWithWarning = {
  NEWTASK: ['NEWTASK'],
  CHECKREADY: ['CHECKREADY'],
  DOCTORASSIGNED: ['DOCTORASSIGNED'],
  REPORTREADY: ['REPORTREADY'],
  SENDBACK: ['SENDBACK'],
  DONE: ['DONE'],
};

// Doctor side
export const IssueSelectableStatusForDoctor = {
  NEWTASK: [],
  CHECKREADY: [],
  DOCTORASSIGNED: ['DOCTORASSIGNED', 'REPORTREADY'],
  REPORTREADY: ['DOCTORASSIGNED', 'REPORTREADY'],
  SENDBACK: [],
  DONE: [],
};

export const SentIssueStatus = {
  DONE: 'DONE',
};

export const IssuePriority = {
  HIGHEST: '5',
  HIGH: '4',
  MEDIUM: '3',
  LOW: '2',
  LOWEST: '1',
};

export const IssueTypeCopy = {
  [IssueType.TASK]: 'Task',
  // [IssueType.STORY]: 'Story',
};

export const IssueStatusCopy = {
  [IssueStatus.NEWTASK]: 'New Task',
  [IssueStatus.CHECKREADY]: 'Check Ready',
  [IssueStatus.DOCTORASSIGNED]: 'Doctor Assigned',
  [IssueStatus.REPORTREADY]: 'Report Ready',
  [IssueStatus.SENDBACK]: 'Send Back',
  [IssueStatus.DONE]: 'Done',
  [IssueStatus.RECHECK]: 'Recheck',
};

/**
 *  For receiver organization side:
 *  Given status key name; get the display string in chosen language;
 *  e.g. status key name: "NEWTASK"  --->  display string in JP: "新規依頼"
 */
export const getIssueStatus = (status) => {
  if (status === IssueStatus.NEWTASK) {
    return intl.get('shared_value_status_new_task');
  } else if (status === IssueStatus.CHECKREADY) {
    return intl.get('shared_value_status_check_ready');
    // return 'checked';
  } else if (status === IssueStatus.DOCTORASSIGNED) {
    return intl.get('shared_value_status_doctor_assigned');
    // return 'assigned';
  } else if (status === IssueStatus.REPORTREADY) {
    return intl.get('shared_value_status_report_ready');
  } else if (status === IssueStatus.SENDBACK) {
    return intl.get('shared_value_status_send_back_receiver');
    // return 'returned';
  } else if (status === IssueStatus.DONE) {
    return intl.get('shared_value_status_done_receiver');
  }
};

export const getIssueState = (state) => {
  if (state === IssueState.NORMAL) {
    return intl.get('shared_value_state_normal');
  } else if (state === IssueState.WARNING) {
    return intl.get('shared_value_state_warning');
  } else if (state === IssueState.ERROR) {
    return intl.get('shared_value_state_error');
  }
};

/**
 *  For client (sender) organization side:
 *  Given status key name; get the display string in chosen language;
 *  e.g. "CHECKREADY" / "DOCTORASSIGNED" / "REPORTREADY" --->  "進行中"
 */
export const getIssueStatusForClient = (status) => {
  if (
    status === IssueStatus.NEWTASK ||
    status === IssueStatusForClient.NEWTASK
  ) {
    return intl.get('shared_value_status_new_task');
  } else if (
    status === IssueStatus.CHECKREADY ||
    status === IssueStatusForClient.CHECKREADY
  ) {
    return intl.get('shared_value_status_in_progress');
  } else if (
    status === IssueStatus.DOCTORASSIGNED ||
    status === IssueStatusForClient.DOCTORASSIGNED
  ) {
    return intl.get('shared_value_status_in_progress');
  } else if (
    status === IssueStatus.REPORTREADY ||
    status === IssueStatusForClient.REPORTREADY
  ) {
    return intl.get('shared_value_status_in_progress');
  } else if (
    status === IssueStatus.SENDBACK ||
    status === IssueStatusForClient.SENDBACK
  ) {
    return intl.get('shared_value_status_send_back_sender');
  } else if (
    status === IssueStatus.DONE ||
    status === IssueStatusForClient.DONE
  ) {
    return intl.get('shared_value_status_done_sender');
  }
};

export const getIssueStatusInFigures = (status) => {
  if (status === IssueStatus.NEWTASK) {
    return `${publicUrl}/static/img/progress-1.png`;
  } else if (status === IssueStatus.CHECKREADY) {
    return `${publicUrl}/static/img/progress-2.png`;
  } else if (status === IssueStatus.DOCTORASSIGNED) {
    return `${publicUrl}/static/img/progress-3.png`;
  } else if (status === IssueStatus.REPORTREADY) {
    return `${publicUrl}/static/img/progress-4.png`;
  } else if (status === IssueStatus.SENDBACK) {
    return `${publicUrl}/static/img/progress-5.png`;
  } else if (status === IssueStatus.DONE) {
    return `${publicUrl}/static/img/progress-6.png`;
  } else if (status === IssueStatus.RECHECK) {
    return `${publicUrl}static/img/progress-7.png`;
  }
};

export const IssuePriorityCopy = {
  [IssuePriority.HIGHEST]: 'Urgent',
  [IssuePriority.HIGH]: 'Urgent 3 Hours',
  [IssuePriority.MEDIUM]: 'In 24 Hours',
  [IssuePriority.LOW]: 'Regular',
  [IssuePriority.LOWEST]: 'Regular',
};

export const getIssuePriority = (priority) => {
  if (priority === IssuePriority.HIGHEST) {
    return intl.get('shared_value_priority_highest');
  } else if (priority === IssuePriority.HIGH) {
    return intl.get('shared_value_priority_high');
  } else if (priority === IssuePriority.MEDIUM) {
    return intl.get('shared_value_priority_medium');
  } else if (priority === IssuePriority.LOW) {
    return intl.get('shared_value_priority_low');
  } else if (priority === IssuePriority.LOWEST) {
    return intl.get('shared_value_priority_lowest');
  }
};

// added temporarily:
export const IssueSelectableStatus_BatchEditModal = {
  NEWTASK: ['NEWTASK', 'CHECKREADY'],
  CHECKREADY: ['CHECKREADY', 'NEWTASK', 'DOCTORASSIGNED'],
  DOCTORASSIGNED: ['DOCTORASSIGNED', 'NEWTASK', 'CHECKREADY', 'REPORTREADY'],
  REPORTREADY: [
    'REPORTREADY',
    'NEWTASK',
    'CHECKREADY',
    'DOCTORASSIGNED',
    'SENDBACK',
  ],
  SENDBACK: [
    'SENDBACK',
    'NEWTASK',
    'CHECKREADY',
    'DOCTORASSIGNED',
    'REPORTREADY',
  ],
  DONE: ['DONE'],
};

export const TAG_SYSTEM = {
  CUSTOMIZED: 'customized',
  FIXED: 'fixed',
  NEW: 'new',
};
