import { Page, Document } from '@react-pdf/renderer';
import ReportDescription from './ReportDescription';
import ReportDiagnosis from './ReportDiagnosis';
import ReportKeyImages from './ReportKeyImages';
import ReportHeader from './ReportHeader';
import ReportMeasures from './ReportMeasures';
import ReportTitle from './ReportTitle';
import ReportInfo from './ReportInfo';
import ReportDateAndDoctors from './ReportDateAndDoctors';
import { styles } from './styles';
import PropTypes from 'prop-types';

const DocloudTemplate = ({ reportTitle, reportContent }) => {
  return (
    <Document title={reportTitle}>
      <Page size="A4" style={styles.firstPage} wrap={true}>
        <ReportHeader />
        <ReportTitle />
        <ReportInfo reportContent={reportContent} />
        <ReportDescription reportContent={reportContent} />
        <ReportMeasures reportContent={reportContent} />
        <ReportDiagnosis reportContent={reportContent} />
        <ReportKeyImages reportContent={reportContent} />
        <ReportDateAndDoctors reportContent={reportContent} />
      </Page>
    </Document>
  );
};

DocloudTemplate.propTypes = {
  reportTitle: PropTypes.string.isRequired,
  reportContent: PropTypes.object.isRequired,
};

export default DocloudTemplate;
