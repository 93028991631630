import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Button, Tooltip } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import StarIcon from '@mui/icons-material/Star';

import CustomToolbarSelect from './CustomToolbarSelect';
import CustomTitle from './CustomTitle';
import { Modal, RefreshButton } from 'components';

import useStyles from './styles';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { formatTime } from 'shared/utils/formatTime';
import IssueDetails from 'pages/Dashboard/Kanban/IssueDetails';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { getIssuePriority } from 'shared/constants/issues';
import { modalWidths } from 'shared/utils/styles';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes).isRequired,
  paidWageData: PropTypes.array.isRequired,
  setPaidWageData: PropTypes.func.isRequired,
  doctorName: PropTypes.string.isRequired,
  doctors: PropTypes.array.isRequired,
  selectedDoctorId: PropTypes.number.isRequired,
  setTableType: PropTypes.func.isRequired,
  refreshTableData: PropTypes.func.isRequired,
};

const DashboardPaidWageDetailedTable = ({
  match,
  history,
  paidWageData,
  setPaidWageData,
  doctorName,
  doctors,
  selectedDoctorId,
  setTableType,
  refreshTableData,
}) => {
  const classes = useStyles();

  const [data, setData] = useState([]);

  const goToIssueDetails = (issueId) => {
    history.push(`${match.path}/seimitsu/issues/${issueId}`);
  };

  const updateLocalProjectIssues = (issueId, updateFields) => {
    // TODO
  };

  useEffect(() => {
    const getDetailedTableData = (paidWageData, selectedDoctorId) => {
      const paidWage = paidWageData.filter(
        (item) => item.user.id === selectedDoctorId
      );

      const tableData = paidWage.map((wage) => {
        const {
          id,
          issueCheckItem,
          issueCreatedAt,
          createdAt,
          issueId,
          issueTitle,
          issuePriority,
          emergencyText,
          originalWage,
          currentWage,
          starred,
        } = wage;

        return [
          issueTitle,
          // emergencyText,
          getIssuePriority(issuePriority),
          issueCreatedAt,
          createdAt,
          {
            originalWage: originalWage,
            currentWage: currentWage,
          },
          {
            wageId: id,
            issueId: issueId,
          },
          starred,
        ];
      });
      setData(tableData);
    };

    getDetailedTableData(paidWageData, selectedDoctorId);
  }, [paidWageData, selectedDoctorId]);

  const columns = [
    {
      name: 'issue_title',
      label: intl.get('paid_wage_label_column_issue_title'),
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'emergency',
      label: intl.get('paid_wage_label_column_emergency'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'order_time',
      label: intl.get('paid_wage_label_column_issue_order_time'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return formatTime(value);
        },
      },
    },
    {
      name: 'complete_time',
      label: intl.get('paid_wage_label_column_issue_complete_time'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return formatTime(value);
        },
      },
    },
    {
      name: 'wage',
      label: intl.get('paid_wage_label_column_wage'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const originalWage = value.originalWage;
          const currentWage = value.currentWage;

          if (currentWage === null || currentWage === originalWage) {
            return (
              <div>
                <p>￥{originalWage}</p>
              </div>
            );
          }
          return (
            <div>
              <p>
                ￥{currentWage} <del>￥{originalWage}</del>
              </p>
            </div>
          );
        },
      },
    },
    {
      name: 'view',
      label: intl.get('paid_wage_label_column_view_issue'),
      options: {
        customBodyRender: (value) => {
          const issueId = parseInt(value.issueId);
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => goToIssueDetails(issueId)}>
                {intl.get('paid_wage_button_view_issue')}
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: 'flag',
      label: intl.get('paid_wage_label_column_flag'),
      options: {
        customBodyRender: (value) => {
          const ifStarred = value;
          return (
            <div>
              {ifStarred && (
                <Tooltip
                  className={classes.tooltipMark}
                  title={intl.get('paid_wage_detailed_tooltip_star_mark')}>
                  <StarIcon fontSize="large" color="warning" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    filterType: 'textField',
    responsive: 'stacked',
    rowsPerPage: 25,
    page: 1,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          data={data}
          setData={setData}
        />
      );
    },
    customToolbar: () => {
      return <RefreshButton handleRefreshClick={refreshTableData} />;
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      <ThemeProvider theme={createTheme()}>
        <MUIDataTable
          title={
            <CustomTitle titleText={doctorName} setTableType={setTableType} />
          }
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
      <Route
        path={`${match.path}/seimitsu/issues/:issueId`}
        render={(routeProps) => (
          <Modal
            isOpen
            width={modalWidths.xxl}
            minWidth={modalWidths.xl}
            withCloseIcon={false}
            onClose={() => history.push(match.url)}
            renderContent={(modal) => (
              <IssueDetails
                issueId={routeProps.match.params.issueId}
                trigger={'byClick'}
                doctors={doctors}
                updateLocalProjectIssues={updateLocalProjectIssues}
                modalClose={modal.close}
              />
            )}
          />
        )}
      />
    </Fragment>
  );
};

DashboardPaidWageDetailedTable.propTypes = propTypes;

export default withRouter(DashboardPaidWageDetailedTable);
