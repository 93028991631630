import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import intl from 'react-intl-universal';

import { DropZoneContainer, DropZoneOverlay } from './styles';
import { generateDirectoryList } from 'pages/Dashboard/New/utils';
import { Grid } from '@material-ui/core';
import pxToRem from 'assets/theme/functions/pxToRem';
import MDButton from 'components/MDButton';

const propTypes = {
  handleFiles: PropTypes.func.isRequired,
  handleDeleteFiles: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  darkMode: PropTypes.bool.isRequired,
};

const DashboardNewDropZone = ({
  handleFiles,
  files,
  handleDeleteFiles,
  darkMode,
}) => {
  const handleOnDrop = (files) => {
    handleFiles(files);
  };

  return (
    <Dropzone onDrop={handleOnDrop} noClick={true}>
      {({ getRootProps, getInputProps, open }) => {
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} data-testid="file-input" />
            <DropZoneContainer containsFiles={files.length > 0}>
              <Grid container>
                {generateDirectoryList(files, handleDeleteFiles, darkMode)}
              </Grid>
              <DropZoneOverlay
                darkMode={darkMode}
                containsFiles={files.length > 0}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}>
                {intl.get('new_text_dropzone_message')}
              </DropZoneOverlay>
            </DropZoneContainer>
            <MDButton
              data-testid="new_send_button"
              color="primary"
              variant="contained"
              onClick={open}
              style={{
                fontSize: pxToRem(18),
                position: 'absolute',
                left: '37%',
                bottom: '-20px',
                minWidth: '300px',
                borderRadius: '30px',
              }}>
              {intl.get('new_text_select_files')}
            </MDButton>
          </div>
        );
      }}
    </Dropzone>
  );
};

DashboardNewDropZone.propTypes = propTypes;

export default DashboardNewDropZone;
