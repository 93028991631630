import localData from './localData';
import moment from 'moment-timezone';

const toLocalTime = (srcTimeRepresentation) => {
  const srcTime = new Date(srcTimeRepresentation);
  const tgtTime = srcTime.toString();
  return tgtTime;
};

export const formatDateTitle = (datetimeString) => {
  const lang = localData.get('userPreferredLanguage');
  if (lang === 'ja-JP') {
    const deadline = moment(datetimeString).tz('Asia/Tokyo');
    return deadline.format('YYYYMMDDHHmm');
  } else if (lang === 'zh-CN') {
    const deadline = moment(datetimeString).tz('Asia/Beijing');
    return deadline.format('YYYYMMDDHHmm');
  } else {
    const deadline = moment(datetimeString);
    return deadline.format('YYYYMMDDHHmm');
  }
};

export const formatDateTimeJa = (datetimeString) => {
  // Parse the input string with Moment.js, considering the timezone
  const deadline = moment(datetimeString).tz('Asia/Tokyo');

  // Format the date and time components in Japanese, excluding seconds
  return deadline.format('YYYY年MM月DD日 HH時mm分');
};

export const formatDateTimeZh = (datetimeString) => {
  const deadline = moment(datetimeString).tz('Asia/Beijing');
  return deadline.format('YYYY年MM月DD日 HH时mm分');
};

const formatDateTimeEn = (deadlineString) => {
  const deadline = new Date(deadlineString);

  if (deadline.getHours() < 9) {
    deadline.setDate(deadline.getDate() - 1);
  }
  const deadlineYear = deadline.getFullYear();
  const deadlineMonth = deadline.getMonth() + 1;
  const deadlineDate = deadline.getDate();
  const deadlineHour = deadline.getHours();
  var deadlineHourString = ' by';
  if (deadlineHour < 9) {
    deadlineHourString += ' 18:00'; // 18:00
  } else if (deadlineHour < 12) {
    deadlineHourString += ' 09:00'; // 09:00
  } else if (deadlineHour < 15) {
    deadlineHourString += ' 12:00'; // 12:00
  } else if (deadlineHour < 18) {
    deadlineHourString += ' 15:00'; // 15:00
  } else {
    deadlineHourString += ' 18:00'; // 18:00
  }
  const formatedDeadline =
    deadlineYear.toString() +
    '-' +
    deadlineMonth.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    '-' +
    deadlineDate.toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
    deadlineHourString;
  return formatedDeadline;
};

// Formats the deadline depending on the language
const formatDateTime = (timeRep) => {
  const timeStringLocal = timeRep;

  const lang = localData.get('userPreferredLanguage');

  var formatedDeadline = '';
  if (lang === 'zh') {
    formatedDeadline = formatDateTimeZh(timeStringLocal);
  } else if (lang === 'ja-JP') {
    formatedDeadline = formatDateTimeJa(timeStringLocal);
  } else {
    formatedDeadline = formatDateTimeEn(timeStringLocal);
  }

  return formatedDeadline;
};

const formatTimeZh = (timeRep) => {
  const time = new Date(timeRep);

  const daysZh = ['日', '一', '二', '三', '四', '五', '六']; // Chinese days of the week.
  const timeYear = time.getFullYear();
  const timeMonth = time.getMonth() + 1; // Adjust for zero-indexed months.
  const timeDate = time.getDate();
  const timeDayString = `星期${daysZh[time.getDay()]}`; // Use array to map day number to Chinese string.

  // Format the date string using template literals and padStart for zero-padding, with Chinese characters.
  return `${timeYear}年${String(timeMonth).padStart(2, '0')}月${String(
    timeDate
  ).padStart(2, '0')}日 ${timeDayString}`;
};

const formatTimeJa = (timeRep) => {
  const time = new Date(timeRep);

  const daysJa = ['日', '月', '火', '水', '木', '金', '土']; // Japanese days of the week.
  const timeYear = time.getFullYear();
  const timeMonth = time.getMonth() + 1; // Adjust for zero-indexed months.
  const timeDate = time.getDate();
  const timeDayString = daysJa[time.getDay()]; // Use array to map day number to Japanese string.

  // Format the date string using template literals and padStart for zero-padding, with Japanese characters.
  return `${timeYear}年${String(timeMonth).padStart(2, '0')}月${String(
    timeDate
  ).padStart(2, '0')}日 (${timeDayString})`;
};

const formatTimeEn = (timeRep) => {
  const time = new Date(timeRep);

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const timeYear = time.getFullYear();
  const timeMonth = time.getMonth() + 1; // Adjust for zero-indexed months.
  const timeDate = time.getDate();
  const timeDayString = days[time.getDay()]; // Use array to map day number to string.

  // Format the date string using template literals and padStart for zero-padding.
  return `${timeYear}-${String(timeMonth).padStart(2, '0')}-${String(
    timeDate
  ).padStart(2, '0')} ${timeDayString}`;
};

const formatTime = (timeRep) => {
  // timeRep is short for time representation
  const timeStringLocal = toLocalTime(timeRep);

  const lang = localData.get('userPreferredLanguage');

  var formatedTime = '';
  if (lang === 'zh-CN') {
    formatedTime = formatTimeZh(timeStringLocal);
  } else if (lang === 'ja-JP') {
    formatedTime = formatTimeJa(timeStringLocal);
  } else {
    // the default language is English
    formatedTime = formatTimeEn(timeStringLocal);
  }

  return formatedTime;
};

export { formatDateTime, formatTime };
