import { styled } from '@mui/material/styles';

export const DeadlinePassed = styled('span')({
  color: 'white',
  background: 'black',
  border: 'thick solid black',
  fontWeight: 'bold',
});

export const Deadline30Mins = styled('span')({
  color: 'white',
  background: 'red',
  border: 'solid red',
  fontWeight: 'bold',
});

export const Deadline1Hour = styled('span')({
  color: 'red',
  border: 'solid red',
  fontWeight: 'bold',
});

export const Deadline3Hours = styled('span')({
  border: 'solid orange',
  fontWeight: 'bold',
});

export const Deadline24Hours = styled('span')({
  border: 'solid black',
});

export const Deadline7Days = styled('span')({
  border: 'solid black',
});
