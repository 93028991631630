import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from 'shared/utils/formatTime';
import {
  DeadlinePassed,
  Deadline30Mins,
  Deadline1Hour,
  Deadline3Hours,
  Deadline24Hours,
} from './styles';
import { IssueStatus } from 'shared/constants/issues';

const DEADLINE_THRESHOLDS = {
  '30MINLEFT': 3 * 60 * 1000,
  '1HOURLEFT': 60 * 60 * 1000,
  '3HOURSLEFT': 3 * 60 * 60 * 1000,
  '24HOURSLEFT': 24 * 60 * 60 * 1000,
};

const propTypes = {
  deadline: PropTypes.string.isRequired,
  currentStatus: PropTypes.string.isRequired,
};

// Decides if the deadline has passed or not
const DataTableDeadline = ({ deadline, currentStatus }) => {
  const formatedDeadline = formatDateTime(deadline);
  const now = new Date();
  const deadlineDate = new Date(deadline);
  const timeDiff = deadlineDate.getTime() - now.getTime();

  // Time has gone over the deadline
  if (timeDiff < 0) {
    if (currentStatus !== IssueStatus.DONE) {
      return <DeadlinePassed>{formatedDeadline}</DeadlinePassed>;
    } else {
      return <div>{formatedDeadline}</div>;
    }
    // Not yet over the deadline
  } else {
    if (timeDiff < DEADLINE_THRESHOLDS['30MINLEFT']) {
      return <Deadline30Mins>{formatedDeadline}</Deadline30Mins>;
    } else if (timeDiff < DEADLINE_THRESHOLDS['1HOURLEFT']) {
      return <Deadline1Hour>{formatedDeadline}</Deadline1Hour>;
    } else if (timeDiff < DEADLINE_THRESHOLDS['3HOURSLEFT']) {
      return <Deadline3Hours>{formatedDeadline}</Deadline3Hours>;
    } else if (timeDiff < DEADLINE_THRESHOLDS['24HOURSLEFT']) {
      return <Deadline24Hours>{formatedDeadline}</Deadline24Hours>;
    } else {
      return <div>{formatedDeadline}</div>;
    }
  }
};
DataTableDeadline.propTypes = propTypes;
export default DataTableDeadline;
