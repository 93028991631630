import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './Styles';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Editor from '../Editor';
import Preview from '../Preview';

const propTypes = {
  template: PropTypes.object,
  issueId: PropTypes.number.isRequired,
  reportContent: PropTypes.object.isRequired,
  setReportContent: PropTypes.func.isRequired,
  saveReportContent: PropTypes.func.isRequired,
  modalClose: PropTypes.func,
  isEditable: PropTypes.bool.isRequired,
};

const PreviewMemo = React.memo(Preview);

// If the file data is sent then open the preview
const DashboardKanbanIssueDetailsReportsReportEditor = ({
  template,
  issueId,
  reportContent,
  setReportContent,
  saveReportContent,
  modalClose,
  isEditable,
}) => {
  const classes = useStyles();
  const [shouldShowPreview, setShouldShowPreview] = useState(true);
  const [previewContent, setPreviewContent] = useState({
    ...reportContent,
  });

  const togglePreview = () => {
    setShouldShowPreview(!shouldShowPreview);
  };

  return (
    <Fragment>
      <Grid>
        <Grid
          container
          className={classes.actionGrid}
          justifyContent="flex-end">
          <div>
            <IconButton onClick={modalClose}>
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </div>
        </Grid>
        <Grid
          container
          className={classes.reportEditorGrid}
          columns={{ xs: 12 }}
          justifyContent="center" // Centers the grid items horizontally
          alignItems="center">
          <Grid
            className={classes.editorGrid}
            size={shouldShowPreview ? 6 : 12}>
            <Editor
              isEditable={isEditable}
              reportContent={reportContent}
              setReportContent={setReportContent}
              setPreviewContent={setPreviewContent}
              saveReportContent={saveReportContent}
              shouldShowPreview={shouldShowPreview}
              togglePreview={togglePreview}
            />
          </Grid>
          {shouldShowPreview && (
            <Grid className={classes.previewGrid} size={6}>
              <PreviewMemo
                reportContent={previewContent}
                template={template}
                issueId={issueId}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

DashboardKanbanIssueDetailsReportsReportEditor.propTypes = propTypes;

export default withRouter(DashboardKanbanIssueDetailsReportsReportEditor);
