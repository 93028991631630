import React, { Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Grid } from '@material-ui/core';

import { ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';

// import DatePicker from './DatePicker';
import { CustomDatePicker as DatePicker } from 'pages/parts/DatePicker';
import SummaryTable from './SummaryTable';
import DetailedTable from './DetailedTable';
import DetailedTableKenshin from './DetailedTableKenshin';

import localData from 'shared/utils/localData';
import api from 'shared/utils/api';

// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { PageError, PageLoader } from 'components';
import { useMaterialUIController } from 'context';
import { getDatePickerTheme, getTablesTheme } from 'pages/parts/CustomThemes';
import { TABLE_TYPE } from './constants';
import { USER_DATA } from 'shared/constants/users';
import { DOLPHIN_API, KENSHIN_API } from 'shared/constants/apis';
import { getFirstDateOfTheSameMonth } from 'shared/utils/dateTime';
import { useSnackbar } from 'notistack';

const DashboardPaidWage = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { enqueueSnackbar } = useSnackbar();

  const currentDate = new Date();
  const firstDate = getFirstDateOfTheSameMonth(currentDate);

  const [loaded, setLoaded] = useState(false);
  const [loadedKenshin, setLoadedKenshin] = useState(false);
  const [ifError, setIfError] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(firstDate);
  const [selectedEndDate, setSelectedEndDate] = useState(currentDate);
  const [tableType, setTableType] = useState(TABLE_TYPE.SUMMARY);
  const [selectedDoctorId, setSelectedDoctorId] = useState(-1);
  const [selectedDoctorName, setSelectedDoctorName] = useState('');
  const [paidWageData, setPaidWageData] = useState([]);
  const [paidWageDataKenshin, setPaidWageDataKenshin] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const preferredLanguage = localData.get('userPreferredLanguage');
  const dstOrgId = localData.get(USER_DATA.CURRENT_ORGANIZATION_ID);

  useEffect(() => {
    const apiVariables = {
      params: {
        organizationId: parseInt(dstOrgId),
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      },
    };

    const kenshinApiVariables = {
      baseURL: process.env.REACT_APP_KENSHIN_API_URL,
      params: {
        userId: localData.get(USER_DATA.USER_ID),
        username: localData.get(USER_DATA.USERNAME),
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      },
    };

    api
      .get(DOLPHIN_API.PAID_WAGE, apiVariables)
      .then(async (data) => {
        const { paidWages, doctors } = data;
        const sortedPaidWages = paidWages.sort((wage1, wage2) =>
          wage1.id < wage2.id ? 1 : -1
        );
        setPaidWageData(sortedPaidWages);
        setDoctors(doctors);
        setLoaded(true);
      })
      .then((error) => {
        return <PageError />;
      });

    api
      .get(KENSHIN_API.PAID_WAGE, kenshinApiVariables)
      .then(async (data) => {
        const { paidWages } = data;
        const sortedPaidWages = paidWages.sort((wage1, wage2) =>
          wage1.id < wage2.id ? 1 : -1
        );
        setPaidWageDataKenshin(sortedPaidWages);
        setLoadedKenshin(true);
      })
      .then((error) => {
        return <PageError />;
      });
  }, [dstOrgId, selectedStartDate, selectedEndDate]);

  if (!(loaded && loadedKenshin)) {
    return <PageLoader />;
  }

  const refreshTableData = () => {
    if (selectedEndDate.toDateString() === new Date().toDateString()) {
      setSelectedEndDate(new Date());
    }
    enqueueSnackbar(intl.get('shared_table_data_reload_success'), {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };

  return (
    <Fragment>
      <Grid container style={{ marginBottom: '10px', marginTop: '40px' }}>
        <Grid item>
          <MDBox ml={2}>
            <MDTypography
              variant="h6"
              gutterBottom
              style={{
                fontWeight: 'bold',
                marginTop: '16px',
                marginRight: '18px',
                textAlign: 'right',
                alignSelf: 'stretch',
              }}>
              {intl.get('paid_wage_text_select_period')}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={2}>
          <ThemeProvider theme={getDatePickerTheme(darkMode)}>
            <DatePicker
              selectedDate={selectedStartDate}
              setSelectedDate={setSelectedStartDate}
              labelText={intl.get('paid_wage_text_select_period_from')}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={2}>
          <ThemeProvider theme={getDatePickerTheme(darkMode)}>
            <DatePicker
              selectedDate={selectedEndDate}
              setSelectedDate={setSelectedEndDate}
              labelText={intl.get('paid_wage_text_select_period_to')}
              minDate={selectedStartDate}
            />
          </ThemeProvider>
        </Grid>
      </Grid>

      <Card sx={{ margin: 2, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            {tableType === TABLE_TYPE.SUMMARY && (
              <SummaryTable
                paidWageData={paidWageData}
                paidWageDataKenshin={paidWageDataKenshin}
                setSelectedDoctorId={setSelectedDoctorId}
                setSelectedDoctorName={setSelectedDoctorName}
                setTableType={setTableType}
                refreshTableData={refreshTableData}
              />
            )}
            {tableType === TABLE_TYPE.DETAILED && (
              <DetailedTable
                paidWageData={paidWageData}
                setPaidWageData={setPaidWageData}
                doctorName={selectedDoctorName}
                doctors={doctors}
                selectedDoctorId={selectedDoctorId}
                setTableType={setTableType}
                refreshTableData={refreshTableData}
              />
            )}
            {tableType === TABLE_TYPE.DETAILED_KENSHIN && (
              <DetailedTableKenshin
                paidWageData={paidWageDataKenshin}
                setWageData={setPaidWageDataKenshin}
                doctors={doctors}
                doctorName={selectedDoctorName}
                selectedDoctorId={selectedDoctorId}
                setTableType={setTableType}
                refreshTableData={refreshTableData}
              />
            )}
          </ThemeProvider>
        </MDBox>
      </Card>
    </Fragment>
  );
};

export default withRouter(DashboardPaidWage);
