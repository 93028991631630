import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';

import { Card, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import FormField from 'pages/parts/FormField';
import DropZone from 'pages/parts/DropZoneLegacy';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
import CachedIcon from '@mui/icons-material/Cached';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';

const propTypes = {
  reportContent: PropTypes.object.isRequired,
  setReportContent: PropTypes.func.isRequired,
  setPreviewContent: PropTypes.func.isRequired,
  saveReportContent: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  shouldShowPreview: PropTypes.bool.isRequired,
  togglePreview: PropTypes.func.isRequired,
};

const DashboardKanbanIssueDetailsReportsEditor = ({
  reportContent,
  setReportContent,
  setPreviewContent,
  saveReportContent,
  isEditable,
  shouldShowPreview,
  togglePreview,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyClick = async (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(intl.get('issue_details_report_preview_text_copied'), {
      variant: 'success',
    });
  };

  const handleChange = (key) => async (event) => {
    setReportContent((prevState) => {
      return {
        ...prevState,
        [key]: event.target.value,
      };
    });
  };

  const handleImages = async (files) => {
    let newKeyImages = [...reportContent.keyImages];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      const fileName = file.name;
      const fileBuffer = await file.arrayBuffer();
      newKeyImages.push({
        fileName: fileName,
        fileBuffer: Buffer.from(fileBuffer),
      });
    }
    setReportContent((prevState) => {
      return {
        ...prevState,
        keyImages: newKeyImages,
      };
    });
  };

  const handleKeyImageDelete = (item, index) => {
    let newKeyImages = reportContent.keyImages;
    newKeyImages.splice(index, 1);
    setReportContent((prevState) => {
      return {
        ...prevState,
        keyImages: newKeyImages,
      };
    });
  };

  const handleRerenderButtonClick = () => {
    setPreviewContent((_) => ({
      ...reportContent,
    }));
  };

  const handleSaveButtonClick = () => {
    saveReportContent();
  };

  return (
    <Fragment>
      <Card square={true} sx={{ overflow: 'visible', minHeight: '100%' }}>
        <MDBox py={3} px={3} mt={1} mx={3} height="100%">
          <MDBox pl={1} mb={2} display="center" justifyContent="center">
            <MDTypography variant="h5" data-testid="report_editor_title">
              {intl.get('issue_details_report_preview_text_report_title')}
            </MDTypography>
          </MDBox>

          <Grid>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid size={6}>
                <FormField
                  data-testid="report_editor_filename"
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_report_filename'
                  )}
                  onChange={handleChange('fileName')}
                  value={reportContent.fileName}
                  disabled={!isEditable}
                />
              </Grid>

              <Grid container size={6} spacing={1} justifyContent="flex-end">
                {shouldShowPreview && (
                  <Grid>
                    <IconButton onClick={handleRerenderButtonClick}>
                      <Tooltip
                        title={intl.get(
                          'issue_details_report_preview_text_rerender'
                        )}>
                        <CachedIcon />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                )}
                {isEditable && (
                  <Grid>
                    <IconButton onClick={handleSaveButtonClick}>
                      <Tooltip
                        title={intl.get(
                          'issue_details_report_preview_text_save'
                        )}>
                        <SaveIcon />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                )}

                <Grid>
                  <IconButton onClick={togglePreview}>
                    <Tooltip
                      title={intl.get(
                        'issue_details_report_preview_text_toggle_preview'
                      )}>
                      <VisibilityIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={3}
              style={{ marginTop: 15 }}>
              <Grid size={6}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_issue_title'
                  )}
                  onChange={handleChange('issueTitle')}
                  value={reportContent.issueTitle}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid size={6}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_issue_date'
                  )}
                  onChange={handleChange('issueDate')}
                  value={reportContent.issueDate}
                  disabled={!isEditable}
                />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={3}
              style={{ marginTop: 15 }}>
              <Grid size={6}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_patient_name'
                  )}
                  onChange={handleChange('patientName')}
                  value={reportContent.patientName}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid size={6}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_src_org_name'
                  )}
                  onChange={handleChange('srcOrgName')}
                  value={reportContent.srcOrgName}
                  disabled={!isEditable}
                />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={3}
              style={{ marginTop: 15 }}>
              <Grid size={6}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_patient_gender'
                  )}
                  onChange={handleChange('patientGender')}
                  value={reportContent.patientGender}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid size={6}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_department'
                  )}
                  onChange={handleChange('department')}
                  value={reportContent.department}
                  disabled={!isEditable}
                />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={3}
              style={{ marginTop: 15 }}>
              <Grid size={6}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_patient_birthday'
                  )}
                  onChange={handleChange('patientBirthdayDate')}
                  value={reportContent.patientBirthdayDate}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid size={6}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_doctors_from_src_org'
                  )}
                  onChange={handleChange('doctorsFromSrcOrg')}
                  value={reportContent.doctorsFromSrcOrg}
                  disabled={!isEditable}
                />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={3}
              style={{ marginTop: 15 }}>
              <Grid size={12}>
                <FormField
                  variant="outlined"
                  label={intl.get('issue_details_report_preview_text_tags')}
                  onChange={handleChange('tags')}
                  value={reportContent.tags}
                  disabled={!isEditable}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={3} style={{ marginTop: 10 }}>
              <Grid size={12}>
                <FormField
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_description'
                  )}
                  onChange={handleChange('description')}
                  value={reportContent.description}
                  multiline
                  disabled={!isEditable}
                />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={1}
              style={{ marginTop: 10 }}>
              <Grid size={!isEditable ? 11 : 12}>
                <FormField
                  data-testid="report_editor_measures"
                  variant="outlined"
                  label={intl.get('issue_details_report_preview_text_measures')}
                  onChange={handleChange('measures')}
                  value={reportContent.measures}
                  multiline
                  disabled={!isEditable}
                />
              </Grid>
              {!isEditable && (
                <Grid size={1}>
                  <IconButton
                    onClick={() => handleCopyClick(reportContent.measures)}>
                    <ContentCopyIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={1}
              style={{ marginTop: 10 }}>
              <Grid size={!isEditable ? 11 : 12}>
                <FormField
                  data-testid="report_editor_diagnosis"
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_diagnosis'
                  )}
                  onChange={handleChange('diagnosis')}
                  value={reportContent.diagnosis}
                  multiline
                  disabled={!isEditable}
                />
              </Grid>

              {!isEditable && (
                <Grid size={1}>
                  <IconButton
                    onClick={() => handleCopyClick(reportContent.diagnosis)}>
                    <ContentCopyIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={1}
              style={{ marginTop: 10 }}>
              <Grid size={12}>
                <FormField
                  data-testid="report_editor_signature"
                  variant="outlined"
                  label={intl.get(
                    'issue_details_report_preview_text_signature'
                  )}
                  onChange={handleChange('signature')}
                  value={reportContent.signature}
                />
              </Grid>
            </Grid>
            {/* {reportContent.keyImages.map((keyImage, index) => {
              return (
                <Grid key={index} container spacing={3}>
                  <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                    <MDTypography px={2} variant="h6">
                      {keyImage.fileName}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ marginTop: 15 }}>
                    <Button variant="contained">Delete</Button>
                  </Grid>
                </Grid>
              );
            })} */}

            <Grid container spacing={0}>
              <Grid
                size={12}
                style={{ marginTop: 15, paddingTop: 12, paddingBottom: 12 }}>
                <MDBox>
                  <MDTypography>
                    {intl.get('issue_details_report_preview_text_key_images')}
                  </MDTypography>
                </MDBox>
              </Grid>
              {reportContent.keyImages.map((item, index) => {
                return (
                  <Grid size={12} key={index}>
                    <Grid container alignItems="center">
                      {isEditable && (
                        <Grid size={1}>
                          <IconButton
                            onClick={() => {
                              handleKeyImageDelete(item, index);
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                      <Grid size={11} sx={{ paddingLeft: 1 }}>
                        <MDTypography variant="body2">
                          {item.fileName}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container spacing={3}>
              <Grid size={12} style={{ marginTop: 15 }}>
                <DropZone handleFiles={handleImages} isDisabled={!isEditable} />
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </Fragment>
  );
};

DashboardKanbanIssueDetailsReportsEditor.propTypes = propTypes;

export default withRouter(DashboardKanbanIssueDetailsReportsEditor);
