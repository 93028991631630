import Grid from '@mui/material/Grid2';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Button } from 'components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { newAttachmentList } from 'pages/Dashboard/Kanban_Kenshin/IssueDetails/utils';
import { AttachmentTitle } from './Styles';

const AttachmentList = ({
  handleOpenAllViewerButtonClick,
  issue,
  isClient,
  isDoctor,
  darkMode,
  handleCommentButtonClick,
  handleDownloadButtonClick,
  handleDicomPreviewButtonClick,
  handlePreviewButtonClick,
  handleViewerButtonClick,
  handleAddAttachmentToParentIssueButtonClick,
  handleAddAttachmentButtonClick,
  shouldAllowAddSenderAttachment,
  shouldAllowAddReceiverAttachment,
}) => {
  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={{ padding: '5px 0px' }}>
        <Grid style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <AttachmentTitle
            style={{
              fontSize: '16px',
            }}>
            📁 {intl.get('issue_details_text_attachments')}
          </AttachmentTitle>
        </Grid>

        {/* {'ViewerですべてのDicom画像を読影'} */}
        <Grid size={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="primary"
            sx={{ justifyContent: 'start' }}
            onClick={handleOpenAllViewerButtonClick}
            icon={<VisibilityIcon />}>
            {intl.get('issue_details_attachment_list_button_open_viewer')}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid
          style={{
            padding: '5px 0px',
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
          }}>
          <AttachmentTitle style={{ fontSize: '14px' }}>
            📥 {intl.get('issue_details_sender_attachment_files')}
          </AttachmentTitle>
        </Grid>
        {shouldAllowAddSenderAttachment && (
          <Grid
            size={2}
            style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="success"
              icon="plus"
              sx={{ justifyContent: 'center' }}
              onClick={() => handleAddAttachmentButtonClick(true)}>
              {intl.get('issue_details_add_more_attachment_files')}
            </Button>
          </Grid>
        )}
      </Grid>
      {newAttachmentList({
        parentIssue: issue.parentIssue,
        attachments: issue.attachmentsOwnedBySrcOrg,
        dicomFilePaths: issue.dicomFilePaths,
        isClient,
        isDoctor,
        darkMode,
        handleCommentButtonClick,
        handleDownloadButtonClick,
        handleDicomPreviewButtonClick,
        handlePreviewButtonClick,
        handleViewerButtonClick,
        handleAddAttachmentToParentIssueButtonClick,
      })}

      <Grid container spacing={0}>
        <Grid
          style={{
            padding: '5px 0px',
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
          }}>
          <AttachmentTitle style={{ fontSize: '14px' }}>
            📤 {intl.get('issue_details_receiver_attachment_files')}
          </AttachmentTitle>
        </Grid>
        {shouldAllowAddReceiverAttachment && (
          <Grid
            size={2}
            style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="success"
              icon="plus"
              sx={{ justifyContent: 'center' }}
              onClick={() => handleAddAttachmentButtonClick(true)}>
              {intl.get('issue_details_add_more_attachment_files')}
            </Button>
          </Grid>
        )}
      </Grid>
      {newAttachmentList({
        parentIssue: issue.parentIssue,
        attachments: issue.attachmentsOwnedByDstOrg,
        dicomFilePaths: issue.dicomFilePaths,
        isClient,
        isDoctor,
        darkMode,
        handleCommentButtonClick,
        handleDownloadButtonClick,
        handleDicomPreviewButtonClick,
        handlePreviewButtonClick,
        handleViewerButtonClick,
        handleAddAttachmentToParentIssueButtonClick,
      })}
    </>
  );
};

AttachmentList.propTypes = {
  handleOpenAllViewerButtonClick: PropTypes.func.isRequired,
  issue: PropTypes.object.isRequired,
  isClient: PropTypes.bool,
  isDoctor: PropTypes.bool,
  darkMode: PropTypes.bool,
  handleCommentButtonClick: PropTypes.func,
  handleDownloadButtonClick: PropTypes.func,
  handleDicomPreviewButtonClick: PropTypes.func,
  handlePreviewButtonClick: PropTypes.func,
  handleViewerButtonClick: PropTypes.func,
  handleAddAttachmentToParentIssueButtonClick: PropTypes.func,
  handleAddAttachmentButtonClick: PropTypes.func,
  shouldAllowAddSenderAttachment: PropTypes.bool,
  shouldAllowAddReceiverAttachment: PropTypes.bool,
};

export default AttachmentList;
